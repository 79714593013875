import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import "../../../styles/dashboard/transaction/TransactionCategoryPage.css";
import { CategoryList } from "src/utils/CategoryList";
import MobileTableCard from "src/components/reusables/MobileTableWrap";

const TransactionCategoryPage = () => {
  // const actionCtx
  const navigate = useNavigate();
  const tableHeaderList = [
    "Narration",
    "Type",
    "Amount",
    "Transaction reference",
    "Transaction date",
  ];

  const tableBodyList = [
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
  ];

  const transferOutIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12L12 4M12 4H6.66667M12 4V9.33333"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const transferInIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4L4 12M4 12H9.33333M4 12V6.66667"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const imgTextComponent = (text?: string) => {
    return (
      <div className="img-text-box">
        <div className={`img-tab-box ${text === "Transfer in" && "img-tab-box-credit"}`}>
          <figure className="img-box">
            {text === "Transfer out" ? transferOutIcon : transferInIcon}
          </figure>
        </div>
        <p className="text">{text}</p>
      </div>
    );
  };

  const leftIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M12.5 15L7.5 10L12.5 5"
        stroke="#014345"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard
          typeTwo
          text="Transaction history"
          onBack={() => {
            navigate("/dashboard-transaction");
          }}
        />
        {/* back to dashboard end */}
        {/* container start */}
        <ContainerWrapper dontShowHeader width={`100%`}>
          <div className="transaction-category-index-wrap">
            <div
              onClick={() => {
                navigate("/dashboard-transaction-spending-insight");
              }}
              className="top-titile-box"
            >
              <div className="arrow-left-back">
                <figure className="img-box">{leftIcon}</figure>
              </div>
              <div className="box">
                <figure
                  style={{ backgroundColor: CategoryList[0].bgColor }}
                  className="img-box grey-bg-two"
                >
                  {CategoryList[0].icon}
                </figure>
                <p className="name">{CategoryList[0].name}</p>
              </div>
            </div>
            {/* transaction table wrap start */}
            <div className="transaction-table-wrap">
              {/* big table start */}
              <div className="big-table">
                <RavenTable headerList={tableHeaderList}>
                  {tableBodyList.map((chi, idx) => {
                    const { amount, date, direction, ref, type } = chi;
                    return (
                      <RavenTableRow
                        key={idx}
                        one={imgTextComponent(direction)}
                        two={type}
                        three={amount}
                        four={ref}
                        five={date}
                      />
                    );
                  })}
                </RavenTable>
              </div>
              {/* big table end */}
              {/* mobile table start */}
              <div className="mobile-table-box">
                {tableBodyList.map((chi, idx) => {
                  const { amount, date, direction, ref } = chi;
                  return (
                    <MobileTableCard
                      amountColor={
                        direction === ("Transfer in" as any)
                          ? "#1ACE37"
                          : direction === ("Transfer out" as any)
                          ? `#FF0F00`
                          : "#ea872d"
                      }
                      text={date}
                      title={direction}
                      amount={amount}
                      key={idx}
                      smallText={ref}
                      showImg
                      type={direction === "Transfer out" ? "debit" : "credit"}
                    />
                  );
                })}
              </div>
              {/* mobile table end */}
            </div>
            {/* transaction table wrap end */}
          </div>
        </ContainerWrapper>
        {/* container end */}
      </DashboardLayoutIndex>
    </>
  );
};

export default TransactionCategoryPage;
