import React, { useState } from "react";
import "../../styles/dashboard/fragments//FilterFragment.css";
import { useOnClickOutsideTwo } from "src/utils/useOutsideClickTwo";
import useWindowSize from "src/utils/UseWindowSize";

interface MyComponentProps {
  text?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const downloadIcon = (
  <svg
    className="img"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dull-bright-filter"
      d="M6 12H18M3 6H21M9 18H15"
      stroke="#676767"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FilterFragment: React.FC<MyComponentProps> = ({ text, onClick, children }) => {
  const [showDrop, setShowDrop] = useState(false);
  const size = useWindowSize();
  const testingFilterFragRef = useOnClickOutsideTwo(() => {
    setShowDrop(false);
  });

  return (
    <div
      //   onClick={() => {
      //     setShowDrop(true);
      //   }}
      ref={testingFilterFragRef}
      className="filter-fragment-index-wrap-outer"
    >
      {" "}
      <div
        onClick={() => {
          setShowDrop(!showDrop);
        }}
        className="filter-fragment-index-wrap grey-bg"
      >
        {(size.width as any) > 900 && <p className="text">{text || "Filter"}</p>}
        <figure className="img-box">{downloadIcon}</figure>
      </div>
      {/* children drop start */}
      {showDrop && <div className="children-drop-box card-bg">{children}</div>}
      {/* children drop end */}
    </div>
  );
};

export default FilterFragment;
