import "../../../../styles/dashboard/settings/security/index.css";
import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import { useState } from "react";
import CircleProgress from "src/components/reusables/CircleProgess";

interface ChangePasswordModalProps {
  onClose: () => void;
  visible: boolean;
}

const Step1 = () => {
  return (
    <RavenInputField
      label="Current Password"
      color="purple-dark"
      placeholder="********"
      type="password"
      name="password"
      className="rss-input"
    />
  );
};

const Step2 = () => {
  return (
    <div className="security-update-password">
      <RavenInputField
        label="New Password"
        color="purple-dark"
        placeholder="********"
        type="password"
        name="password"
      />
      <RavenInputField
        label="Confirm Password"
        color="purple-dark"
        placeholder="********"
        type="password"
        name="password"
        className="rss-input"
      />
    </div>
  );
};

const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const [step, setStep] = useState<1 | 2>(1);

  const { onClose, visible } = props;

  const handleButtonClick = () => {
    if (step === 1) {
      setStep(2);
    }
  };

  const renderSelection = (): JSX.Element => {
    switch (step) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
    }
  };

  return (
    <RavenModal
      onClose={onClose}
      visble={visible}
      btnLabel={step < 2 ? "Continue" : "Save Password"}
      btnColor="purple-light"
      onBtnClick={handleButtonClick}
    >
      <div className="all-recipient-modal-wrap security-change-password-modal">
        <div className="title-text-box">
          <div className="title">Change Password</div>
          <p style={{ marginTop: "0.4rem" }} className="text grey-white-color">
            Reset and change your personal banking password
          </p>
          <div className="my-24">
            <CircleProgress steps={2} currentStep={step} />
          </div>
        </div>
        <div className="view-all-box-recipient">
          <div className="wrap-all rss-input">{renderSelection()}</div>
        </div>
      </div>
    </RavenModal>
  );
};

export default ChangePasswordModal;
