import {
  // RavenButton,
  RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useState, useRef, useEffect } from "react";
import "../../styles/dashboard/fragments/DeleteModal.css";

interface Props {
  visible?: boolean;
  text?: string;
  title?: string;
  onCancel?: () => void;
  onDelete?: () => void;
  loading?: boolean;
  value?: any;
  onChange?: (param: any) => void;
  onComplete?: (param: any) => void;
  onPay?: (param: any) => void;
  pin?: string;
  setPin?: (param: any) => void;
  btnLabel?: string;
}

const TransactionPinModal: React.FC<Props> = ({
  visible,
  text,
  title,
  onCancel,
  onDelete,
  loading,
  value,
  onChange,
  onComplete,
  onPay,
  pin,
  setPin,
  btnLabel,
}) => {
  const [completePin, setCompletePin] = useState(false);
  // const [pin, setPin] = useState("");
  // const pinRefVal = useRef();
  const pinRefVal: React.RefObject<HTMLInputElement[]> = useRef([]);
  // const location =

  useEffect(() => {
    setCompletePin(false);
    //   pinRefVal.current.forEach((input: any) => (input.value = ""));
    pinRefVal.current?.forEach((input: any) => (input.value = ""));
  }, [visible]);

  return (
    <RavenModal
      className={`transaction-pin-modal-reuse-wrap`}
      visble={visible}
      effect={`fadeInUp`}
      onBtnClick={() => {
        onPay && onPay(pin);
      }}
      onClose={() => {
        setCompletePin(false);
        onCancel && onCancel();
        //   pinRefVal.current.forEach((input) => (input.value = ""));
        pinRefVal.current?.forEach((input: any) => (input.value = ""));
      }}
      btnLabel={btnLabel || `Complete Transaction`}
      loading={loading}
      btnColor={`purple-dark`}
      disabled={!completePin}
    >
      <div className="container-wrap">
        <p className="title">{title || "Complete Transaction"}</p>
        <p className="text grey-white-color pin-test">
          {text ||
            "Please kindly provide your 6-Digit transaction PIN to complete Transaction"}
        </p>
        <RavenInputField
          pinRef={pinRefVal}
          type={`pin`}
          color={`black-light`}
          onComplete={(num: string) => {
            setCompletePin(true);
            onComplete && onComplete(num);
          }}
          id="transafer-Pin"
          value={value}
          onChange={(num: string) => {
            onChange && onChange(num);
            setPin && setPin(num);
            setCompletePin(false);
          }}
        />
      </div>
      {/* <div className="two-button-wrap">
          <p onClick={onCancel} className="cancel grey-white-color">
            Cancel
          </p>
          <RavenButton
            color={`black-light`}
            label="Pay"
            loading={loading}
            size={`small`}
            onClick={onDelete || onPay}
            disabled={!completePin}
          />
        </div> */}
    </RavenModal>
  );
};

export default TransactionPinModal;
