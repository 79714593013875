import React from "react";
import "../../styles/dashboard/reusables/ContainerWrapper.css";
// import arrowRight from "../../assets/arrow-right-icon.svg";
import { FaAngleLeft } from "react-icons/fa";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import NoContentPage from "./NoContentPage";
// import { useDarkMode } from "../../utils/themes/useDarkMode";

interface MyComponentProps {
  children?: React.ReactNode;
  onBack?: () => void;
  stepList?: string[];
  activeStep?: number;
  btnText?: string;
  onBtnClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  width?: any;
  back?: boolean;
  dontShowHeader?: boolean;
  className?: string;
  pagination?: any;
  onStepClick?: (param: any) => void;
  stepClickable?: boolean;
  noContent?: boolean;
  noContentTitle?: string;
  noContentText?: string;
  noContentLoading?: boolean;
  noContentImg?: any;
  
}

const ContainerWrapper: React.FC<MyComponentProps> = ({
  children,
  onBack,
  stepList,
  activeStep,
  btnText,
  onBtnClick,
  loading,
  disabled,
  width,
  back,
  dontShowHeader,
  className,
  pagination,
  onStepClick,
  stepClickable,
  noContent,
  noContentText,
  noContentLoading,
  noContentTitle,
  noContentImg,
}) => {
  // const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  // const [checkTheme, setCheckTheme] = useState(localStorage.getItem("theme"));

  if (noContent) {
    return (
      <NoContentPage
        loading={noContentLoading}
        text={noContentText}
        title={noContentTitle}
        img={noContentImg}
      />
    );
  }

  return (
    <div className={`container-wraaper-box card-bg ${className && className}`}>
      {/* header wrap start */}
      {!dontShowHeader && (
        <div className="header-box border-theme-bottom">
          {/* back box start */}
          {back && (
            <div
              onClick={() => {
                onBack && onBack();
              }}
              className="back-box"
            >
              <FaAngleLeft className="icon grey-white-color" />
              <p className="text">back</p>
            </div>
          )}
          {/* back box end */}
          {/* step box start */}
          {stepList && (
            <div className="step-box">
              {/* box start */}
              {stepList?.map((chi, idx) => {
                return (
                  <div
                    className={`box ${
                      activeStep && activeStep >= idx + 1 && "box-active"
                    }`}
                    key={idx}
                    onClick={() => {
                      onStepClick && onStepClick(idx + 1);
                    }}
                    style={{ cursor: stepClickable && ("pointer" as any) }}
                  >
                    <div className="num">
                      <span>{idx + 1}</span>
                    </div>
                    <p className="text">{chi || "0ieje"}</p>
                  </div>
                );
              })}
              {/* box end */}
            </div>
          )}
          {/* step box end */}
        </div>
      )}
      {/* header box end */}
      {/* main box start */}
      <div
        className="content-box"
        style={{ paddingBottom: btnText ? "10rem" : "0rem" }}
      >
        <div className="wrap-container" style={{ width: width && width }}>
          {children}
        </div>
      </div>
      {/* main box end */}
      {/* footer box start */}
      {btnText && (
        <div className="footer-box card-bg border-theme-top">
          <div className="wrap">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                // onBtnClick()
              }}
              //   className="form"
            >
              <RavenInputField
                // purpleGradient
                type={`submit`}
                loading={loading}
                onSubmit={onBtnClick}
                color={`black-light`}
                value={btnText || `btn text`}
                disabled={disabled}
                //   textColor={`white-${theme}`}
              />
            </form>
          </div>
        </div>
      )}
      {pagination && (
        <div className="footer-box card-bg border-theme-top">
          <div className="table-pagination-box">{pagination}</div>
        </div>
      )}
      {/* footer wrap end */}
    </div>
  );
};

export default ContainerWrapper;
