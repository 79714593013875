import { useState } from "react";
import SettingIndexLayout from "../SettingsIndexLayout";
import { SettingsRowItem } from "../blocks";
import BankStatementModal from "./BankStatementModal";
import "src/styles/dashboard/settings/device-management/index.css";

const macIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      // className="dull-bright-filter"
      d="M16.1103 12.4077V5.88917C16.1103 5.05946 16.1103 4.64461 15.9489 4.3277C15.8068 4.04894 15.5802 3.82231 15.3014 3.68027C14.9845 3.5188 14.5697 3.5188 13.74 3.5188H5.14737C4.31766 3.5188 3.90281 3.5188 3.5859 3.68027C3.30714 3.82231 3.0805 4.04894 2.93847 4.3277C2.777 4.64461 2.777 5.05946 2.777 5.88917V12.4077M4.01156 15.3706H14.8758C15.335 15.3706 15.5646 15.3706 15.753 15.3202C16.2643 15.1832 16.6636 14.7839 16.8006 14.2726C16.8511 14.0842 16.8511 13.8546 16.8511 13.3953C16.8511 13.1657 16.8511 13.0509 16.8258 12.9567C16.7573 12.7011 16.5577 12.5014 16.302 12.4329C16.2079 12.4077 16.093 12.4077 15.8634 12.4077H3.02391C2.79429 12.4077 2.67948 12.4077 2.58528 12.4329C2.32965 12.5014 2.12999 12.7011 2.0615 12.9567C2.03625 13.0509 2.03625 13.1657 2.03625 13.3953C2.03625 13.8546 2.03625 14.0842 2.08674 14.2726C2.22372 14.7839 2.62305 15.1832 3.1343 15.3202C3.3227 15.3706 3.55232 15.3706 4.01156 15.3706Z"
      stroke="var(--primary-purple)"
      strokeWidth="1.335"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const iphoneIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      // className="dull-bright-filter"
      d="M11.666 2.037v1.037c0 .415 0 .623-.08.781a.74.74 0 01-.325.324c-.158.08-.365.08-.78.08H8.407c-.415 0-.623 0-.781-.08a.74.74 0 01-.324-.324c-.08-.158-.08-.366-.08-.78V2.036m-.593 14.815h5.63c.83 0 1.244 0 1.561-.161.279-.142.505-.369.647-.648.162-.317.162-.731.162-1.561V4.408c0-.83 0-1.245-.162-1.562a1.481 1.481 0 00-.647-.647c-.317-.162-.732-.162-1.562-.162h-5.63c-.829 0-1.244 0-1.56.162a1.481 1.481 0 00-.648.647c-.161.317-.161.732-.161 1.562v10.074c0 .83 0 1.244.161 1.561.142.279.369.506.647.648.317.161.732.161 1.562.161z"
      stroke="var(--primary-purple)"
      strokeWidth={1.335}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const data = [
  { icon: macIcon, desc: "Feb 12,2023", title: "Apple Macbook M1, 2022" },
  { icon: iphoneIcon, desc: "Feb 12,2023", title: "Iphone XR" },
];

const SettingsDeviceManagement = () => {
  const [selectedDevice, setSelectedDevice] = useState<string>();

  return (
    <>
      <SettingIndexLayout title="Device management">
        <div className="dvm-list border-theme">
          {data.map((ele) => (
            <div key={ele.title} className="dvm-list__item border-theme-bottom">
              <SettingsRowItem {...ele} />
              <button
                type="button"
                onClick={() => setSelectedDevice(ele.title)}
                className="reset-border btn-delete cursor-pointer purple-accent-box grey-bg-two"
              >
                Delete Device
              </button>
            </div>
          ))}
        </div>
      </SettingIndexLayout>
      {selectedDevice && (
      <BankStatementModal
        onClose={() => setSelectedDevice(undefined)}
        visible={true}
        device={selectedDevice}
      />
      )}
    </>
  );
};

export default SettingsDeviceManagement;
