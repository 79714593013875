import { generateArray } from "src/utils/helpers";
import SettingIndexLayout from "../SettingsIndexLayout";
import { useState } from "react";
import "../../../../styles/dashboard/settings/limits/index.css";
import { SettingsTabHeader, SettingsTitleBlock } from "../blocks";

const options = [{ name: "Limits" }, { name: "Fees" }];

interface Props {
  amount: string;
  title: string;
  decimal?: number | string;
  className?: string;
}

const Row = ({ amount, title, className }: Props) => {
  return (
    <div className={className}>
      <p className="text">{title}</p>
      <p className="limit-row-title">
        {amount}.<span className="text-grey">00</span>
      </p>
    </div>
  );
};

interface TableRowProps {
  title: string;
  desc: string;
  hasDecimal?: boolean;
}

const TableRow = ({ desc, title, hasDecimal }: TableRowProps) => (
  <div className="fees-table-row border-theme-bottom">
    <div className="title">{title}</div>
    <div className="text">
      {desc}
      {hasDecimal && (
        <>
          .<span className="text-grey">00</span>
        </>
      )}
    </div>
  </div>
);

const LimitAndFees = () => {
  const [selected, setSelected] = useState(options[0]);

  return (
    <SettingIndexLayout title="Limits and Fees">
      <div className="limits-and-fees">
        <div className="select-type-box ">
          <SettingsTabHeader
            options={options}
            onChange={setSelected}
            identifier="name"
            selected={selected}
          />
          {selected.name === "Limits" && (
            <div className="limits-content-box">
              {generateArray(2).map((n) => (
                <div className="limits-content-item border-theme" key={n}>
                  <div className="limits-content-header border-theme-bottom">
                    <SettingsTitleBlock title="Raven-to-Raven transfers" />
                    <div className="copy-box fade-purple-bg grey-bg-two">
                      <span>Upgrade</span>
                    </div>
                  </div>
                  <div className="limits-content-body grey-bg">
                    <Row amount="₦50,000,000" title="Single Transfer" />
                    <div className="mid-content  border-theme-right border-theme-left">
                      <Row amount="₦50,000,000" title="Receiving" />
                    </div>
                    <div className="last-content ">
                      <Row amount="₦50,000,000" title="Multiple Transfer" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {selected.name === "Fees" && (
            <div className="limits-content-box">
              <div className="fees-box card-bg">
                <TableRow title="Raven-to-Raven transfer" desc="Free" />
                <TableRow title="Raven-to-other Banks transfer" desc="₦10" hasDecimal />
                <TableRow title="Airtime" desc="₦5" hasDecimal />
                <TableRow title="Data" desc="₦50" hasDecimal />
                <TableRow title="Bills" desc="₦50" hasDecimal />
              </div>
            </div>
          )}
        </div>
      </div>
    </SettingIndexLayout>
  );
};

export default LimitAndFees;
