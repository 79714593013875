import { RavenButton } from "@ravenpay/raven-bank-ui";
import React from "react";
// import { useSelector } from "react-redux";
import "../../../styles/dashboard/verification/verification.css";

interface MyComponentProps {
  pending?: boolean;
  text?: string;
  failed?: boolean;
  onRetryPending?: () => void;
  dontShowBtn?: boolean;
}

const ReuseVerified: React.FC<MyComponentProps> = ({
  pending,
  text,
  failed,
  onRetryPending,
  dontShowBtn,
}) => {
  //   const { loading } = useSelector((state) => state.verifications);
  return (
    <>
      {" "}
      <div className="reusable-verified-pending-wrap grey-bg">
        <div className="img-wrap">
          <figure className="img-box">
            {pending ? (
            <svg className="img" width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="47.9999" height="48" rx="24" fill="#EA872D"/>
            <g filter="url(#filter0_dd_1270_61026)">
            <path d="M24.5 28V24M24.5 20H24.51M34.4999 24C34.4999 29.5228 30.0228 34 24.5 34C18.9771 34 14.5 29.5228 14.5 24C14.5 18.4772 18.9771 14 24.5 14C30.0228 14 34.4999 18.4772 34.4999 24Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <filter id="filter0_dd_1270_61026" x="10.5" y="10" width="28" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1270_61026"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.06 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_1270_61026" result="effect2_dropShadow_1270_61026"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1270_61026" result="shape"/>
            </filter>
            </defs>
            </svg>
            
            ) : failed ? (
              <svg
                className="img"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="47.9999" height="48" rx="24" fill="#FF0F00" />
                <g filter="url(#filter0_dd_1270_61028)">
                  <path
                    d="M30.0004 17.999L18.0005 29.999M18.0005 17.999L30.0004 29.999"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_dd_1270_61028"
                    x="10"
                    y="10"
                    width="28"
                    height="29"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1270_61028"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.06 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="effect1_dropShadow_1270_61028"
                      result="effect2_dropShadow_1270_61028"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect2_dropShadow_1270_61028"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            ) : (
                <svg className="img" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="47.9999" height="48" rx="24" fill="#1ACE37"/>
                <g filter="url(#filter0_dd_1270_61033)">
                <path d="M19.4997 24L22.4997 27L28.4997 21M33.9997 24C33.9997 29.5228 29.5226 34 23.9997 34C18.4769 34 13.9998 29.5228 13.9998 24C13.9998 18.4772 18.4769 14 23.9997 14C29.5226 14 33.9997 18.4772 33.9997 24Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <filter id="filter0_dd_1270_61033" x="10" y="10" width="28" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1270_61033"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.06 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_1270_61033" result="effect2_dropShadow_1270_61033"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1270_61033" result="shape"/>
                </filter>
                </defs>
                </svg>
                
            )}
          </figure>
        </div>
        <p className="text grey-white-color-white">
          {text || "khbd jnbhdsns byhajnmajdx aghbdas"}
        </p>
      </div>
      {failed && (
        <>
          {!dontShowBtn ? (
            <RavenButton
              style={{ marginTop: "3rem", width: "100%" }}
              onClick={onRetryPending}
              className=" border-theme"
              color="purple-dark"
              label="Retry Verification"
              //   loading={loading}
            ></RavenButton>
          ) : (
            <RavenButton
              color="purple-dark"
              style={{ marginTop: "3rem", width: "100%" }}
              loading={true}
            />
          )}
          {/* <RavenButton
            onClick={() => {
              // window.Intercom("show");
              window.fcWidget.open()
            }}
            className="failed-btn border-theme"
            color="purple-dark"
          >
            <span className="grey-white-color">Contact Support</span>
          </RavenButton> */}
        </>
      )}
      {pending && (
        <>
          {!dontShowBtn ? (
            <RavenButton
              style={{ marginTop: "3rem", width: "100%" }}
              onClick={onRetryPending}
              className=" border-theme"
              color="purple-dark"
              label="Retry Verification"
              //   loading={loading}
            ></RavenButton>
          ) : (
            <RavenButton
              color="purple-dark"
              style={{ marginTop: "3rem", width: "100%" }}
              loading={true}
            />
          )}
        </>
      )}
      {!pending && !failed && (
        <RavenButton
          style={{ marginTop: "3rem", width: "100%" }}
        //   onClick={onRetryPending}
          className=" border-theme"
          color="purple-dark"
          label="Done"
          //   loading={loading}
        ></RavenButton>
      )}
    </>
  );
};

export default ReuseVerified;
