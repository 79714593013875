import "../../../../styles/dashboard/settings/profile/index.css";
import SettingIndexLayout from "../SettingsIndexLayout";
import { RavenInputField } from "@ravenpay/raven-bank-ui";

const fields = [
  { label: "First name", value: "Oshioke" },
  { label: "Email Address", value: "oshiomah20@gmail.com", type: "email" },
  { label: "Last name", value: "Mohammed" },
  { label: "Username", value: "@oshiokeui" },
  { label: "Phone number", value: "08129346987" },
];

const Header = () => (
  <div className="settings-profile-header">
    <div className="avatar">MO</div>
    <div className="settings-profile-header__body">
      <h4 className="settings-profile-header__title">Mohammed oshioke</h4>
      <span className="settings-profile-header__text">Oshiomah20@gmail.com</span>
    </div>
  </div>
);

const SettingProfileIndex = () => {
  return (
    <SettingIndexLayout title={<Header />}>
      <div className="settings-profile__body">
        <form
          className="settings-profile__body__form"
          onSubmit={(e) => e.preventDefault()}
        >
          {fields.map((ele, idx) => (
            <RavenInputField key={idx} {...ele} color="purple-dark" />
          ))}
        </form>
        <p className="settings-profile__footer">
          If you have any issues with your information or will like to change any, please
          send a message to{" "}
          <span className="support-email">support@getravenbank.com</span>
        </p>
      </div>
    </SettingIndexLayout>
  );
};

export default SettingProfileIndex;
