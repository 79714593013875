import CardIndexPage from "src/pages/dashboard/cards/CardIndexPage";
import CreateCardIndex from "src/pages/dashboard/cards/CreateCardIndex";

export const dashboard_cards_routes_group = [
  {
    path: "/dashboard-cards",
    element: CardIndexPage,
  },
  {
    path: "/dashboard-cards-create",
    element: CreateCardIndex,
  },
];
