import { createContext, useState } from "react";
// import UseNotificationSound from "src/components/fragments/UseNotificationSound";
// import waveSound from "../assets/raven_success_sound copy";
import WaveSound from "../assets/raven_success_sound.wav";
import useNotificationSound from "src/components/fragments/UseNotificationSound";

interface ActionContextInterface {
  categoryObj: any;
  showCategories: boolean;
  setCategoryObj: (param: any) => void;
  setShowCategories: (param: any) => void;
  playSound: () => void;
  confettiVal: boolean;
  setConfettiVal: (param?: any) => void;
  showProfileDrop: boolean;
  setShowProfileDrop: (param?: any) => void;
  showReferralModal: boolean;
  setShowReferralModal:(param?: any) => void;
  contactUsModal: boolean;
  setContactUsModal:(param?: any) => void;
}

// const playNotification = UseNotificationSound("../assets/raven_success_sound.wav");

const ActionContext = createContext<ActionContextInterface>({
  showCategories: false,
  categoryObj: {},
  setCategoryObj: (param: any) => {},
  setShowCategories: (param: boolean) => {},
  playSound: () => {},
  confettiVal: false,
  setConfettiVal: (param: any) => {},
  showProfileDrop: false,
  setShowProfileDrop: (param) => {},
  showReferralModal: false,
  setShowReferralModal: (param) => {},
  contactUsModal: false,
  setContactUsModal: (param) => {},
});

export function ActionContextProvider(props: any) {
  const playSoundVal = useNotificationSound(WaveSound);
  const [showCategoriesVal, setSHowCategoriesVal] = useState(false);
  const [categoryObjVal, setCategoryObjVal] = useState({});
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [isShowreferal, setIsShowReferal] = useState(false);
  const [isContactUs, setIsContactUs] = useState(false);
  const [profileDropVal, setProfileDropVal] = useState(false);

  const handleConfettiClick = () => {
    setIsConfettiActive(true);

    // Reset the confetti after a short delay (e.g., 3 seconds)
    setTimeout(() => {
      setIsConfettiActive(false);
    }, 3000);
  };
  function setShowCategoriesFunc(param: boolean) {
    setSHowCategoriesVal(param);
  }

  function setShowCategoryObjFunc(param: any) {
    setCategoryObjVal(param);
  }

  function playNotificationFunc() {
    playSoundVal();
  }

  function showProfileDropFunc(param: any) {
    if (param === "show") {
      setProfileDropVal(!profileDropVal);
    }
    if (param === "close") {
      setProfileDropVal(false);
    }
  }

  function setShowCReferalModalFunc(param: any) {
    setIsShowReferal(param);
  }

  function setContactUsModalFunc(param: any) {
    setIsContactUs(param);
  }

  const context = {
    showCategories: showCategoriesVal,
    setShowCategories: setShowCategoriesFunc,
    categoryObj: categoryObjVal,
    setCategoryObj: setShowCategoryObjFunc,
    playSound: playNotificationFunc,
    confettiVal: isConfettiActive,
    setConfettiVal: handleConfettiClick,
    showProfileDrop: profileDropVal,
    setShowProfileDrop: showProfileDropFunc,
    showReferralModal: isShowreferal,
    setShowReferralModal: setShowCReferalModalFunc,
    contactUsModal: isContactUs,
    setContactUsModal: setContactUsModalFunc,
  };

  return (
    <ActionContext.Provider value={context}>{props.children}</ActionContext.Provider>
  );
}

export default ActionContext;
