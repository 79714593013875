import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import StepperMainWrapper from "src/components/reusables/StepperMainWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import useWindowSize from "src/utils/UseWindowSize";
import "../../../../styles/dashboard/savings/TargetSavingIndex.css";
import { RavenInputField, RavenToggleSlide, RavenToolTip } from "@ravenpay/raven-bank-ui";
import SuccessModal from "src/components/fragments/TransactionSuccessModal";
import TransactionPinModal from "src/components/fragments/TransactionPinModal";
import ActionContext from "src/context/ActionContext";

const FixedSavingIndex = () => {
  const stepList = ["Activate Fixed savings", "Savings details", "Confirm savings"];
  const [activeStep, setActiveStep] = useState(1);
  const size = useWindowSize();
  const navigate = useNavigate();
  const [noTargetSavings, setNoTargetSavings] = useState(false);

  const actionCtx = useContext(ActionContext);
  type RegisterProp = {
    amount: string;
    narration: string;
    bulk_list: any[];
    beneficiary: boolean;
    schedule: string;
    schedule_date: string;
    user_pin: string;
    provider: string;
    type: string;
    phone: string;
    recipients: any[];
  };

  const [details, setDetails] = useState<RegisterProp>({
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    provider: "",
    type: "",
    amount: "",
    phone: "",
    user_pin: "",
    schedule: "",
    schedule_date: "",
    recipients: [],
  });

  type showModalProp = {
    pin: boolean;
    success: boolean;
    view: boolean;
  };
  const [showModal, setShowModal] = useState<showModalProp>({
    pin: false,
    success: false,
    view: false,
  });

  const targetLogo = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 8C6 6.4087 6.63214 4.88258 7.75736 3.75736C8.88258 2.63214 10.4087 2 12 2C13.5913 2 15.1174 2.63214 16.2426 3.75736C17.3679 4.88258 18 6.4087 18 8H19C19.5304 8 20.0391 8.21071 20.4142 8.58579C20.7893 8.96086 21 9.46957 21 10V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V10C3 9.46957 3.21071 8.96086 3.58579 8.58579C3.96086 8.21071 4.46957 8 5 8H6ZM12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8H8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4ZM14 14C14 14.3511 13.9076 14.6959 13.732 15C13.5565 15.304 13.304 15.5565 13 15.732V17C13 17.2652 12.8946 17.5196 12.7071 17.7071C12.5196 17.8946 12.2652 18 12 18C11.7348 18 11.4804 17.8946 11.2929 17.7071C11.1054 17.5196 11 17.2652 11 17V15.732C10.6187 15.5119 10.3208 15.1721 10.1523 14.7653C9.98384 14.3586 9.95429 13.9076 10.0682 13.4824C10.1822 13.0571 10.4333 12.6813 10.7825 12.4133C11.1318 12.1453 11.5597 12 12 12C12.5304 12 13.0391 12.2107 13.4142 12.5858C13.7893 12.9609 14 13.4696 14 14Z"
        fill="var(--primary-purple)"
      />
    </svg>
  );

  const typeList = ["Active savings", "Matured savings"];
  const [activeType, setActiveType] = useState("Active savings");

  const plusIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5V19M5 12H19"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const savingsList = [
    {
      name: "Detty December",
      amount: "₦356,000.00",
      time: "76 days to go",
      status: "active",
    },
    {
      name: "Detty December",
      amount: "₦356,000.00",
      time: "76 days to go",
      status: "active",
    },
    {
      name: "Detty December",
      amount: "₦356,000.00",
      time: "76 days to go",
      status: "active",
    },
    {
      name: "Detty December",
      amount: "₦356,000.00",
      time: "76 days to go",
      status: "active",
    },
    {
      name: "Detty December",
      amount: "₦356,000.00",
      time: "76 days to go",
      status: "active",
    },
    {
      name: "Detty December",
      amount: "₦356,000.00",
      time: "76 days to go",
      status: "active",
    },
    {
      name: "Detty December",
      amount: "₦356,000.00",
      time: "76 days to go",
      status: "active",
    },
    {
      name: "Detty December",
      amount: "₦356,000.00",
      time: "76 days to go",
      status: "active",
    },
    {
      name: "Detty December",
      amount: "₦356,000.00",
      time: "76 days to go",
      status: "active",
    },
  ];

  const arrowRight = (
    <svg
      className="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18.5L15 12.5L9 6.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* content wrapper start */}
        <ContainerWrapper
          activeStep={activeStep}
          stepList={noTargetSavings && (stepList as any)}
          dontShowHeader={!noTargetSavings}
          //   dontShowHeader={(size.width as any) < 900}
          width={(size.width as any) < 900 && `100%`}
          //   back
          //   onBack={() => {
          //     navigate("/dashboard-savings");
          //   }}
        >
          <div className="target-savings-index-wrap">
            {/* step one start */}
            {activeStep === 1 && (
              <>
                {noTargetSavings ? (
                  <>
                    {" "}
                    <StepperMainWrapper
                      btnColor="purple-dark"
                      btnText="Activate Fixed savings"
                      onBack={() => {
                        navigate("/dashboard-savings");
                      }}
                      //   disabled={ }
                      onBtnClick={() => {
                        setActiveStep(2);
                      }}
                    >
                      {/* empty bg screen start */}
                      <div className="empty-screen-bg"></div>
                      <div className="infor-detail-box  grey-bg">
                        <p className="title">About fixed savings</p>
                        <p className="text grey-white-color">
                          Fixed savings allows you save a particular amount for a selected
                          duration. you can earn up to 18% interest on your savings
                        </p>
                        {/* <br />
                        <p className="text grey-white-color">
                          For example, if you make a transfer of ₦13k, we will
                          help you save ₦650 automatically 😉.
                        </p> */}
                      </div>
                      {/* empty bg screen end */}
                    </StepperMainWrapper>
                  </>
                ) : (
                  <>
                    <StepperMainWrapper
                      btnColor="purple-dark"
                      //   btnText="Activate Target savings"
                      onBack={() => {
                        navigate("/dashboard-savings");
                      }}
                      title="All fixed savings"
                      //   disabled={ }
                      //   onBtnClick={() => {
                      //     setActiveStep(2);
                      //   }}
                      className="target-savings-index-wrap-stepper"
                    >
                      {/* switch content start */}
                      <div className="switch-view-content-bg card-bg">
                        {" "}
                        <div className="switch-view-content grey-bg">
                          {typeList.map((chi, idx) => {
                            return (
                              <div
                                onClick={() => {
                                  setActiveType(chi);
                                }}
                                key={idx}
                                className={`switch-item ${
                                  activeType === chi && "switch-item-active"
                                }`}
                              >
                                <p className="item grey-white-color">{chi}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      {/* switch content end */}
                      {/* existing savings box start */}
                      <div className="existing-savings-box">
                        {savingsList?.map((chi, idx) => {
                          const { name, amount, status, time } = chi;
                          return (
                            <div
                              key={idx}
                              className={`saving-item ${
                                idx !== savingsList?.length - 1 && "border-theme-bottom"
                              }`}
                              onClick={() => {
                                navigate("/dashboard-savings-fixed-single");
                              }}
                            >
                              <div className="name-amount-box">
                                <p className="name">{name}</p>
                                <p className="amount">{amount}</p>
                              </div>
                              {/* status time start */}
                              <div className="status-time-box">
                                <div className="status-box">
                                  <p className="status">{status}</p>
                                </div>
                                <p className="time">{time}</p>
                              </div>
                              {/* status time end */}

                              <div className="right-arrow-box">
                                <figure className="img-box">{arrowRight}</figure>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* existing savings box end */}
                      {/* add more savings icon start */}
                      <div
                        onClick={() => {
                          setNoTargetSavings(true);
                        }}
                        className="add-more-savings-box"
                      >
                        <div className="tooltip-hover-wrap">
                          <figure className="img-box">{plusIcon}</figure>
                          <RavenToolTip
                            color="purple-dark"
                            text="Add new fixed saving"
                            position={`top-left`}
                          />
                        </div>
                      </div>
                      {/* add more savings icon end */}
                    </StepperMainWrapper>
                  </>
                )}
              </>
            )}
            {/* step one end */}
            {/* step two start */}
            {activeStep === 2 && (
              <>
                {/* {noTargetSavings ? <></> : <></>} */}
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Proceed"
                  title="Fixed savings"
                  text="Avoid temptations, save for the future 😎"
                  onBack={() => {
                    setActiveStep(1);
                  }}
                  //   disabled={ }
                  onBtnClick={() => {
                    setActiveStep(3);
                  }}
                >
                  {/* form group start */}
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    <RavenInputField
                      color="purple-dark"
                      type="text"
                      label="Savings title"
                      placeholder="Give this savings a title"
                      onChange={() => {}}
                    />
                  </form>
                  {/* form group end */}
                  {/* form group start */}
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    <RavenInputField
                      color="purple-dark"
                      type="number"
                      thousandFormat
                      label="Fixed amount"
                      placeholder="Set a  fixed amount"
                      onChange={() => {}}
                      numberPrefix={`₦`}
                    />
                  </form>
                  {/* form group end */}
                  {/* form group start */}
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    <RavenInputField
                      color="purple-dark"
                      type="select"
                      label="Select duration"
                      placeholder="Select a Date"
                      onChange={() => {}}
                    />
                  </form>
                  {/* form group end */}
                </StepperMainWrapper>
              </>
            )}
            {/* step two end */}
            {/* step three start */}
            {activeStep === 3 && (
              <>
                {/* {noTargetSavings ? <></> : <></>} */}
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Finish"
                  //   title="Set target"
                  //   text="Set a savigns goal in less than a minute"
                  onBack={() => {
                    setActiveStep(2);
                  }}
                  //   disabled={ }
                  onBtnClick={() => {
                    setShowModal((prev) => {
                      return { ...prev, pin: true };
                    });
                  }}
                >
                  {/* amount box start */}
                  <div
                    style={{ marginTop: "-1rem" }}
                    className="target-amount-box grey-bg"
                  >
                    <div className="label-value-box">
                      <p className="label grey-white-color">Detty december</p>
                      <p className="value">₦150,000.00</p>
                    </div>
                    <div className="logo-wrap white-black-bg">
                      <figure className="img-box">{targetLogo}</figure>
                    </div>
                  </div>
                  {/* amount box end */}
                  {/* label value wrapp start */}
                  <div className="label-value-wrap-box">
                    {/* label value start */}
                    <div className="label-value-box">
                      <p className="label grey-white-color">Lock period</p>
                      <p className="value">8 months</p>
                    </div>
                    {/* label value end */}
                    {/* label value start */}
                    <div className="label-value-box">
                      <p className="label grey-white-color">Interest rate</p>
                      <p className="value">8.0% p.a</p>
                    </div>
                    {/* label value end */}
                    {/* label value start */}
                    <div className="label-value-box">
                      <p className="label grey-white-color">Interest yield</p>
                      <p className="value primary">₦265.20</p>
                    </div>
                    {/* label value end */}
                    {/* label value start */}
                    <div className="label-value-box">
                      <p className="label grey-white-color">Withholding tax</p>
                      <p className="value">₦20</p>
                    </div>
                    {/* label value end */}
                    {/* label value start */}
                    <div className="label-value-box">
                      <p className="label grey-white-color">Total amount</p>
                      <p className="value">₦157,000</p>
                    </div>
                    {/* label value end */}
                    {/* label value start */}
                    <div className="label-value-box">
                      <p className="label grey-white-color">Maturity date</p>
                      <p className="value">July 8th, 2023</p>
                    </div>
                    {/* label value end */}
                  </div>
                  {/* label value wrapp end */}
                  {/* note wrap start */}
                  <div className="note-wrap-box">
                    <p className="note">Note:</p>
                    <div className="note-text-box">
                      <p className="note-text grey-white-color">
                        Withdrawing before your release date attracts 5.0% charge o the
                        amount saved and interest earned
                      </p>
                      <div style={{ transform: "scale(0.85)" }} className="small-toggle">
                        {" "}
                        <RavenToggleSlide color={`purple-dark`} />
                      </div>
                    </div>
                  </div>
                  {/* note wrap end */}
                </StepperMainWrapper>
              </>
            )}
            {/* step three end */}
          </div>
        </ContainerWrapper>
        {/* content wrapper end */}
      </DashboardLayoutIndex>

      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin) => {
          //   handleSubmit(pin);
          //   actionCtx?.setConfettiVal();
          actionCtx?.playSound();
          setShowModal((prev) => {
            return { ...prev, success: true, pin: false };
          });
        }}
        value={details?.user_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        // loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        // onClose={() => {
        //   setShowModal((prev) => {
        //     return { ...prev, success: false };
        //   });
        // }}
        title="Transaction Successful."
        text={`You have successfully sent ₦150,000 to Ayeni Kehinde Timilehin`}
        btnText={`Done`}
        onBtnClick={() => {
          navigate("/dashboard-savings");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default FixedSavingIndex;
