/* eslint-disable no-useless-concat */

import moment from "moment";
// import { stringSimilarity } from "string-similarity";

export const SPECIAL_ROLE_NAME = "owner";

type RSStyleDefault = Record<string, string>; // object my different
export const reactSelectStyle = {
  control: (base: RSStyleDefault, state: RSStyleDefault) => ({
    ...base,
    border: state.isFocused ? "0.1rem solid #6F6F6F" : "0.1rem solid #6F6F6F",
    // backgroundColor: state.isSelected ? "#6F6F6F" : "white",
    boxShadow: state.isFocused ? "0.1rem solid #6F6F6F" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(204, 204, 204, .3)" : "white",
    color: state.isSelected ? "#020202" : "#020202",
  }),
};

export const formatNumWithoutCommaNaira = (numb: number | string) => {
  // const nairaSymbol = "\u{020A6}";

  var regex = /[,\sN%$#%₦G]/g;
  var result = String(numb).replace(regex, "");
  return result;
};

export const formatNumWithCommaNaira = (numb: number) => {
  // const nairaSymbol = "\u{020A6}";

  var regex = /[,\sNG]/g;
  var result = String(numb).replace(regex, "");
  var num: string | number = Math.abs(Number(result)); // might cause an error here
  num = num.toFixed(2);
  const numSplit = num.split(".");
  var int = numSplit[0];
  const dec = numSplit[1];
  if (int.length > 3) {
    int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (numb) {
    return int + "." + dec;
  }

  return "0" + "." + "00";
};

export const formatNumWithCommaNairaSymbol = (numb: number | string) => {
  const nairaSymbol = "\u{020A6}";

  var regex = /[,\sNG]/g;
  var result = String(formatNumWithoutCommaNaira(numb)).replace(regex, "");
  var num: string | number = Math.abs(Number(result));
  num = num.toFixed(2);
  const numSplit = num.split(".");
  var int = numSplit[0];
  const dec = numSplit[1];
  if (int.length > 3) {
    int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (numb) {
    return nairaSymbol + "" + int + "." + dec;
  }

  return nairaSymbol + "" + "0" + "." + "00";
};

export const getTotalPage = (perpage: StringOrNum, totalNum: StringOrNum) => {
  const val = Math.ceil(Number(totalNum) / Number(perpage));
  // console.log(val);
  return val;
};

export function formatDate(date: string) {
  // console.log(date);
  var actualDate = String(moment(date).format("MMMM, DD, YYYY  — HH:mm a"));
  const splitDate = actualDate.split(",");
  var newDate =
    splitDate[0].slice(0, 3) + "." + splitDate[1] + "," + splitDate[2];
  return newDate;
  // const val = date ? formatTimeDifference(date) : null;
  // return val;
}

export function numberFormatChart(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" },
    { value: 1e18, symbol: "QT" },
    { value: 1e21, symbol: "QQ" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  if (item && item.symbol === "M") {
    // const formattedNum = (num / item.value).toFixed(digits);
    // const integerPart = formattedNum.split(".")[0];
    // const decimalPart = formattedNum.split(".")[1];
    // return `${integerPart}.${decimalPart}${item.symbol}`;
  }
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export const formatMetaData = (obj: string) => {
  if (obj) {
    const newObj = JSON?.parse(obj);
    // console.log(newObj);
    return newObj;
  }
};

export const getPageNum = (link: string) => {
  if (link) {
    const num = link?.split("&current_page=")[1]?.split("&")[0];
    return num;
  }
};

/**
 * using a generic function for this guy
 */
export const sumAllNum = <T extends number>(list: T[]) => {
  if (list) {
    const sumVal = list?.reduce((a, b) => a + b, 0);
    return sumVal;
  }
};

export const removeCountryCode = (num: unknown) => {
  // if (num) {
  //   const val =
  //     num.slice(0, 3) === "234"
  //       ? num.replace("234", "0")
  //       : num.slice(0, 1) === "0"
  //       ? num.replace("0", "")
  //       : num;
  //   const valspace = val?.replace(" ", "");
  //   return removeLeadingZeroFromString(valspace);
  // }
  return num;
};

export const capitalizeFirstLetter = (str: string) => {
  if (str) {
    let capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalizedStr;
  }
};

type FormatStatusParam = LoosenString<"0" | "1" | "2" | "3">;
export const formatStatus = (param: FormatStatusParam) => {
  switch (param) {
    case "0":
      return "pending";
    case "1":
      return "processing";
    case "2":
      return "success";
    case "3":
      return "failed";
    default:
      return "--";
  }
};

// export function findBestMatch(str: string, strArr: string[]) {
// 	const lowerCaseArr = strArr.map((element) => element.toLowerCase()); //creating lower case array
// 	const match = stringSimilarity.findBestMatch(str.toLowerCase(), lowerCaseArr).bestMatch; //trying to find bestMatch
// 	//  console.log('rating >>', match.rating);
// 	if (match.rating > 0 && match.rating >= 0.5) {
// 		const foundIndex = lowerCaseArr.findIndex((x) => x === match.target); //finding the index of found best case
// 		return strArr[foundIndex]; //returning initial value from array
// 	}
// 	return null;
// }

export const reverseArrayFunc = <T>(list: T[]) => {
  if (list?.length > 0) {
    const reversedArray = list?.reverse();
    return reversedArray;
  }
};

export const removeSpace = (str: string) => {
  const val = str.replace(/\s+/g, "");
  // console.log(val);
  return val;
};

export const trimLongString = (str: string, num: number) => {
  if (str && num) {
    const val =
      String(str).length > Number(num)
        ? `${String(str).slice(0, Number(num))}...`
        : str;
    return val;
  }
};

// export const getPosTerminalRequest = <T>(list: T[]) => {
// 	if (list?.length > 0) {
// 		const status = list[0]?.status;
// 		// console.log(status);
// 		return status;
// 	}
// };

export const formatTitleTemplate = (text: string) => {
  var regex = /[,\s_.csvNG]/g;
  var result = String(text).replace(regex, " ");
  return result;
};

export function convertToLowerCase(inputString?: string) {
  if (inputString) {
    return String(inputString)?.toLowerCase();
  }
}

export const formatNUmPan = (str?: string) => {
  if (str) {
    const val = `${str?.slice(0, 6)}******${str?.slice(
      str?.length - 4,
      str?.length
    )}`;
    return val;
  }
};

export const lowerCaseWrap = (text?: string) => {
  if (text) {
    // Capitalize the first letter of the first word
    const firstWord = text.split(" ")[0];
    const capitalizedFirstWord =
      firstWord.charAt(0).toUpperCase() + firstWord.slice(1);

    // Reconstruct the string with the capitalized first word
    return capitalizedFirstWord + text.slice(firstWord.length);
  }
};

export const formatTypeFunction = (str?: string) => {
  let val;
  // console.log(str);
  if (str) {
    if (!str?.includes("_")) {
      val = str;
    } else {
      const splitStr = String(str)?.replace(/_/g, " ");
      val = lowerCaseWrap(splitStr);
    }

    // console.log(splitStr);

    // console.log(val);
    return val;
  }
};

export const getStartAndStopDates = (param: string) => {
  if (param) {
    // get the current date
    var currentDate = moment();

    // subtract 7 days from the current date moment(currentDate).subtract(daysToSubtract, 'days')
    var actualDate = moment(currentDate).subtract(param, "days");

    const fromDate = actualDate.format("YYYY-MM-DD");

    const toDate = currentDate.format("YYYY-MM-DD");

    const obj = {
      start_date: fromDate,
      end_date: toDate,
    };

    return obj;
  }
};

interface GetAllAmountArg extends Record<string, unknown> {
  amount?: StringOrNum | any;
}
export const getAllAmount = <T extends GetAllAmountArg>(list: T[]) => {
  // console.log(list);

  if (list.length > 0) {
    const amountList = list.map((chi) =>
      Number(formatNumWithoutCommaNaira(chi?.amount))
    );
    const sumList = sumAllNum(amountList);
    // console.log(sumList);
    return sumList;
  }
};

export const returnCbnTransferFee = (feesObject: any, amount: StringOrNum) => {
  let transferCharge = 0;
  let cbnFeeStructure = feesObject.cbn;
  for (let l in cbnFeeStructure) {
    let splittedIndex = l.split("_");
    let lowerNumberForComparism: StringOrNum = splittedIndex[0];
    if (parseFloat(String(splittedIndex.length)) === parseFloat("2")) {
      if (parseFloat(splittedIndex[0]) > parseFloat("0")) {
        lowerNumberForComparism =
          parseFloat(splittedIndex[0]) - parseFloat(String(1));
      }
      if (
        parseFloat(String(amount)) >
          parseFloat(String(lowerNumberForComparism)) &&
        parseFloat(String(amount)) <= parseFloat(splittedIndex[1])
      ) {
        transferCharge = parseFloat(cbnFeeStructure[String(l)]);
        break;
      }
    } else {
      if (parseFloat(String(amount)) >= parseFloat(splittedIndex[0])) {
        transferCharge = parseFloat(cbnFeeStructure[String(splittedIndex[0])]);
        break;
      }
    }
  }
  return transferCharge;
};

export function clearCache() {
  if ("caches" in window) {
    // Clear all caches
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
}

export const formatListWithImage = <
  T extends { imageSrc: string; value: string }
>(
  list: T[]
) => {
  const newList = list.map((chi) => {
    return {
      ...chi,
      imageSrc: `https://businessapi.getraventest.com/static/media/banks/${chi?.value}.png `,
    };
  });
  // console.log(newList);
  return newList;
};

export function removeLeadingZeroFromString<T>(str: T) {
  // safe to use unknown as you narrow the type
  if (typeof str !== "string") {
    return str; // Return as it is if not a string
  }

  if (str.charAt(0) === "0") {
    return str.substring(1); // Remove the first character (leading zero) and return the rest of the string
  }

  return str; // Return the string as it is if the first character is not '0'
}

type CurrencyParams = LoosenString<"NGN" | "USD" | "EUR" | "GBP">;
export function getCurrencySymbol(param: CurrencyParams) {
  const nairaSymbol = "\u{020A6}";

  switch (param) {
    case "NGN":
      return nairaSymbol;
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return nairaSymbol;
  }
}

export function getPrimaryAccount<T extends { primary: number }>(list: T[]) {
  if (list?.length > 0) {
    const newList = list?.filter((chi) => chi?.primary === 1);
    // console.log(newList);

    return newList?.length >= 1 ? newList[0] : list[0];
  }
}

export function formAllAccountList<T extends { primary: number }>(list: T[]) {
  // console.log(list, "---------------dytd-");

  if (list?.length > 1 && getPrimaryAccount(list)) {
    const firstChi = getPrimaryAccount(list);
    const newList = list?.filter((chi) => chi?.primary !== 1);
    const returnList = [firstChi, ...newList];
    return returnList;
  } else {
    // console.log(list);
    return list;
  }
}

export function canStringify<T>(obj: T) {
  try {
    JSON.stringify(obj);
    return true;
  } catch (error) {
    return false;
  }
}

export function generateUniqueId() {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base36
  const randomString = Math.random().toString(36).substr(2, 5); // Generate a random string
  const uniqueId = `${timestamp}-${randomString}`;
  return uniqueId;
}

export function convertToLowercase(inputString?: string) {
  if (inputString) {
    return String(inputString).toLowerCase();
  }
}

/**
 * cant type this guy, might throw an error
 */
export function formatTimeDifference(date: any) {
  const now = new Date();
  const timeDifference = ((now as any) - (new Date(date) as any)) as any;
  // console.log(date);
  if (timeDifference < 60000) {
    // Less than 1 minute
    return "Just now";
  } else if (timeDifference < 3600000) {
    // Less than 1 hour
    const minutesAgo = Math.floor(timeDifference / 60000);
    return `${minutesAgo} minutes ago`;
  } else if (timeDifference < 86400000 && date?.getDate() === now?.getDate()) {
    // Today
    const hoursAgo = Math.floor(timeDifference / 3600000);
    return `${hoursAgo} hours ago`;
  } else {
    // Other dates
    // const options = { year: 'numeric', month: 'long', day: 'numeric' };
    var actualDate = String(moment(date).format("MMMM, DD, YYYY  — HH:mm a"));
    const splitDate = actualDate.split(",");
    var newDate =
      splitDate[0].slice(0, 3) + "." + splitDate[1] + "," + splitDate[2];
    return newDate;
  }
}

// rehk was here

/**
 * i dont have to import the type i can just reference it in d.ts file in my tipe folders
 */
export const getUser = (): User => {
  const bananaMonkey: User = {
    age: "69",
    name: "rehkmansa",
  };

  return bananaMonkey;
};

export const gStyle = {
  mixBlendMode: "multiply",
};

export function rearrangeArray(
  array: any[],
  inputValue: string,
  objectKey: string
): any[] {
  // const objectKey = "name"
  const matchingObject = array.find((obj) =>
    obj[objectKey].toLowerCase().includes(inputValue.toLowerCase())
  );

  if (matchingObject) {
    // Remove the matching object from the array
    const filteredArray = array.filter((obj) => obj !== matchingObject);
    // Place the matching object at the beginning of the array
    return [matchingObject, ...filteredArray];
  } else {
    return array; // No matching object found, return the original array
  }
}

export function getRandomNumber(min: number, max: number) {
  // Generate a random number between min and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function searchGlobalFunction(param: any, list: any[], keysToSearch: string[]) {
  const lowerCaseValue = param.toLowerCase();
  const compareFunction = (a: any, b: any) => {
    const aContainsSearchValue = keysToSearch.some((key) =>
      a[key].toLowerCase().includes(lowerCaseValue)
    );
    const bContainsSearchValue = keysToSearch.some((key) =>
      b[key].toLowerCase().includes(lowerCaseValue)
    );

    if (aContainsSearchValue && !bContainsSearchValue) {
      return -1; // Move a before b
    } else if (!aContainsSearchValue && bContainsSearchValue) {
      return 1; // Move b before a
    } else {
      return 0; // Leave the order unchanged
    }
  };
  // console.log(all_customers);
  const sortedArray = list?.slice()?.sort(compareFunction);
  return sortedArray;
}


export const accentColors = [
    {
      name: "purple" as const,
      color: "rgba(117, 90, 226, 1)",
      fadeBg: "rgba(240, 238, 255, 1)",
      iconFadeBg: "rgba(180, 164, 252, 1)",
    },
    {
      name: "orange" as const,
      color: "#EA872D",
      fadeBg: "rgba(255, 246, 237, 1)",
      iconFadeBg: "rgba(238, 187, 135, 1)",
    },
    {
      name: "green" as const,
      color: "#0B8376",
      fadeBg: "rgba(224, 241, 240, 1)",
      iconFadeBg: "rgba(155, 229, 201, 1)",
    },
  ];