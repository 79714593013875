import SavingsIndex from "src/pages/dashboard/savings/SavingsIndex";
import { dashboard_savings_target_routes_group } from "./target";
import { dashboard_savings_fixed_routes_group } from "./fixed";
import { dashboard_savings_spend_save_routes_group } from "./spend-save";
import { dashboard_savings_hifa_routes_group } from "./hifa";

export const dashboard_savings_routes_group = [
  {
    path: "/dashboard-savings",
    element: SavingsIndex,
  },
  ...dashboard_savings_target_routes_group,
  ...dashboard_savings_fixed_routes_group,
  ...dashboard_savings_spend_save_routes_group,
  ...dashboard_savings_hifa_routes_group,
];
