import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ActionContextProvider } from "./context/ActionContext";
import FreshchatComponent from "./components/reusables/FreshChat";
import NoConfigFound from "./components/fragments/NoConfigFound";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <ActionContextProvider>
    <BrowserRouter>
      {/* <App /> */}
      {process.env.NODE_ENV !== "development" ? <NoConfigFound /> : <App />}
      <FreshchatComponent />
    </BrowserRouter>
  </ActionContextProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
