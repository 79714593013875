import "src/styles/dashboard/settings/bank-statement/index.css";
import { RavenButton, RavenModal } from "@ravenpay/raven-bank-ui";
import "src/styles/dashboard/settings/beneficiary/DeleteModal.css";

interface DeleteDeviceProps {
  onClose(): void;
  visible: boolean;
  device: string;
}

const DeleteDevice = ({ onClose, visible, device }: DeleteDeviceProps) => {
  return (
    <RavenModal
      onClose={onClose}
      visble={visible}
      onBtnClick={() => {
        throw new Error("Not implemented");
      }}
      className="dvm-modal"
    >
      <div className="dvm-modal-body">
        <div>
          <h4 className="dvm-modal-title">Delete {device}</h4>
          <p className="text-content grey-white-color">
            Removing this device, would disable their access to your account on your
            business, ensure you confirm this device before totally removing them from
            your account.
          </p>
        </div>
      </div>
      <div className="delete-beneficiary-modal-footer grey-bg">
        <div className="btn-row">
          <button onClick={onClose} className="btn-reset btn-text" type="button">
            Cancel
          </button>
          <RavenButton color="error-dark">Proceed</RavenButton>
        </div>
      </div>
    </RavenModal>
  );
};

export default DeleteDevice;
