import {
  RavenButton,
  RavenInputField,
  RavenTable,
  RavenTableRow,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import TitleTextBox from "src/components/reusables/TitleTextBox";
import BottomNavComponent from "src/layout/dashboard/BottomNavComponent";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import "../../../styles/dashboard/transaction/TransactionIndex.css";
import ExportFragment from "src/components/fragments/ExportFragment";
import FilterFragment from "src/components/fragments/FilterFragment";
import { useNavigate } from "react-router-dom";
import PaginationFragment from "src/components/fragments/PaginationFragment";
import TransactionDetailModal from "./TransactionDetailModal";
import MobileTableCard from "src/components/reusables/MobileTableWrap";

const TransactionIndex = () => {
  const navigate = useNavigate();
  const insightIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V3M7 14.5V17.5M11.5 11.5V17.5M16 8.5V17.5M20.5 5.5V17.5"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const transferOutIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12L12 4M12 4H6.66667M12 4V9.33333"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const transferInIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4L4 12M4 12H9.33333M4 12V6.66667"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const tableHeaderList = [
    "Narration",
    "Type",
    "Amount",
    "Transaction reference",
    "Transaction date",
  ];

  const tableBodyList = [
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer out",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
    {
      direction: "Transfer in",
      type: "Deposit",
      amount: "₦2,300,000",
      ref: "674960486378",
      date: "5 Sept, 2022 • 5:48PM",
    },
  ];

  const imgTextComponent = (text?: string) => {
    return (
      <div className="img-text-box">
        <div className={`img-tab-box ${text === "Transfer in" && "img-tab-box-credit"}`}>
          <figure className="img-box">
            {text === "Transfer out" ? transferOutIcon : transferInIcon}
          </figure>
        </div>
        <p className="text">{text}</p>
      </div>
    );
  };

  type showModalProp = {
    view: boolean;
  };
  const [showModal, setShowModal] = useState<showModalProp>({
    view: false,
  });

  return (
    <>
      <DashboardLayoutIndex>
        {/* title text box start */}
        <TitleTextBox
          // dontShow
          title={`Transactions history`}
          text={`Get a quick snapshot of your transactions, search and filter to see
          even more.`}
        >
          <RavenButton
            onClick={() => {
              navigate("/dashboard-transaction-spending-insight");
            }}
            className="btn-insight fade-purple-bg border-theme grey-bg-two"
            color="purple-dark"
          >
            <p className="text">Spending insights</p>
            <figure className="img-box">{insightIcon}</figure>
          </RavenButton>
        </TitleTextBox>
        {/* title text box end */}
        {/* container wrapper start */}
        <ContainerWrapper
          noContentLoading={false}
          //   noContent={true}
          dontShowHeader
          width={`100%`}
        >
          {/* trnsaction index wrap start */}
          <div className="transaction-index-wrap">
            {/* search filter box start */}
            <div className="search-filter-box">
              <div className="search-box-wrap">
                <RavenInputField
                  color={`purple-dark`}
                  type={`search`}
                  placeholder="Search"
                  className="btn-search"
                />
              </div>
              {/* search-box end */}

              <div className="filter-export-box">
                <FilterFragment>
                  <RavenInputField
                    color="purple-dark"
                    type="select"
                    placeholder="Filter by"
                  />
                </FilterFragment>
                <ExportFragment />
              </div>
            </div>
            {/* search filter box end */}
            {/* pagination start */}
            <PaginationFragment next from={`1`} to={10} total={30} />
            {/* pagination end */}
            {/* transaction table wrap start */}
            <div className="transaction-table-wrap">
              {/* big table start */}
              <div className="big-table">
                <RavenTable headerList={tableHeaderList}>
                  {tableBodyList.map((chi, idx) => {
                    const { amount, date, direction, ref, type } = chi;
                    return (
                      <RavenTableRow
                        key={idx}
                        one={imgTextComponent(direction)}
                        two={type}
                        three={amount}
                        four={ref}
                        five={date}
                        onRowClick={() => {
                          setShowModal((prev) => {
                            return { ...prev, view: true };
                          });
                        }}
                      />
                    );
                  })}
                </RavenTable>
              </div>
              {/* big table end */}
              {/* mobile table start */}
              <div className="mobile-table-box">
                {tableBodyList.map((chi, idx) => {
                  const { amount, date, direction, ref, type } = chi;
                  return (
                    <MobileTableCard
                      amountColor={
                        direction === ("Transfer in" as any)
                          ? "#1ACE37"
                          : direction === ("Transfer out" as any)
                          ? `#FF0F00`
                          : "#ea872d"
                      }
                      text={date}
                      title={direction}
                      amount={amount}
                      key={idx}
                      smallText={ref}
                      showImg
                      type={direction === "Transfer out" ? "debit" : "credit"}
                    />
                  );
                })}
              </div>
              {/* mobile table end */}
            </div>
            {/* transaction table wrap end */}
          </div>
          {/* trnsaction index wrap end */}
        </ContainerWrapper>
        {/* container wrapper end */}
        <BottomNavComponent />
      </DashboardLayoutIndex>
      {/* view trans modal start */}
      <TransactionDetailModal
        visible={showModal?.view}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
        }}
      />
      {/* view trans modal end */}
    </>
  );
};

export default TransactionIndex;
