import {
  RavenInputField,
  RavenModal,
  RavenRadioCheck,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import ActionContext from "src/context/ActionContext";
import "../../../styles/dashboard/send-money/SendMoneyCategoryModal.css";
import { CategoryList } from "src/utils/CategoryList";
import useDebounce from "src/utils/UseDebounce";
import { rearrangeArray } from "src/helper";

interface MyselectObj {
  name: string;
}

const SendMoneyCategoryModal = () => {
  const actionCtx = useContext(ActionContext);
  const [selectObjList, setSelectObjList] = useState(CategoryList);
  const [selectObj, setSelectObj] = useState<MyselectObj>({
    name: "",
  });

  const [search, setSearch] = useState("");
  //   const navigate = useNavigate

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      const newList = rearrangeArray(CategoryList, search, "name");
      setSelectObjList(newList);

      //   setDetails((prev) => {
      //     return { ...prev, authorized_users: newList };
      //   });
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      //   setRavenName("");
      setSelectObjList(CategoryList);
      //   checkUserToShow(details?.selected_roles);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <RavenModal
      onBtnClick={() => {
        actionCtx.setCategoryObj(selectObj);
        actionCtx?.setShowCategories(false);
      }}
      btnColor="purple-light"
      visble={actionCtx?.showCategories}
      onClose={() => {
        setSelectObj({ name: "" });
        actionCtx?.setShowCategories(false);
      }}
      btnLabel="Confirm category"
      disabled={selectObj?.name ? false : true}
      //   disabled={!selectObj ? false : true}
    >
      <div className="category-modal-wrap-index">
        {/* title box start */}
        <div className="title-text-box">
          <p className="title">Categorize this transaction</p>
          <p className="text grey-white-color">
            Please kindly provide your 6-Digit transaction PIN to complete
            Transaction
          </p>
        </div>
        {/* title box end */}
        {/* search box start */}
        <div className="search-cat-box">
          <RavenInputField
            color={`purple-dark`}
            type="search"
            placeholder="Search"
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        {/* search box end */}
        {/* select list tart */}
        <div className="select-list-box">
          {selectObjList?.map((chi, idx) => {
            const { name, bgColor, icon } = chi;
            return (
              <div
                onClick={() => {
                  if (selectObj?.name === name) {
                    setSelectObj({ name: "" });
                    actionCtx.setCategoryObj({});
                  } else {
                    setSelectObj(chi);
                  }
                }}
                key={idx}
                className="select-item-box"
              >
                <div
                  style={{ backgroundColor: bgColor }}
                  className="img-cat-wrap grey-bg-two"
                >
                  <figure className="img-box">{icon}</figure>
                </div>
                <p className="name">{name}</p>

                <div className="radio-check-box">
                  <RavenRadioCheck
                    color="purple-dark"
                    key={`${bgColor}-${idx}`}
                    checked={selectObj?.name === name}
                    name={`${bgColor}-${idx}`}
                    id={idx}
                    // style={}
                    onChange={() => {}}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {/* select list end */}
      </div>
    </RavenModal>
  );
};

export default SendMoneyCategoryModal;
