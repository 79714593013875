import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../../styles/dashboard/transaction/TransactionDetailModal.css";

interface MyComponentProps {
  detail?: any;
  onClose?: () => void;
  visible?: boolean;
}

const TransactionDetailModal: React.FC<MyComponentProps> = ({
  detail,
  onClose,
  visible,
}) => {
  const copyIcon = (
    <svg
      className="img"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_594_40222)">
        <path
          d="M4.66667 4.6665V3.03317C4.66667 2.37978 4.66667 2.05308 4.79383 1.80351C4.90568 1.58399 5.08416 1.40552 5.30368 1.29366C5.55324 1.1665 5.87994 1.1665 6.53333 1.1665H10.9667C11.6201 1.1665 11.9468 1.1665 12.1963 1.29366C12.4158 1.40552 12.5943 1.58399 12.7062 1.80351C12.8333 2.05308 12.8333 2.37978 12.8333 3.03317V7.4665C12.8333 8.1199 12.8333 8.4466 12.7062 8.69616C12.5943 8.91568 12.4158 9.09416 12.1963 9.20601C11.9468 9.33317 11.6201 9.33317 10.9667 9.33317H9.33333M3.03333 12.8332H7.46667C8.12006 12.8332 8.44676 12.8332 8.69632 12.706C8.91585 12.5942 9.09432 12.4157 9.20618 12.1962C9.33333 11.9466 9.33333 11.6199 9.33333 10.9665V6.53317C9.33333 5.87978 9.33333 5.55308 9.20618 5.30351C9.09432 5.08399 8.91585 4.90552 8.69632 4.79366C8.44676 4.6665 8.12006 4.6665 7.46667 4.6665H3.03333C2.37994 4.6665 2.05324 4.6665 1.80368 4.79366C1.58416 4.90552 1.40568 5.08399 1.29383 5.30351C1.16667 5.55308 1.16667 5.87978 1.16667 6.53317V10.9665C1.16667 11.6199 1.16667 11.9466 1.29383 12.1962C1.40568 12.4157 1.58416 12.5942 1.80368 12.706C2.05324 12.8332 2.37994 12.8332 3.03333 12.8332Z"
          stroke="var(--primary-purple)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_594_40222">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const handleDownload = async () => {};
  return (
    <RavenModal
      btnColor="purple-dark"
      btnLabel="Download receipt"
      onClose={onClose}
      visble={visible}
      onBtnClick={handleDownload}
    >
      <div className="transaction-detail-modal-index">
        <div className="title-box border-theme-bottom">
          <p className="title">Download receipt</p>
        </div>
        <div className="wrap-content-box">
          {/* label value box start */}
          <div className="label-value-box border-theme-bottom">
            <p className="label grey-white-color">Narration</p>
            <div className="value">My feeding allowance...</div>
          </div>
          {/* label value box end */}
          {/* label value box start */}
          <div className="label-value-box border-theme-bottom">
            <p className="label grey-white-color">Bank Name</p>
            <div className="value">Raven</div>
          </div>
          {/* label value box end */}
          {/* label value box start */}
          <div className="label-value-box border-theme-bottom">
            <p className="label grey-white-color">Account Number</p>
            <div className="value">Alex Oyebade</div>
          </div>
          {/* label value box end */}
          {/* label value box start */}
          <div className="label-value-box border-theme-bottom">
            <p className="label grey-white-color">Reference number</p>
            <div className="value">
              674960486378{" "}
              <div className="copy-box">
                <figure className="img-box">{copyIcon}</figure>
              </div>
            </div>
          </div>
          {/* label value box end */}
        </div>
      </div>
    </RavenModal>
  );
};

export default TransactionDetailModal;
