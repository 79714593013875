import React, { useState } from "react";
import "../../styles/dashboard/fragments/SelectSpecialComponent.css";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { trimLongString } from "src/helper";
import { RavenToolTip } from "@ravenpay/raven-bank-ui";

interface MyComponentProps {
  selectList: any[];
  parentClassName?: string;
}

const rightArrowIcon = (
  <svg
    className="img"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dull-bright-filter"
      d="M9 18L15 12L9 6"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const SelectSpecialComponent: React.FC<MyComponentProps> = ({
  selectList,
  parentClassName,
}) => {
  const navigate = useNavigate();
  const [activeSelect, setActiveSelect] = useState("Bank transfer");

  const [isCopied, setIsCopied] = useState(false);
  const handleCopied = async (num: string) => {
    setIsCopied(true);
    await navigator.clipboard.writeText(num);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div
      className={`select-wrap select-special-wrap-index border-theme ${
        parentClassName && parentClassName
      }`}
    >
      {selectList?.map((chi, idx) => {
        const { name, text, link, logo, smallIcon, details,  newBagde, comingSoon } =
          chi;
        return (
          // <React.Fragment>
          // {" "}
          <div
            key={idx}
            className={`select-item ${
              idx !== selectList?.length - 1 && "border-theme-bottom"
            }`}
          >
            <div
              onClick={() => {
                link && navigate(link);
                if (name === "Bank transfer") {
                  activeSelect === name
                    ? setActiveSelect("")
                    : setActiveSelect(name);
                }
              }}
              className="select-item-wrap"
            >
              {" "}
              <div
                // style={{ backgroundColor: iconBg && iconBg }}
                className="select-img fade-purple-bg grey-bg-two"
              >
                <figure
                  className={`img-box  ${smallIcon && "img-box-small"} ${
                    smallIcon && "img-box-small"
                  } `}
                >
                  {logo}
                </figure>
              </div>
              <div className="text-box">
                <div className="title">
                  {name}{" "}
                  {newBagde && (
                    <>
                      <div className="new-bagde">
                        <p className="text">New</p>
                      </div>
                    </>
                  )}
                   {comingSoon && (
                    <>
                      <div className="coming-soon-bagde">
                        <p className="text">Coming soon</p>
                      </div>
                    </>
                  )}
                </div>
                <p className="text grey-white-color">{text}</p>
              </div>
            {!comingSoon &&  <div
                className={`arrow-icon ${
                  activeSelect === "Bank transfer" &&
                  name === "Bank transfer" &&
                  "arrow-icon-rotate"
                }`}
              >
                <figure className="img-box">{rightArrowIcon}</figure>
              </div>}
            </div>
            {details && (
              <>
                {/* content display start */}
                <div
                  className={`content-display-box ${
                    activeSelect === name &&
                    name === "Bank transfer" &&
                    "content-display-box-show"
                  }`}
                >
                  <div className="grey-bg display-wrap">
                    <div className="info-box">
                      <div className="bank-info grey-white-color">
                        <div className="name tooltip-hover-wrap">
                          {trimLongString(
                            details?.name?.replaceAll("-", " "),
                            20
                          ) || "------"}{" "}
                          <RavenToolTip
                            color="black-light"
                            position={`right`}
                            text={details?.name || "------------"}
                          />{" "}
                        </div>
                        <p>•</p>
                        <p className="bank">
                          {details?.bank || "----------------"}
                        </p>
                      </div>
                      <p className="acc_num">
                        {details?.account_num || "---------------"}
                      </p>
                    </div>
                    {details?.account_num && (
                      <div
                        onClick={() => {
                          // const obj = {
                          //   bank: actionCtx?.currentAccount?.bank || "",
                          //   account_name:
                          //     actionCtx?.currentAccount?.account_name,
                          //   account_number:
                          //     actionCtx?.currentAccount?.account_number || "",
                          // };
                          handleCopied(details?.copy_value);
                        }}
                        className="copy-box fade-purple-bg grey-bg-two"
                      >
                        <figure className="img-box">
                          {isCopied ? (
                            <FaCheck className="img icon" />
                          ) : (
                            <svg
                              width="10"
                              height="11"
                              viewBox="0 0 10 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="img"
                            >
                              <path
                                // className="dull-bright-filter"
                                d="M2.22222 2.5V1C2.22222 0.867391 2.28075 0.740215 2.38494 0.646446C2.48913 0.552678 2.63044 0.5 2.77778 0.5H9.44444C9.59179 0.5 9.7331 0.552678 9.83728 0.646446C9.94147 0.740215 10 0.867391 10 1V7.99999C10 8.1326 9.94147 8.25978 9.83728 8.35355C9.7331 8.44731 9.59179 8.49999 9.44444 8.49999H7.77778V9.99999C7.77778 10.276 7.52778 10.5 7.21833 10.5H0.559444C0.486182 10.5004 0.413554 10.4877 0.345735 10.4628C0.277915 10.4379 0.216241 10.4011 0.164255 10.3546C0.112269 10.3082 0.0709961 10.2529 0.0428079 10.1921C0.0146198 10.1312 7.18746e-05 10.0659 0 9.99999L0.00166668 3C0.00166668 2.724 0.251667 2.5 0.561111 2.5H2.22222ZM1.11278 3.5L1.11111 9.49999H6.66667V3.5H1.11278ZM3.33333 2.5H7.77778V7.49999H8.88889V1.5H3.33333V2.5Z"
                                fill="var(--primary-purple)"
                              />
                            </svg>
                          )}
                        </figure>{" "}
                        <span>{isCopied ? `Copied` : `Copy`}</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* content display end */}
              </>
            )}
          </div>

          // </React.Fragment>
        );
      })}
    </div>
  );
};

export default SelectSpecialComponent;
