import BillAndUtilityIndex from "src/pages/dashboard/bill-utilities/BillAndUtilityIndex";
import AirtimeIndex from "src/pages/dashboard/bill-utilities/airtime/AirtimeIndex";
import BettingIndex from "src/pages/dashboard/bill-utilities/betting/BettingIndex";
import CableIndex from "src/pages/dashboard/bill-utilities/cable/CableIndex";
import DataIndex from "src/pages/dashboard/bill-utilities/data/DataIndex";
import ElectricityIndex from "src/pages/dashboard/bill-utilities/electricity/ElectricityIndex";

export const dashboard_bill_utilities_routes_group = [
  {
    path: "/dashboard-bill-utilities",
    element: BillAndUtilityIndex,
  },
  {
    path: "/dashboard-bill-utilities-airtime",
    element: AirtimeIndex,
  },
  {
    path: "/dashboard-bill-utilities-data",
    element: DataIndex,
  },
  {
    path: "/dashboard-bill-utilities-cable",
    element: CableIndex,
  },
  {
    path: "/dashboard-bill-utilities-electricity",
    element: ElectricityIndex,
  },
  {
    path: "/dashboard-bill-utilities-betting",
    element: BettingIndex,
  },
];
