import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: [
    "Leisure",
    "Savings",
    "Food",
    "Health",
    "Transport & fuel",
    "Charity",
    "Family",
  ],
  datasets: [
    {
      label: "",
      data: [12, 19, 30, 5, 6, 15, 13],
      backgroundColor: [
        "rgba(117, 90, 226, 1)",
        "rgba(11, 131, 118, 1)",
        "rgba(234, 135, 45, 1)",
        "rgba(255, 15, 0, 1)",
        "rgba(131, 69, 132, 1)",
        "rgba(243, 106, 172, 1)",
        "rgba(71, 104, 133, 1)",
      ],
      hoverBackgroundColor: [
        "rgba(117, 90, 226, 1)",
        "rgba(11, 131, 118, 1)",
        "rgba(234, 135, 45, 1)",
        "rgba(255, 15, 0, 1)",
        "rgba(131, 69, 132, 1)",
        "rgba(243, 106, 172, 1)",
        "rgba(71, 104, 133, 1)",
      ],
      //   borderColor: [
      //     "rgba(255, 99, 132, 1)",
      //     "rgba(54, 162, 235, 1)",
      //     "rgba(255, 206, 86, 1)",
      //     "rgba(75, 192, 192, 1)",
      //     "rgba(153, 102, 255, 1)",
      //     "rgba(255, 159, 64, 1)",
      //   ],
      borderWidth: 0,
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: (context) => {
          const label = data.labels[context.dataIndex];
          const value = context.parsed;
          return `${label}: ${value}%`;
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  cutout: 115,
//   annotation: {
//     annotations: [
//       {
//         type: 'line',
//         mode: 'horizontal',
//         scaleID: 'y-axis-0',
//         value: 0, // Adjust the value to position the annotation
//         borderColor: 'rgba(0, 0, 0, 0.5)',
//         borderWidth: 2,
//         label: {
//           enabled: true,
//           content: 'Static Tooltip', // Tooltip text
//         },
//       },
//     ],
//   },
};

export function DoughnutChart() {
  return <Doughnut options={options} data={data} />;
}
