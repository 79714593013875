import SingleTargetSavings from "src/pages/dashboard/savings/target/SingleTargetSavings";
import TargetSavingIndex from "src/pages/dashboard/savings/target/TargetSavingIndex";

export const dashboard_savings_target_routes_group = [
  {
    path: "/dashboard-savings-target",
    element: TargetSavingIndex,
  },
  {
    path: "/dashboard-savings-target-single",
    element: SingleTargetSavings,
  },
];
