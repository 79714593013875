import React, { useEffect, useState } from "react";
import "../../styles/dashboard/fragments/BottomNavComponent.css";
import { useLocation, useNavigate } from "react-router-dom";
// import NavbarFade from "./NavbarFade";

// interface MyComponentProps {
//   children?: React.ReactNode;
// }

const gStyle = {
  mixBlendMode: "multiply",
};

const homeIcon = (
  <svg
    className="img"
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6129 23.1055H7.01255C6.82367 23.1055 6.63663 23.0673 6.46212 22.993C6.28762 22.9188 6.12906 22.8099 5.9955 22.6727C5.86193 22.5355 5.75599 22.3726 5.68371 22.1933C5.61142 22.0141 5.57422 21.8219 5.57422 21.6279V12.2539C5.57424 12.0166 5.62989 11.7828 5.73646 11.5722C5.84303 11.3616 5.99739 11.1804 6.18649 11.0441L13.1341 6.03854C13.3798 5.86142 13.6733 5.76772 13.9735 5.77057C14.2736 5.77342 14.5653 5.87269 14.8078 6.05445L21.4604 11.0441C21.6433 11.1813 21.7921 11.3609 21.8947 11.5683C21.9973 11.7757 22.0508 12.005 22.0508 12.2377V21.6279C22.0509 21.8219 22.0137 22.014 21.9415 22.1933C21.8692 22.3726 21.7633 22.5355 21.6298 22.6727C21.4963 22.8099 21.3377 22.9187 21.1632 22.993C20.9887 23.0673 20.8017 23.1055 20.6129 23.1055Z"
      fill="var(--primary-purple)"
    />
    <g style={gStyle as any}>
      <path
        d="M10.9044 3.7334H8.46216C8.17995 3.7334 7.95117 4.10448 7.95117 4.56224V12.5955C7.95117 13.0533 8.17995 13.4243 8.46216 13.4243H10.9044C11.1866 13.4243 11.4153 13.0533 11.4153 12.5955V4.56224C11.4153 4.10448 11.1866 3.7334 10.9044 3.7334Z"
        fill="#EA872D"
      />
    </g>
  </svg>
);

const accountIcon = (
  <svg
    className="img"
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.18281 22.8558V12.2989C8.18281 11.5257 7.55601 10.8989 6.78281 10.8989C6.00961 10.8989 5.38281 11.5257 5.38281 12.2989V22.8558C5.38281 23.629 6.00961 24.2558 6.78281 24.2558C7.55601 24.2558 8.18281 23.629 8.18281 22.8558Z"
      fill="#EA872D"
    />
    <path
      d="M13.3166 23.3328V10.3907C13.3166 9.61752 12.6898 8.99072 11.9166 8.99072C11.1434 8.99072 10.5166 9.61752 10.5166 10.3907V23.3328C10.5166 24.106 11.1434 24.7328 11.9166 24.7328C12.6898 24.7328 13.3166 24.106 13.3166 23.3328Z"
      fill="#EA872D"
    />
    <path
      d="M17.9836 23.3328V10.3907C17.9836 9.61752 17.3568 8.99072 16.5836 8.99072C15.8104 8.99072 15.1836 9.61752 15.1836 10.3907V23.3328C15.1836 24.106 15.8104 24.7328 16.5836 24.7328C17.3568 24.7328 17.9836 24.106 17.9836 23.3328Z"
      fill="#EA872D"
    />
    <path
      d="M23.1164 22.8558V12.2989C23.1164 11.5257 22.4896 10.8989 21.7164 10.8989C20.9432 10.8989 20.3164 11.5257 20.3164 12.2989V22.8558C20.3164 23.629 20.9432 24.2558 21.7164 24.2558C22.4896 24.2558 23.1164 23.629 23.1164 22.8558Z"
      fill="#EA872D"
    />
    <path
      d="M24.5166 22.8247H3.98327C3.8595 22.8247 3.7408 22.9001 3.65329 23.0343C3.56577 23.1685 3.5166 23.3505 3.5166 23.5403V24.9714C3.5166 25.1611 3.56577 25.3431 3.65329 25.4773C3.7408 25.6115 3.8595 25.6869 3.98327 25.6869H24.5166C24.6404 25.6869 24.7591 25.6115 24.8466 25.4773C24.9341 25.3431 24.9833 25.1611 24.9833 24.9714V23.5403C24.9833 23.3505 24.9341 23.1685 24.8466 23.0343C24.7591 22.9001 24.6404 22.8247 24.5166 22.8247Z"
      fill="var(--primary-purple)"
    />
    <path
      d="M25.1961 9.32095L14.4831 3.34769C14.4115 3.30778 14.3313 3.28687 14.2498 3.28687C14.1683 3.28687 14.0881 3.30778 14.0166 3.34769L3.30351 9.32095C3.22671 9.36379 3.16263 9.42698 3.11798 9.50389C3.07334 9.58079 3.04979 9.66857 3.0498 9.758V12.7446C3.0498 12.8766 3.10111 13.0033 3.19243 13.0966C3.28375 13.19 3.40761 13.2424 3.53676 13.2424H24.9628C25.092 13.2424 25.2159 13.19 25.3072 13.0966C25.3985 13.0033 25.4498 12.8766 25.4498 12.7446V9.758C25.4498 9.66857 25.4263 9.58079 25.3816 9.50389C25.337 9.42698 25.2729 9.36379 25.1961 9.32095Z"
      fill="var(--primary-purple)"
    />
  </svg>
);

const savingsIcon = (
  <svg
    className="img"
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.46879 16.2368H6.86014C5.66723 16.2368 4.7002 17.2039 4.7002 18.3968V20.852C4.7002 22.0449 5.66723 23.012 6.86014 23.012H8.46879C9.66169 23.012 10.6287 22.0449 10.6287 20.852V18.3968C10.6287 17.2039 9.66169 16.2368 8.46879 16.2368Z"
      fill="var(--primary-purple)"
    />
    <path
      d="M15.462 10.4419H13.8533C12.6604 10.4419 11.6934 11.4089 11.6934 12.6018V20.8521C11.6934 22.045 12.6604 23.012 13.8533 23.012H15.462C16.6549 23.012 17.6219 22.045 17.6219 20.8521V12.6018C17.6219 11.4089 16.6549 10.4419 15.462 10.4419Z"
      fill="var(--primary-purple)"
    />
    <path
      d="M22.4551 6.44873H20.8465C19.6536 6.44873 18.6865 7.41577 18.6865 8.60867V20.8519C18.6865 22.0448 19.6536 23.0119 20.8465 23.0119H22.4551C23.648 23.0119 24.6151 22.0448 24.6151 20.8519V8.60867C24.6151 7.41577 23.648 6.44873 22.4551 6.44873Z"
      fill="var(--primary-purple)"
    />
    <g style={gStyle as any}>
      <path
        d="M11.1711 17.2668C14.6505 17.2668 17.4711 14.4461 17.4711 10.9667C17.4711 7.48735 14.6505 4.66675 11.1711 4.66675C7.6917 4.66675 4.87109 7.48735 4.87109 10.9667C4.87109 14.4461 7.6917 17.2668 11.1711 17.2668Z"
        fill="#EA872D"
      />
    </g>
    <path
      d="M12.8937 12.6464C12.7946 12.6462 12.6996 12.6068 12.6296 12.5367C12.5596 12.4666 12.5203 12.3716 12.5203 12.2725V9.61735H10.0374C9.93841 9.61735 9.84344 9.57802 9.77342 9.50799C9.7034 9.43797 9.66406 9.343 9.66406 9.24398C9.66406 9.14495 9.7034 9.04999 9.77342 8.97996C9.84344 8.90994 9.93841 8.87061 10.0374 8.87061H12.8937C12.9927 8.87061 13.0877 8.90994 13.1577 8.97996C13.2277 9.04999 13.267 9.14495 13.267 9.24398V12.273C13.2669 12.372 13.2275 12.4669 13.1575 12.5369C13.0876 12.6069 12.9927 12.6462 12.8937 12.6464Z"
      fill="white"
    />
    <path
      d="M8.97737 13.5334C8.90357 13.5332 8.83148 13.5111 8.77018 13.4701C8.70888 13.429 8.66111 13.3706 8.6329 13.3025C8.60468 13.2343 8.59728 13.1592 8.61162 13.0869C8.62597 13.0145 8.66142 12.9479 8.71351 12.8957L12.6313 8.98019C12.6658 8.94462 12.707 8.91627 12.7526 8.89679C12.7981 8.87731 12.8471 8.86708 12.8966 8.86671C12.9461 8.86634 12.9952 8.87582 13.0411 8.89462C13.0869 8.91341 13.1285 8.94113 13.1635 8.97618C13.1986 9.01122 13.2262 9.05288 13.245 9.09872C13.2638 9.14457 13.2732 9.19369 13.2728 9.24322C13.2724 9.29276 13.2621 9.34171 13.2426 9.38723C13.223 9.43276 13.1947 9.47394 13.1591 9.50839L9.24123 13.4239C9.20664 13.4586 9.16552 13.4862 9.12023 13.505C9.07495 13.5237 9.0264 13.5334 8.97737 13.5334Z"
      fill="white"
    />
  </svg>
);

const historyIcon = (
  <svg
    className="img"
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8068 9.39697H6.24469C5.14305 9.39697 4.25 10.2909 4.25 11.3936V22.5356C4.25 23.6383 5.14305 24.5323 6.24469 24.5323H16.8068C17.9085 24.5323 18.8015 23.6383 18.8015 22.5356V11.3936C18.8015 10.2909 17.9085 9.39697 16.8068 9.39697Z"
      fill="var(--primary-purple)"
    />
    <path
      d="M12.7055 18.9708H6.62517C6.49588 18.9708 6.37189 18.9194 6.28047 18.8279C6.18905 18.7364 6.1377 18.6122 6.1377 18.4828C6.1377 18.3534 6.18905 18.2293 6.28047 18.1378C6.37189 18.0463 6.49588 17.9949 6.62517 17.9949H12.7055C12.8347 17.9949 12.9585 18.0462 13.0499 18.1377C13.1412 18.2291 13.1925 18.3531 13.1925 18.4824C13.1926 18.5465 13.1801 18.61 13.1557 18.6692C13.1313 18.7285 13.0954 18.7823 13.0502 18.8277C13.0049 18.8731 12.9512 18.909 12.8921 18.9336C12.8329 18.9581 12.7695 18.9708 12.7055 18.9708Z"
      fill="white"
    />
    <path
      d="M10.23 21.1046H6.62517C6.49595 21.1046 6.37203 21.0533 6.28062 20.9619C6.18921 20.8705 6.13781 20.7464 6.1377 20.6171C6.1377 20.4877 6.18905 20.3636 6.28047 20.2721C6.37189 20.1806 6.49588 20.1292 6.62517 20.1292H10.23C10.3592 20.1292 10.4832 20.1806 10.5747 20.2721C10.6661 20.3636 10.7174 20.4877 10.7174 20.6171C10.7173 20.7464 10.6659 20.8705 10.5745 20.9619C10.4831 21.0533 10.3592 21.1046 10.23 21.1046Z"
      fill="white"
    />
    <g style={gStyle as any}>
      <path
        d="M18.0841 17.4782C21.1773 17.4782 23.6849 14.9682 23.6849 11.8719C23.6849 8.77564 21.1773 6.26562 18.0841 6.26562C14.9909 6.26562 12.4834 8.77564 12.4834 11.8719C12.4834 14.9682 14.9909 17.4782 18.0841 17.4782Z"
        fill="#EA872D"
      />
    </g>
    <path
      d="M20.6081 13.5815H17.9169C17.8634 13.5815 17.8104 13.571 17.761 13.5505C17.7116 13.53 17.6667 13.4999 17.6289 13.462C17.5911 13.4241 17.5611 13.3792 17.5407 13.3297C17.5202 13.2802 17.5097 13.2272 17.5098 13.1736V9.57847C17.5098 9.47037 17.5527 9.36671 17.629 9.29027C17.7054 9.21384 17.8089 9.1709 17.9169 9.1709C18.0248 9.1709 18.1284 9.21384 18.2047 9.29027C18.2811 9.36671 18.324 9.47037 18.324 9.57847V12.766H20.6081C20.714 12.7692 20.8146 12.8135 20.8884 12.8896C20.9622 12.9658 21.0035 13.0677 21.0035 13.1738C21.0035 13.2799 20.9622 13.3818 20.8884 13.4579C20.8146 13.5341 20.714 13.5784 20.6081 13.5815Z"
      fill="white"
    />
  </svg>
);

const profileIcon = (
  <svg
    className="img"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3481 19.6444V23.1413C19.3481 23.5562 18.9847 23.8934 18.5372 23.8934H5.47796C5.03037 23.8934 4.66699 23.5562 4.66699 23.1413V19.6444C4.66699 17.129 6.01804 15.0894 9.58047 15.0894C9.69953 15.0927 9.82521 15.0943 9.95878 15.0943C10.5395 15.0972 11.2489 15.0732 12.0088 15.0632C12.7679 15.0732 13.4772 15.0972 14.058 15.0943C14.1907 15.0943 14.3172 15.0943 14.4363 15.0894C17.9987 15.0894 19.3481 17.129 19.3481 19.6444Z"
      fill="var(--primary-purple)"
    />
    <path
      d="M15.0578 12.2854C16.8899 10.4533 16.8899 7.48275 15.0578 5.65059C13.2256 3.81842 10.2551 3.81842 8.4229 5.65059C6.59073 7.48275 6.59073 10.4533 8.4229 12.2854C10.2551 14.1176 13.2256 14.1176 15.0578 12.2854Z"
      fill="var(--primary-purple)"
    />
    <path
      d="M23.1038 21.081C23.0063 26.7697 14.5449 26.7689 14.4482 21.081C14.5457 15.3926 23.0071 15.3926 23.1038 21.081Z"
      fill="#EA872D"
    />
  </svg>
);

const listToShow = [
  { name: "Dashboard", icon: homeIcon, link: "/dashboard-overview" },
  { name: "Accounts", icon: accountIcon },
  { name: "Wealth", icon: savingsIcon },
  { name: "History", icon: historyIcon, link: "/dashboard-transaction" },
  { name: "Profile", icon: profileIcon, link: "/dashboard-settings-profile" },
];

const BottomNavComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isTransformed, setIsTransformed] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;

    if (!isTransformed && scrollTop >= 100) {
      setIsTransformed(true);
    } else if (isTransformed && scrollTop < 100) {
      setIsTransformed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransformed]);

  return (
    // <NavbarFade>
    <div
      className={`bottom-nav-index-wrap card-bg border-theme scrollable-content ${
        isTransformed ? "transformed-content" : ""
      }`}
    >
      {listToShow?.map((chi, idx) => {
        const { name, icon, link } = chi;
        return (
          <div
            key={idx}
            className={`list-item ${location.pathname === link && "list-item-active"}`}
            onClick={() => {
              link && navigate(link);
            }}
          >
            <div className="img-wrap">
              <figure className="img-box">{icon}</figure>
            </div>
            <p className="name grey-white-color">{name}</p>
          </div>
        );
      })}
    </div>
    // </NavbarFade>
  );
};

export default BottomNavComponent;
