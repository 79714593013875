import React, { Fragment, useContext } from "react";
import "../../styles/dashboard/layout/LayoutIndex.css";
import HeaderNav from "./HeaderNav";
import { ThemeProvider } from "styled-components";
import { useDarkMode } from "src/utils/themes/useDarkMode";
import { darkTheme, lightTheme, GlobalStyles } from "src/utils/themes/theme";
import TotalReferralModal from "src/components/reusables/TotalReferralModal";
import ActionContext from "src/context/ActionContext";
import ContactUsModal from "src/components/reusables/ContactUsModal";
// import BottomNavComponent from "./BottomNavComponent";

interface MyComponentProps {
  children?: React.ReactNode;
}

const DashboardLayoutIndex: React.FC<MyComponentProps> = ({ children }) => {
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const themeMode = theme === "light" ? lightTheme : darkTheme;
  const actionCtx = useContext(ActionContext);

  return (
    <Fragment>
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        <div className="dashboard-layout-index-wrap scrollable-container">
          {/* header nav start */}
          <div className="header-nav-box border-theme-bottom">
            <HeaderNav theme={theme} toggleTheme={toggleTheme} />
          </div>
          {/* header nav end */}
          {/* content main nav start */}
          <div className="dashboard-content-main-box">
            <div className="content-global-size"> {children}</div>
          </div>
          {/* content main nav end */}
          {/* <BottomNavComponent /> */}
        </div>
      </ThemeProvider>
      {/* total referal box modal start */}
      <TotalReferralModal
        visible={actionCtx?.showReferralModal}
        onClose={() => {
          actionCtx.setShowReferralModal(false);
        }}
      />
      {/* total referral box modal end */}
      {/* total referal box modal start */}
      <ContactUsModal
        visible={actionCtx?.contactUsModal}
        onClose={() => {
          actionCtx.setContactUsModal(false);
        }}
      />
      {/* total referral box modal end */}
    </Fragment>
  );
};

export default DashboardLayoutIndex;
