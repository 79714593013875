import HifaSavingIndex from "src/pages/dashboard/savings/hifa/HifaSavingIndex";
import SingleHifaSavingsIndex from "src/pages/dashboard/savings/hifa/SingleHifaSavingIndex";


export const dashboard_savings_hifa_routes_group = [
    {
      path: "/dashboard-savings-hifa",
      element: HifaSavingIndex,
    },
    {
      path: "/dashboard-savings-hifa-single",
      element: SingleHifaSavingsIndex,
    },
  ];