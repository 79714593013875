// import useSound from "use-sound";
// // import waveSound from "../../assets/raven_success_sound.wav";
// // import { formatMetaData } from "../../utils/helper/Helper";
// // import { useSelector } from "react-redux";


// function UseNotificationSound(soundUrl) {
//   // const { user } = useSelector(
//   //   (state) => state.user
//   // );
//   const [play] = useSound(soundUrl, { volume: 0.5 });

//   const playNotification = () => {
//     play();
//   };  

//   return playNotification;
// }

// export default UseNotificationSound;


import useSound from "use-sound";

type UseNotificationSoundFunction = (soundUrl: string, ) => () => void;

const useNotificationSound: UseNotificationSoundFunction = (soundUrl, options = { volume: 0.5 }) => {
  const [play] = useSound(soundUrl, options);

  const playNotification = () => {
    play();
  };

  return playNotification;
};

export default useNotificationSound;
