import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import StepperMainWrapper from "src/components/reusables/StepperMainWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import useWindowSize from "src/utils/UseWindowSize";
import "../../../../styles/dashboard/savings/TargetSavingIndex.css";
// import {
//   RavenInputField,
//   RavenToggleSlide,
//   RavenToolTip,
// } from "@ravenpay/raven-bank-ui";
import SuccessModal from "src/components/fragments/TransactionSuccessModal";
import TransactionPinModal from "src/components/fragments/TransactionPinModal";
import ActionContext from "src/context/ActionContext";
import SingleDetailComponent from "./SingleDetailComponent";
import WithdrawComponents from "./WithdrawComponents";
import { RavenInputField, RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import ReactSlider from "react-slider";
import { formatNumWithoutCommaNaira } from "src/helper";

const SpendSaveSavingIndex = () => {
  const [singleSavingsoShow, setSingleSavingsToShow] = useState("");
  const stepList = [
    "Activate Target savings",
    "Savings details",
    "Confirm savings",
  ];
  const [activeStep, setActiveStep] = useState(1);
  const size = useWindowSize();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [noTargetSavings, setNoTargetSavings] = useState(true);

  const actionCtx = useContext(ActionContext);
  type RegisterProp = {
    amount: string;
    narration: string;
    bulk_list: any[];
    beneficiary: boolean;
    schedule: string;
    schedule_date: string;
    user_pin: string;
    provider: string;
    type: string;
    phone: string;
    recipients: any[];
    percent: string | number;
  };

  const [details, setDetails] = useState<RegisterProp>({
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    provider: "",
    type: "",
    amount: "",
    phone: "",
    user_pin: "",
    schedule: "",
    schedule_date: "",
    recipients: [],
    percent: "",
  });

  type showModalProp = {
    pin: boolean;
    success: boolean;
    view: boolean;
  };
  const [showModal, setShowModal] = useState<showModalProp>({
    pin: false,
    success: false,
    view: false,
  });

  const minusIcon = (
    <svg
      className="img"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M5.17773 12H19.1777"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const addIcon = (
    <svg
      className="img"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M12.5879 5V19M5.58789 12H19.5879"
        stroke="#020202"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* content wrapper start */}
        <ContainerWrapper
          activeStep={activeStep}
          stepList={noTargetSavings && (stepList as any)}
          dontShowHeader
          //   dontShowHeader={(size.width as any) < 900}
          width={(size.width as any) < 900 && `100%`}
          //   back
          //   onBack={() => {
          //     navigate("/dashboard-savings");
          //   }}
        >
          <div
            className={`${noTargetSavings && "target-savings-index-wrap"} ${
              !noTargetSavings && "target-savings-index-wrap-single"
            } `}
          >
            {/* step one start */}
            {activeStep === 1 && (
              <>
                {noTargetSavings ? (
                  <>
                    {" "}
                    <StepperMainWrapper
                      btnColor="purple-dark"
                      btnText="Start saving"
                      onBack={() => {
                        navigate("/dashboard-savings");
                      }}
                      //   disabled={ }
                      onBtnClick={() => {
                        setActiveStep(2);
                      }}
                    >
                      {/* empty bg screen start */}
                      <div className="empty-screen-bg"></div>
                      <div className="infor-detail-box  grey-bg">
                        <p className="title">Activate spend - save</p>
                        <p className="text grey-white-color">
                          When you activate spend- save, we help you to save 5%
                          of every transaction that you make on the raven app ,
                          fpr example , if you make a transfer of ₦15k , we will
                          help you save ₦650 automatically
                        </p>
                        {/* <br />
                        <p className="text grey-white-color">
                          For example, if you make a transfer of ₦13k, we will
                          help you save ₦650 automatically 😉.
                        </p> */}
                      </div>
                      {/* empty bg screen end */}
                    </StepperMainWrapper>
                  </>
                ) : (
                  <>
                    {/* normal content to show start */}
                    {!singleSavingsoShow && (
                      <SingleDetailComponent
                        setSingleSavingsToShow={setSingleSavingsToShow}
                      />
                    )}
                    {/* normal content to show end */}
                    {/* withdraw content start ----------------- */}
                    {singleSavingsoShow === "terminate" && (
                      <>
                        <WithdrawComponents
                          setContent={(param) => {
                            setSingleSavingsToShow(param);
                          }}
                        />
                      </>
                    )}
                    {/* withdraw content end ------------------- */}
                  </>
                )}
              </>
            )}
            {/* step one end */}
            {/* step two start */}
            {activeStep === 2 && (
              <>
                {/* {noTargetSavings ? <></> : <></>} */}
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Proceed"
                  title="Spend - save"
                  text="Select a percentage you will like to enable for spend-save"
                  onBack={() => {
                    setActiveStep(1);
                  }}
                  //   disabled={ }
                  onBtnClick={() => {
                    setShowModal((prev) => {
                      return { ...prev, pin: true };
                    });
                  }}
                >
                  {/* spend save input settings wrap */}
                  <div className="spend-save-input-setting-wrap">
                    <div className="percent-setting-box">
                      <p className="text grey-white-color">Select percentage</p>
                      {/* minus input add box start */}
                      <div className="minus-input-add-box border-theme">
                        <div
                          onClick={() => {
                            Number(details?.percent) > 1 &&
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  percent: Number(details.percent) - 0.5,
                                };
                              });
                          }}
                          className="minus-box grey-bg"
                        >
                          <figure className="img-box">{minusIcon}</figure>
                        </div>
                        <div className="input-box border-theme-right border-theme-left">
                          <RavenInputField
                            disabled
                            onChange={(e: any) => {
                              if (
                                Number(
                                  formatNumWithoutCommaNaira(details.percent)
                                ) <= 100
                              ) {
                                setDetails((prev) => {
                                  return {
                                    ...prev,
                                    percent: Number(
                                      formatNumWithoutCommaNaira(e.target.value)
                                    ),
                                  };
                                });
                              }
                            }}
                            className="input-value-per"
                            numberSuffix={` %`}
                            color="purple-dark"
                            type="number"
                            placeholder=" "
                            value={details?.percent}
                          />
                        </div>
                        <div
                          onClick={() => {
                            Number(details?.percent) < 100 &&
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  percent: Number(details.percent) + 0.5,
                                };
                              });
                          }}
                          className="add-box grey-bg"
                        >
                          {" "}
                          <figure className="img-box">{addIcon}</figure>
                        </div>
                      </div>
                      {/* minus input add box end */}
                      {/* raven slider start */}
                      <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        value={details?.percent as any}
                        trackClassName="example-track"
                        onChange={(e) => {
                          setDetails((prev) => {
                            return {
                              ...prev,
                              percent: e,
                            };
                          });
                        }}
                        min={0}
                        max={100}
                        step={0.5}
                      />
                      {/* raven slider end */}
                      {/* min max value box start */}
                      <div className="min-max-box">
                        <p className="min grey-white-color">0%</p>
                        <p className="min grey-white-color">100%</p>
                      </div>
                      {/* min max value box end */}
                    </div>

                    {/* note box start */}
                    <div className="note-wrap-box">
                      <p className="note">Note:</p>
                      {/* text toggle start */}
                      <div className="text-toggle-box">
                        <p className="text grey-white-color">
                          Withdrawing before your release date attracts 5.0%
                          charge o the amount saved and interest earned
                        </p>
                        <div className="small-toggle">
                          <RavenToggleSlide
                            color={`purple-dark`}
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                      {/* text toggle end */}
                    </div>
                    {/* note box end */}
                  </div>
                  {/* spend save input settings wrap end */}
                </StepperMainWrapper>
              </>
            )}
            {/* step two end */}
          </div>
        </ContainerWrapper>
        {/* content wrapper end */}
      </DashboardLayoutIndex>

      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin) => {
          //   handleSubmit(pin);
          actionCtx?.setConfettiVal();
          actionCtx?.playSound();
          setShowModal((prev) => {
            return { ...prev, success: true, pin: false };
          });
        }}
        value={details?.user_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        // loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        // onClose={() => {
        //   setShowModal((prev) => {
        //     return { ...prev, success: false };
        //   });
        // }}
        title="Transaction Successful."
        text={`You have successfully sent ₦150,000 to Ayeni Kehinde Timilehin`}
        btnText={`Go to dashboard`}
        onBtnClick={() => {
          navigate("/dashboard-overview");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default SpendSaveSavingIndex;
