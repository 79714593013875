import React, { useState } from "react";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import useWindowSize from "src/utils/UseWindowSize";
import "../../../../styles/dashboard/add-money/AddMoneyIndex.css";
// import SelectSpecialComponent from "src/components/reusables/SelectSpecialComponent";
import StepperMainWrapper from "src/components/reusables/StepperMainWrapper";
import { useNavigate } from "react-router-dom";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import { AmountInfoIconSpan } from "../../send-money/raven/AmountSpanInfo";
import { FaCheck } from "react-icons/fa";
// import { trimLongString } from "src/helper";

const AddMoneyUssdIndex = () => {
  const stepList = ["Select option", "Receive money"];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(2);
  const size = useWindowSize();
  const navigate = useNavigate();

  const [isCopied, setIsCopied] = useState(false);
  const handleCopied = async (num: string) => {
    setIsCopied(true);
    await navigator.clipboard.writeText(num);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleStepClick = (param: number) => {
    if (param === 1) {
      navigate("/dashboard-add-money");
    }
  };

  type RegisterProp = {
    username: string;
    amount: string;
    narration: string;
    bulk_list: any[];
    beneficiary: boolean;
    schedule: string;
    transfer_pin: string;
    template_title: string;
    save_template: boolean;
    account_num: string;
    bank: any;
    name: string;
    schedule_time: string;
  };

  const [details, setDetails] = useState<RegisterProp>({
    username: "",
    amount: "",
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    account_num: "",
    bank: "",
    name: "",
    schedule_time: "",
    schedule: "",
    transfer_pin: "",
    template_title: "",
    save_template: false,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const [copyContent, setCopyContent] = useState("");

  const banlOptions = [
    { label: "GTB", value: "098" },
    { label: "UBA", value: "088" },
    { label: "Union", value: "078" },
  ];

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* content wrapper start */}
        <ContainerWrapper
          activeStep={activeStep}
          stepList={stepList}
          dontShowHeader={(size.width as any) < 900}
          width={(size.width as any) < 900 && `100%`}
        >
          <div className="add-money-index-wrap-ussd">
            {" "}
            {activeStep === 2 && (
              <>
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Generate code"
                  title="USSD Top up"
                  text="Enter your bank and the amount to generate a USSD ."
                  onBack={() => {
                    activeStep === 2 && handleStepClick(1);
                  }}
                  disabled={!details?.bank || !details?.amount}
                  onBtnClick={() => {
                    setCopyContent("gfdfgh");
                  }}
                >
                  <form
                    autoComplete="off"
                    style={{
                      width: "100%",
                      position: "relative",
                      zIndex: 1000,
                    }}
                    action=""
                    className="wrap-input-box"
                  >
                    {" "}
                    <RavenInputField
                      selectOption={banlOptions}
                      // labelSpanText={InfoIconSpan(true)}
                      color={`purple-dark`}
                      type={`select`}
                      label="Bank Name* "
                      placeholder="select bank"
                      // selectComponents={{ Option: CustomOption }}
                      onChange={(e: any) => {
                        setDetails((prev) => {
                          return { ...prev, bank: e };
                        });
                      }}
                      style={{
                        width: "100%",
                        position: "relative",
                        zIndex: 1000,
                      }}
                      selectValue={details?.bank}
                      name={`bank`}
                      value={details?.bank}
                    />
                    <RavenInputField
                      labelSpanText={AmountInfoIconSpan(false, 214757) as string}
                      placeholder="How much do you want to send?"
                      type="number"
                      label="Amount"
                      color="purple-dark"
                      thousandFormat
                      numberPrefix={`₦`}
                      //   style={{ marginTop: "-1rem" }}
                      value={details?.amount}
                      onChange={handleChange}
                      name={`amount`}
                    />
                    <div
                      className={`content-display-box ${
                        copyContent && "content-display-box-show"
                      }`}
                    >
                      <div className="grey-bg-two display-wrap">
                        <div className="info-box">
                          <div className="bank-info grey-white-color">
                            <div className="name tooltip-hover-wrap"></div>
                            <p className="bank">Copy your code</p>
                          </div>
                          <p className="acc_num">*940*23000*33848849#</p>
                        </div>
                        {/* {details?.account_num && ( */}
                        <div
                          onClick={() => {
                            handleCopied(`*940*23000*33848849#`);
                          }}
                          className="copy-box  grey-bg-two"
                        >
                          <figure className="img-box">
                            {isCopied ? (
                              <FaCheck className="img icon" />
                            ) : (
                              <svg
                                width="10"
                                height="11"
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="img"
                              >
                                <path
                                  // className="dull-bright-filter"
                                  d="M2.22222 2.5V1C2.22222 0.867391 2.28075 0.740215 2.38494 0.646446C2.48913 0.552678 2.63044 0.5 2.77778 0.5H9.44444C9.59179 0.5 9.7331 0.552678 9.83728 0.646446C9.94147 0.740215 10 0.867391 10 1V7.99999C10 8.1326 9.94147 8.25978 9.83728 8.35355C9.7331 8.44731 9.59179 8.49999 9.44444 8.49999H7.77778V9.99999C7.77778 10.276 7.52778 10.5 7.21833 10.5H0.559444C0.486182 10.5004 0.413554 10.4877 0.345735 10.4628C0.277915 10.4379 0.216241 10.4011 0.164255 10.3546C0.112269 10.3082 0.0709961 10.2529 0.0428079 10.1921C0.0146198 10.1312 7.18746e-05 10.0659 0 9.99999L0.00166668 3C0.00166668 2.724 0.251667 2.5 0.561111 2.5H2.22222ZM1.11278 3.5L1.11111 9.49999H6.66667V3.5H1.11278ZM3.33333 2.5H7.77778V7.49999H8.88889V1.5H3.33333V2.5Z"
                                  fill="var(--primary-purple)"
                                />
                              </svg>
                            )}
                          </figure>{" "}
                          <span>{isCopied ? `Copied` : `Copy`}</span>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </form>
                </StepperMainWrapper>
              </>
            )}
          </div>
        </ContainerWrapper>
        {/* content wrapper end */}
      </DashboardLayoutIndex>
    </>
  );
};

export default AddMoneyUssdIndex;
