import React from "react";
import "../../styles/dashboard/fragments/BackToDashboard.css";
import { useNavigate } from "react-router-dom";

interface MyComponentProps {
  title?: string;
  text?: string;
  onBack?: () => void;
  typeTwo?: boolean;
  small?: boolean;
  styles?: any;
}

const BackToDashboard: React.FC<MyComponentProps> = ({
  text,
  onBack,
  typeTwo,
  small,
  styles,
}) => {
  const navigate = useNavigate();
  const backArrowIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M20 12H4M4 12L10 18M4 12L10 6"
        stroke="#676767"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div
      onClick={() => {
        !typeTwo && navigate("/dashboard-overview");
        typeTwo && onBack && onBack();
      }}
      className={`back-to-dashboard-wrap ${small && `back-to-dashboard-wrap-small `} `}
      style={styles}
    >
      {/* img wrap starft */}
      <div className="img-back-wrap card-bg ">
        <figure className="img-box">{backArrowIcon}</figure>
      </div>
      {/* img wrap end */}
      <p className="back-text">{text || `Back to dashboard`}</p>
    </div>
  );
};

export default BackToDashboard;
