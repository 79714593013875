// import { ThreeDots } from 'react-loader-spinner';
// import { RavenToolTip } from "@ravenpay/raven-bank-ui";

export const InfoIconSpan = (loading: boolean | false, text?: string) => {
  const infoIcon = (
    <svg
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 13.3333C3.55719 11.6817 5.67134 10.6667 8 10.6667C10.3287 10.6667 12.4428 11.6817 14 13.3333M11 5C11 6.65685 9.65685 8 8 8C6.34315 8 5 6.65685 5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return loading
    ? ((
        <div
          style={{ display: "flex", alignItems: "center", gap: ".8rem" }}
          className="info-box "
        >
          <figure style={{ width: "2rem", height: "2rem" }} className="img-box">
            {infoIcon}
          </figure>
          <p
            style={{ color: "var(--primary-purple)", fontSize: "1.45rem" }}
            className="text"
          >
            Beneficiaries
          </p>
          {/* <RavenToolTip
            // textColor="black"
            color="purple-light"
            text="Complete Address."
            position={`top-center`}
          /> */}
        </div>
      ) as unknown as string)
    : ((text || "Verify") as string);
};
