import { dashboard_add_money_routes_group } from "./add-money";
import { dashboard_bill_utilities_routes_group } from "./bill-utilities";
import { dashboard_cards_routes_group } from "./cards";
import { dashboard_overview_routes_group } from "./overview";
import { dashboard_savings_routes_group } from "./savings";
import { dashboard_send_money_routes_group } from "./send-money";
import { dashboard_transaction_routes_group } from "./transaction";
import { verification_routes_group } from "./verification";
import { dashboard_settings_routes_group } from './settings';

export const dashboard_routes_group = [
   ...dashboard_overview_routes_group,
   ...dashboard_send_money_routes_group,
   ...dashboard_add_money_routes_group,
   ...dashboard_transaction_routes_group,
   ...dashboard_bill_utilities_routes_group,
   ...dashboard_savings_routes_group,
   ...dashboard_cards_routes_group,
   ...verification_routes_group,
   ...dashboard_settings_routes_group,
  ];