import { cn } from "src/utils/helpers";
import "../../../styles/dashboard/settings/blocks/index.css";

interface RowItemProps {
  title: string;
  desc: string;
  icon: React.ReactNode;
  onClick?(): void;
}

interface SettingsTitleBlockProps {
  title: string;
  desc?: string;
  className?: string;
}

export const SettingsTitleBlock = (props: SettingsTitleBlockProps) => {
  const { title, className, desc } = props;

  if (!desc)
    return <h5 className={cn("settings-inner__row__title", className)}>{title}</h5>;

  return (
    <div className={cn("settings-inner__row__content", className)}>
      <h5 className="settings-inner__row__title">{title}</h5>
      <p>{desc}</p>
    </div>
  );
};

export const SettingsRowItem = ({ desc, icon, title, onClick }: RowItemProps) => (
  <div
    onClick={onClick}
    className={cn("settings-inner__row", onClick && "cursor-pointer")}
  >
    <div className="settings-inner__row__icon-box">
      <figure className="settings-inner__row__icon img-box fade-purple-bg grey-bg-two">
        {icon}
      </figure>
    </div>

    <SettingsTitleBlock title={title} desc={desc} />
  </div>
);

interface SettingTabProps<T> {
  options: T[];
  onChange(selected: T): void;
  /** if object is passed use key to render the object value */
  identifier?: string;
  selected: T;
}
export const SettingsTabHeader = <T,>(props: SettingTabProps<T>) => {
  const { options, onChange, identifier: key = "name", selected } = props;

  const assertValue = (ctx: T) => {
    if (typeof ctx === "string") return ctx;

    const aCtx = ctx as any;

    return aCtx?.[key] ?? "Not defined";
  };

  return (
    <div className="tab-component  grey-bg">
      {options.map((value, idx) => {
        return (
          <div
            onClick={() => onChange(value)}
            className={cn(
              "tab-component-child cursor-pointer",
              assertValue(value) === assertValue(selected) &&
                "tab-component-child--active"
            )}
            key={idx}
          >
            <p className="grey-white-color bg-white">{assertValue(value)}</p>
          </div>
        );
      })}
    </div>
  );
};
