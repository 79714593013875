import React from "react";
import { useNavigate } from "react-router-dom";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import "../../../styles/dashboard/transaction/SpendingInsight.css";
import FilterFragment from "src/components/fragments/FilterFragment";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import { CategoryList } from "src/utils/CategoryList";
import { getRandomNumber } from "src/helper";
import { DoughnutChart } from "src/components/charts/DoughnutChart";

const SpendingInsight = () => {
  const navigate = useNavigate();

  const addNumberToCatList = (param: any[]) => {
    const newList = param?.map((chi) => {
      return {
        ...chi,
        tansc_no: `${getRandomNumber(1, 15)} Transactions`,
        percentage: `35%`,
        amount: "₦205,000",
      };
    });
    return newList;
  };

  const rightArrowIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M9 18L15 12L9 6"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard
          typeTwo
          text="Transaction history"
          onBack={() => {
            navigate("/dashboard-transaction");
          }}
        />
        {/* back to dashboard end */}
        {/* container wrapper start */}
        <ContainerWrapper width={`100%`} dontShowHeader>
          <div className="spending-insight-transaction-wrap-index">
            <div className="insight-title-filter-box">
              <p className="title">Spending insights</p>
              <FilterFragment>
                <RavenInputField
                  type="select"
                  color="purple-dark"
                  placeholder="Filter by"
                />
              </FilterFragment>
            </div>
            {/* title filter box end */}
            <div className="isight-split-box">
              {" "}
              {/* spending insight left box start */}
              <div className="spending-insight-left-box border-theme-right">
                {addNumberToCatList(CategoryList)?.map((chi, idx) => {
                  const {
                    name,
                    bgColor,
                    icon,
                    color,
                    tansc_no,
                    amount,
                    percentage,
                  } = chi;
                  return (
                    <div
                      onClick={() => {
                        navigate("/dashboard-transaction-category");
                      }}
                      key={idx}
                      className="insight-item-card border-theme"
                    >
                      <div
                        style={{ backgroundColor: bgColor }}
                        className="insight-img grey-bg-two"
                      >
                        <figure className="img-box">{icon}</figure>
                      </div>
                      <div className="title-text-box">
                        <p className="title" style={{ color: color }}>
                          {name}
                        </p>
                        <p className="trans-no grey-white-color">{tansc_no}</p>
                      </div>

                      {/* percent amount box start */}
                      <div className="amount-percent-box">
                        <p className="amount">{amount}</p>
                        <p className="percent grey-white-color">{percentage}</p>
                      </div>
                      {/* percent amount box end */}

                      {/* right arrow box start */}
                      <div className="right-arrow-box">
                        <figure className="img-box">{rightArrowIcon}</figure>
                      </div>
                      {/* right arrow box end */}
                    </div>
                  );
                })}
              </div>
              {/* spending insight left box end */}
              {/* spending insight right box start */}
              <div className="spending-insight-right-box">
                {/* top box start */}
                <div className="top-box">
                  <div className="chart-wrap">
                    <DoughnutChart />
                    <div className="total-percentage-box">
                      <p className="value">₦475k</p>
                      <p className="text grey-white-color">All-time Volume</p>
                    </div>
                  </div>
                </div>
                {/* top box end */}
                {/* bottom box start */}
                <div className="bottom-box">
                  <div className="dist-box border-theme">
                    <div className="all-box border-theme-right">
                      {" "}
                      <p className="text grey-white-color">Total credit</p>
                      <p className="value">475,000.00(32%)</p>
                    </div>
                    <div className="credit-debit-box">
                      <div className="credit-box">
                        <p className="text grey-white-color">Total credit</p>
                        <p className="value">475,000.00(32%)</p>
                      </div>
                      <div className="debit-box border-theme-top">
                        <p className="text grey-white-color">Total debit</p>
                        <p className="value">475,000.00(68%)</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* bottom box end */}
              </div>
              {/* spending insight right box end */}
            </div>
          </div>
        </ContainerWrapper>
        {/* container wrapper end */}
      </DashboardLayoutIndex>
    </>
  );
};

export default SpendingInsight;
