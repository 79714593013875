import { useState } from "react";
import SettingIndexLayout from "../SettingsIndexLayout";
import { SettingsRowItem } from "../blocks";
import "src/styles/dashboard/settings/bank-statement/index.css";
import BankStatementModal from "./BankStatementModal";

const downloadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5"
      stroke="var(--primary-purple)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SettingBankStatement = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <SettingIndexLayout title="Bank Statement">
      <div>
        <div className="settings-grouped-list-item border-theme">
          <SettingsRowItem
            icon={downloadIcon}
            desc="Generate bank statement"
            title="Bank statement"
          />
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="reset-border btn-generate cursor-pointer purple-accent-box fade-purple-bg grey-bg-two"
          >
            Generate now
          </button>
        </div>
      </div>

      {showModal && <BankStatementModal onClose={() => setShowModal(false)} visible />}
    </SettingIndexLayout>
  );
};

export default SettingBankStatement;
