import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../styles/reuseables/TotalReferralModal.css";

interface MyComponentProps {
  visible?: boolean;
  onClose?: () => void;
}

const noContentImg = (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="img"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09093 0.727296C7.78494 0.727296 6.53244 1.2461 5.60897 2.16957C4.68549 3.09305 4.16669 4.34555 4.16669 5.65154V6.21487C4.16669 7.0973 4.42866 7.91866 4.87972 8.60608H3.18184C2.39825 8.60608 1.64675 8.91737 1.09266 9.47145C0.538576 10.0255 0.227295 10.777 0.227295 11.5606L0.227295 13.5303C0.227295 14.3139 0.538576 15.0654 1.09266 15.6195C1.64675 16.1736 2.39825 16.4849 3.18184 16.4849H14.0152V8.60608H15.9849V16.4849H26.8182C27.6018 16.4849 28.3533 16.1736 28.9074 15.6195C29.4615 15.0654 29.7728 14.3139 29.7728 13.5303V11.5606C29.7728 10.777 29.4615 10.0255 28.9074 9.47145C28.3533 8.91737 27.6018 8.60608 26.8182 8.60608H25.1203C25.5714 7.91866 25.8334 7.0973 25.8334 6.21487V5.65154C25.8334 4.34555 25.3146 3.09305 24.3911 2.16957C23.4676 1.2461 22.2151 0.727296 20.9091 0.727296C18.4017 0.727296 16.2055 2.06669 15 4.0679C14.3872 3.04835 13.5209 2.20482 12.4854 1.61941C11.4499 1.03401 10.2805 0.726661 9.09093 0.727296ZM15.9849 8.60608H21.4724C22.7921 8.60608 23.8637 7.53457 23.8637 6.21487V5.65154C23.8637 4.86794 23.5524 4.11644 22.9983 3.56236C22.4442 3.00827 21.6927 2.69699 20.9091 2.69699C19.6031 2.69699 18.3506 3.2158 17.4271 4.13927C16.5037 5.06274 15.9849 6.31525 15.9849 7.62124V8.60608ZM14.0152 8.60608H8.5276C7.2079 8.60608 6.13639 7.53457 6.13639 6.21487V5.65154C6.13639 4.86794 6.44767 4.11644 7.00175 3.56236C7.55584 3.00827 8.30734 2.69699 9.09093 2.69699C9.73759 2.69699 10.3779 2.82436 10.9754 3.07183C11.5728 3.31929 12.1156 3.68201 12.5729 4.13927C13.0302 4.59653 13.3929 5.13937 13.6403 5.73681C13.8878 6.33425 14.0152 6.97457 14.0152 7.62124V8.60608Z"
      fill="var(--primary-purple)"
    />
    <path
      d="M14.0152 18.4546H2.19702V25.3485C2.19702 26.6545 2.71582 27.907 3.6393 28.8305C4.56277 29.754 5.81527 30.2728 7.12126 30.2728H14.0152V18.4546ZM15.9849 30.2728H22.8788C24.1848 30.2728 25.4373 29.754 26.3608 28.8305C27.2843 27.907 27.8031 26.6545 27.8031 25.3485V18.4546H15.9849V30.2728Z"
      fill="var(--primary-purple)"
    />
  </svg>
);

const TotalReferralModal: React.FC<MyComponentProps> = ({ visible, onClose }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [noReferal, setNoReferal] = useState(true);
  return (
    <RavenModal onClose={onClose} visble={visible} onBtnClick={() => {}}>
      <div className="total-referral-modal-wrap-index">
        <div className="title-text-box">
          <p className="title">Referrals and rewards</p>
          <p className="text grey-white-color">Refer friends and earn cash rewards</p>
        </div>

        {/* total cash detail box start */}
        <div className="total-cash-detail-box border-theme">
          <div className="top-part-box top-part-box-two border-theme-bottom">
            {/* value label start */}
            <div className="value-label-box">
              <p className="label grey-white-color">Total cash earned</p>
              <p className="value">₦400.00</p>
            </div>
            {/* value label end */}
            {/* value label start */}
            <div className="value-label-box">
              <p className="label grey-white-color">Friends who joined</p>
              <p className="value">3</p>
            </div>
            {/* value label end */}
          </div>
          <div className="bottom-part-box">
            {/* value label start */}
            <div className="value-label-box">
              <p className="label grey-white-color">Pending</p>
              <p className="value">₦400.00</p>
            </div>
            {/* value label end */}
          </div>
        </div>
        {/* total cash detail box end */}

        {/* total referal box start */}
        <div className="total-referral-invite-box border-theme">
          <div className="top-part-box border-theme-bottom">
            {/* value label start */}
            <div className="value-label-box">
              <p className="value">Your invites</p>
            </div>
            {/* value label end */}
          </div>
          {/* invite referal box start */}
          <div className="invite-referal-box-wrap">
            {noReferal ? (
              <>
                <div className="wrap-center">
                  <div className="img-wrap-box">
                    {" "}
                    <figure className="img-box">{noContentImg}</figure>
                  </div>
                  <p className="title">You have no referrals yet</p>
                  <p className="text grey-white-color">
                    Copy your referral code or share your link with your friends and
                    family to earn extra cash and more goodies when they join and use
                    Raven.
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {/* invite referal box end */}
        </div>
        {/* total referal box end */}
      </div>
    </RavenModal>
  );
};

export default TotalReferralModal;
