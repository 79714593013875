import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React from "react";
// import "../../styles/reusables/ReusableScheduleBox.css";
import moment from "moment";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

interface MyComponentProps {
  onChange?: (param: any) => void;
  value?: string;
  id?: string;
  enableTime: boolean;
  onReset: () => void;
  textSchedule: string;
}


const NewSelectScheduleBox: React.FC<MyComponentProps> = ({
  onChange,
  value,
  id,
  enableTime,
  onReset,
  textSchedule,
}) => {
  return (
    <div className="new-reusable-schedule-box animate-move-up-class">
      {value && <p className="text-schedule">{`Schedule time: `}</p>}

      <div className="date-arrow-wrap">
        {!value && (
          <label
            style={{ cursor: "pointer" }}
            htmlFor={`select-raven-schedule-${id}`}
          >
            {" "}
            <span className="grey-white-color">{textSchedule || ""}</span>
          </label>
        )}
        <form
          style={{ width: !value ? "1rem" : "100%" }}
          autoComplete="off"
          action=""
        >
          {" "}
          <RavenInputField
            type={`date`}
            value={value}
            onChange={(e: any) => {
              onChange && onChange(moment(e[0]).format("YYYY-MM-DD HH:mm:ss"));
            }}
            id={`select-raven-schedule-${id}`}
            color={`black-light`}
            className={`input-date  ${value && "input-date-long"}`}
            placeholder={" "}
            enableTime={true}
            // dateOptions={{weekNumbers: true,}}
            scheduleFormat={true as any}
          />
        </form>

        {!value && (
          <label htmlFor={`select-raven-schedule-${id}`}>
            <FaAngleDown className="icon" />
          </label>
        )}
        {!value && (
          <label
            style={{
              cursor: "pointer",
              transform: "translateY(15%) translateX(-10%)",
              marginLeft: "auto",
            }}
            htmlFor={`select-raven-schedule-${id}`}
          >
            {" "}
            <FaAngleRight />
          </label>
        )}
      </div>
      {value && (
        <p
          onClick={() => {
            onReset && onReset();
          }}
          className="reset"
        >
          Reset
        </p>
      )}
    </div>
  );
};

export default NewSelectScheduleBox;
