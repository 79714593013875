import React from "react";
import "../../styles/dashboard/fragments/PaginationFragment.css";

interface MyComponentProps {
  total?: string | number;
  to?: string | number;
  from?: string | number;
  prev?: boolean;
  next?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
}

const rightIcon = (
  <svg
    className="img"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dull-bright-filter"
      d="M7.5 15L12.5 10L7.5 5"
      stroke="#014345"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const leftIcon = (
  <svg
    className="img"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dull-bright-filter"
      d="M12.5 15L7.5 10L12.5 5"
      stroke="#014345"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PaginationFragment: React.FC<MyComponentProps> = ({
  total,
  to,
  from,
  next,
  onNext,
  onPrev,
  prev,
}) => {
  return (
    <div className="pagination-fragment-index-wrap">
      <p className="from">{from || "1"}</p>
      <p>-</p>
      <p className="to">{to || "5"}</p>
      <p>of</p>
      <p className="total">{total || "10"}</p>
      {/* left icon start */}
      <div
        onClick={() => {
          onPrev && onPrev();
        }}
        className={`left-icon-box ${!prev && "disabled"}`}
      >
        <figure className="img-box">{leftIcon}</figure>
      </div>
      {/* left icon end */}
      {/* right icon start */}
      <div
        onClick={() => {
          onNext && onNext();
        }}
        className={`right-icon-box ${!next && "disabled"}`}
      >
        <figure className="img-box">{rightIcon}</figure>
      </div>
      {/* right icon end */}
    </div>
  );
};

export default PaginationFragment;
