import "../../../styles/dashboard/settings/SettingsIndex.css";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import BottomNavComponent from "src/layout/dashboard/BottomNavComponent";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import SettingSidebar from "./SettingsSidebar";
import TitleTextBox from "src/components/reusables/TitleTextBox";
import { cn } from "src/utils/helpers";
import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useOnClickOutside } from "src/utils/UseOutsideClick";
import useWindowSize from "src/utils/UseWindowSize";
import { useNavigate } from "react-router-dom";

interface SettingIndexLayoutProps extends IChildren {
  title: string | React.ReactNode;
}

const SettingIndexLayout = (props: SettingIndexLayoutProps) => {
  const { children, title } = props;
  const size = useWindowSize();
  const [showSideBar, setShowSideBar] = useState(false);
  const navigate = useNavigate();
  const testingSidebarSettingRef = useOnClickOutside(() => {
    // setShowSideBar(false);
  });

  const backIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M13.3332 8H2.6665M2.6665 8L6.6665 12M2.6665 8L6.6665 4"
        stroke="#676767"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <DashboardLayoutIndex>
      {(size.width as any) > 900 ? (
        <TitleTextBox title="Settings" text="Configure your profile to fit your taste." />
      ) : (
        <>
          <div
            onClick={() => {
              navigate("/dashboard-settings-index");
            }}
            className="mobile-custom-title-box-index-wrap"
          >
            <div className="icon-wrap-box card-bg">
              <figure className="img-box">{backIcon}</figure>
            </div>
            <p className="text">Settings</p>
          </div>
        </>
      )}
      {/* mobile menus start */}
      <div className="menu-mobile-icon-settings-box">
        <div
          onClick={() => {
            setShowSideBar(!showSideBar);
          }}
          className="icon-box  border-theme"
          ref={testingSidebarSettingRef}
        >
          {showSideBar ? (
            <FaChevronLeft className="icon grey-white-color-white" />
          ) : (
            <FaChevronRight className="icon grey-white-color-white" />
          )}
        </div>
      </div>
      {/* mo bile menu end */}
      <ContainerWrapper dontShowHeader width="100%">
        <section className="settings-index-wrap">
          <SettingSidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
          <div className="settings-index__content border-theme shadow-shadow-box-shadow">
            <div
              className={cn(
                "settings-index__content__title border-theme-bottom",
                typeof title === "string" && "sic-title "
              )}
            >
              {title}
            </div>
            <div className="settings-index__content__body  hide-scrollbar">
              {children}
            </div>
          </div>
        </section>
      </ContainerWrapper>
      <BottomNavComponent />
    </DashboardLayoutIndex>
  );
};

export default SettingIndexLayout;
