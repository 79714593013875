import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
// import VerificationLayout from "../../../layout/dashboard/VarificationLayout";
import "../../../styles/dashboard/verification/verification.css";
// import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import VerificationLayout from "src/layout/dashboard/VerificationLayout";
import ReuseVerified from "./ReusedVerifyBox";
import { reactSelectStyle } from "src/helper";
import SuccessModal from "src/components/fragments/TransactionSuccessModal";

const AddressVerification = () => {
  //   const { business } = useSelector((state) => state.user);
//   const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  //   const { loading, states, Lgas } = useSelector((state) => state.verifications);
  type RegisterProp = {
    country: string | any;
    state: string | any;
    lga: string;
    street_address: string;
    file: string | any;
    closest_landmark: string;
    house_no: string;
  };
  const [details, setDetails] = useState<RegisterProp>({
    country: "Nigeria",
    state: "",
    lga: "",
    street_address: "",
    file: "",
    closest_landmark: "",
    house_no: "",
  });

//   const handleChange = (e: any) => {
//     const { name, value } = e.target;
//     const obj = { ...details, [name]: value };
//     setDetails(obj);
//   };

  useEffect(() => {
    // dispatch(getStates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusCheck, setStatusCheck] = useState(0);
  //   useEffect(() => {
  //     if (Object.keys(business).length > 0) {
  //       setStatusCheck(Number(business?.address));
  //     }
  //   }, [business]);

//   const handleSubmit = async () => {
//     const obj = {
//       ...details,
//       file: details?.file,
//       //   state: details?.state?.value,
//       //   lga: details?.lga?.value,
//       country: details?.country,
//     };
//     const formData = new FormData();
//     formData.append("file", obj?.file);
//     formData.append("street_address", details?.street_address);
//     formData.append("state", obj?.state);
//     formData.append("lga", obj?.lga);
//     formData.append("country", obj?.country);
//     //  let dataVal;
//     //     for (const [key, value] of formData) {
//     //       //  console.log(`${key}: ${value}\n`)
//     //        dataVal = value
//     //        console.log(formData);
//     //     }
//     //     return
//     // const data = await dispatch(verifyAddressInformation(formData));
//     // if (data?.payload?.data?.status === "success") {
//     //   //   dispatch(getUser());
//     //   setDetails((prev) => {
//     //     return {
//     //       ...prev,
//     //       country: "Nigeria",
//     //       state: "",
//     //       lga: "",
//     //       street_address: "",
//     //       file: "",
//     //       closest_landmark: "",
//     //       house_no: "",
//     //     };
//     //   });
//     //   setShowModal(true);
//     // }
//   };

//   const navigate = useNavigate();
  //   useEffect(() => {
  //     if (Object?.keys(business)?.length > 0 && business?.nin !== 3) {
  //       // navigate("/dashboard-verification-nin");
  //       const urlLink = window?.location.href.split("?")[1];
  //       if (urlLink) {
  //         navigate(`/dashboard-verification-nin?${urlLink}`);
  //       } else {
  //         // console.log(location.pathname);
  //         navigate("/dashboard-verification-nin");
  //       }
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [business]);

  return (
    <>
      <VerificationLayout>
        <div className="verification-wrap-box">
          <div className="text-box-verify">
            <p className="big-title">Verify your address</p>
            <p className="small-title">
              Verify your address to increase your limits on Raven
            </p>
          </div>
          {/* text box end */}
          {/* form start */}
          {statusCheck === 3 || statusCheck === 1 || statusCheck === 2 ? (
            <ReuseVerified
              // text={`Your address information is verified successfully, proceed to other verifications to complete your setup.`}
              text={
                statusCheck === 1
                  ? `Your verification is pending, and you would be notified when, you have been verified`
                  : statusCheck === 2
                  ? `NOK not verified, we couldn’t verify your NOK at this moment restart verification`
                  : `Your NOK information is verified successfully, proceed to other verifications to complete your setup..`
              }
              pending={statusCheck === 1}
              failed={statusCheck === 2}
              //   onFailClick={() => setStatusCheck(0)}
            />
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // handleSubmit(e);
              }}
              action=""
              className="form form-business"
              autoComplete="off"
            >
              <RavenInputField
                type={`select`}
                label={`State of residence?`}
                placeholder="E.g Lagos State"
                selectStyles={reactSelectStyle}
                color={`purple-dark`}
                name="state"
                onChange={(e: any) => {
                  // dispatch(getLgaByStates(e?.label));
                  setDetails((prev) => {
                    return { ...prev, state: e, lga: "" };
                  });
                }}
                value={details?.state}
                //   selectOption={states}
                style={{ position: "relative", zIndex: "11" }}
              />
              <RavenInputField
                type={`select`}
                label={`LGA`}
                placeholder="select lga"
                selectStyles={reactSelectStyle}
                color={`purple-dark`}
                name="state"
                onChange={(e: any) => {
                  // dispatch(getLgaByStates(e?.label));
                  setDetails((prev) => {
                    return { ...prev, state: e, lga: "" };
                  });
                }}
                value={details?.state}
                //   selectOption={states}
                style={{ position: "relative", zIndex: "11" }}
              />
              <RavenInputField
                type={`submit`}
                color="purple-dark"
                value={`Verify and proceed`}
                // style={{marginTop: "2.5rem"}}
                disabled={
                  !details?.country ||
                  !details.file ||
                  !details?.house_no ||
                  !details?.lga ||
                  !details?.state ||
                  !details?.street_address
                }
                className="btn-submit"
                // loading={loading}
              />
            </form>
          )}
          {/* form end */}
        </div>
      </VerificationLayout>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal}
        // onClose={() => setShowModal(false)}
        title="Address Verification Successful."
        text={`Congratulations! Your address has been successfully verified. You can now enjoy seamless transactions and reliable services with confidence.`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal(false);
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default AddressVerification;
