import FixedSavingIndex from "src/pages/dashboard/savings/fixed/FixedSavingsIndex";
import SingleFixedSavings from "src/pages/dashboard/savings/fixed/SingleFixedSavings";


export const dashboard_savings_fixed_routes_group = [
    {
      path: "/dashboard-savings-fixed",
      element: FixedSavingIndex,
    },
    {
      path: "/dashboard-savings-fixed-single",
      element: SingleFixedSavings,
    },
  ];
  