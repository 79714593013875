import React from "react";
// import NewSelectScheduleBox from "../../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../styles/dashboard/fragments/NewPreviewDetailBox.css";
// import bankIconDefault from "../../../../assets/bank-general-img.svg"
// import bankIconDefault from "../../../../assets/raven-default-bank-img.svg";
import { RavenInputField, RavenToggleSlide } from "@ravenpay/raven-bank-ui";
// import moment from "moment";
// import { formatDate, trimLongString } from "../../../../utils/helper/Helper";
// import { FaArrowRight, FaTimes } from "react-icons/fa";
// import ChangeSourceAccount from "../../../../components/reusables/ChangeSourceAccount";

interface MyComponentProps {
  collapse?: boolean;
  amount?: string;
  name?: string;
  bank?: any;
  account_name?: string;
  narration?: string;
  onChange?: () => void;
  value?: any;
  id?: any;
  checked?: boolean;
  onChangeSchedule?: (param: any) => void;
  valueSchedule?: any;
  onReset?: () => void;
  details?: any;
  bulkAmount: string;
  setDetails?: (param: any) => void;
  handleRemoveFromList?: (param: any) => void;
  handleChange?: (param: any) => void;
  transferFee?: string;
  list?: any[] | any;
  onSubmit?: () => void;
}

const cardIcon = (
  <svg
    className="img"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: "55%", height: "55%", objectFit: "contain" }}
  >
    <path
      d="M1.75 20.5625C1.75 21.3747 2.07266 22.1537 2.64699 22.728C3.22132 23.3023 4.00027 23.625 4.8125 23.625H23.1875C23.9997 23.625 24.7787 23.3023 25.353 22.728C25.9273 22.1537 26.25 21.3747 26.25 20.5625V12.1406H1.75V20.5625ZM5.35938 16.4062C5.35938 15.9711 5.53223 15.5538 5.8399 15.2462C6.14758 14.9385 6.56488 14.7656 7 14.7656H9.625C10.0601 14.7656 10.4774 14.9385 10.7851 15.2462C11.0928 15.5538 11.2656 15.9711 11.2656 16.4062V17.5C11.2656 17.9351 11.0928 18.3524 10.7851 18.6601C10.4774 18.9678 10.0601 19.1406 9.625 19.1406H7C6.56488 19.1406 6.14758 18.9678 5.8399 18.6601C5.53223 18.3524 5.35938 17.9351 5.35938 17.5V16.4062ZM23.1875 4.375H4.8125C4.00027 4.375 3.22132 4.69766 2.64699 5.27199C2.07266 5.84632 1.75 6.62527 1.75 7.4375V8.85938H26.25V7.4375C26.25 6.62527 25.9273 5.84632 25.353 5.27199C24.7787 4.69766 23.9997 4.375 23.1875 4.375Z"
      fill="white"
    />
  </svg>
);

const NewPreviewDetailBox: React.FC<MyComponentProps> = ({
  onSubmit,
  valueSchedule,
  details,
  bulkAmount,
}) => {
  // const [viewIndex, setViewIndex] = useState(0);

  return (
    <div className="new-preview-detail-box-wrap card-bg border-theme">
      {/* img top box start */}
      <div className="img-top-box-wrap border-theme-bottom">
        <div className="img-wrap">
          <figure
            style={{
              backgroundColor: "var(--primary-purple)",
              borderRadius: "50%",
              placeItems: "center",
              display: "grid",
            }}
            className="img-box"
          >
            {cardIcon}
            {/* <img src={bankIconDefault} alt="" className="img" /> */}
          </figure>
          {/* <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div> */}
        </div>

        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">{bulkAmount}</p>
          <p className="date grey-white-color-white">{`Amount in Card`}</p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      <div className="scroll-content-wrap">
        {" "}
        {/* middle content wrap start */}
        <div className="middle-content-wrap border-theme-bottom">
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color-white">Card Title:</p>
            <p className="value">{details?.card_title || ""}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color-white">Card Processing Fee:</p>
            <p className="value">{details?.fee || ""}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color-white">Card Type:</p>
            <p className="value">{details?.type || ""}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color-white">Card Provider:</p>
            <div className="value">{details?.provider || ""}</div>
          </div>
          {/* box end */}
        </div>
        {/* middle content wrap end */}
          {/* note wrap bg grey start */}
      <div className="note-wrap-bg-grey-box grey-bg">
        <p className="note">Note</p>
        <p className=" text grey-white-color">
          You would be debited the total of ₦5,000 for this transaction at
          today’s market rate at ₦800.00 to ₦1.
        </p>
        <p className=" text grey-white-color">
          These exchange rates are provided by independent 3rd parties who
          handle fund conversions at prevailing market rates and are not set or
          controlled by Raven. They are subject to change based on market
          trends.
        </p>
      </div>
      {/* note wrap bg grey end */}
      {/* i accept box start */}
      <div className="accept-box-wrap">
        <p className="text-accept">I accept</p>
        <div className="toggle">
          {" "}
          <RavenToggleSlide
            // checked={details?.accept}
            onChange={() => {
              // setDetails((prev) => {
              //   return { ...prev, accept: !details?.accept };
              // });
            }}
            color={`purple-dark`}
          />
        </div>
      </div>
      {/* i accept box end */}
      </div>
    
      {/* Raven submit start */}
      <div
        style={{ width: "100%", marginTop: "1.5rem" }}
        className="btn-reuseSubmit-box"
      >
        <RavenInputField
          //   style={{ marginTop: "2.5rem" }}
          color="purple-dark"
          type="submit"
          value={`Confirm card creation`}
          onSubmit={onSubmit}
        />
      </div>
      {/* Raven submit end */}
    </div>
  );
};

export default NewPreviewDetailBox;
