import React from "react";
import "../../styles/dashboard/reusables/TitleTextBox.css";

interface MyComponentProps {
  children?: React.ReactNode;
  // children:
  title?: string;
  text?: string;
  dontShow?: boolean;
}

const TitleTextBox: React.FC<MyComponentProps> = ({
  title,
  text,
  children,
  dontShow,
}) => {

    if(dontShow){
        return<></>
    }

  return (
    <div className="title-text-index-box-wrap">
      <div className="title-text-box">
        <p className="title">{title || "Nothing to see here for now..."}</p>
        <p className="text grey-white-color">{text}</p>
      </div>
      {/* children box start */}
      <div className="children-box">{children}</div>
      {/* children box end */}
    </div>
  );
};

export default TitleTextBox;
