import { useState } from "react";
import "../../../../styles/dashboard/settings/preferences/index.css";
import SettingIndexLayout from "../SettingsIndexLayout";
import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import { PreferenceIcons, checkIcon, ThemeIllustration } from "./icons";
import { SettingsRowItem as RowItem, SettingsTitleBlock } from "../blocks";
import { cn } from "src/utils/helpers";
import { accentColors } from "src/helper";
import useWindowSize from "src/utils/UseWindowSize";

const PreferenceFields = [
  {
    icon: PreferenceIcons.Alarm,
    title: "Email notification",
    name: "email",
    desc: "Access to receiving Email notifications for your transactions",
  },
  {
    icon: PreferenceIcons.VolumeMax,
    name: "transaction",
    title: "Transaction sound",
    desc: "Play a  sound when you perform transactions",
  },
  {
    icon: PreferenceIcons.Palette,
    title: "Change accents ",
    desc: "Select an overall theme of your app, pick a new theme below",
  },
];

const themeOptions = [
  { name: "Light", icon: ThemeIllustration.light },
  { name: "Dark", icon: ThemeIllustration.dark },
  { name: "System preference", icon: ThemeIllustration.system },
] as const;

type ThemeOption = (typeof themeOptions)[number]["name"];

type AccentColors = (typeof accentColors)[number]["name"];

const SettingPreferenceIndex = () => {
  const [selected, setSelected] = useState<AccentColors>(
    (localStorage.getItem("accent-color") as any) || "purple"
  );
  const [selectedTheme, setSelectedTheme] = useState<ThemeOption>("Light");

  const handleAccentColor = (
    color?: string,
    fadeBg?: string,
    iconFadeBg?: string,
    name?: string
  ) => {
    document.documentElement.style.setProperty("--primary-purple", color as string);
    document.documentElement.style.setProperty("--fade-purple-bg", fadeBg as string);
    document.documentElement.style.setProperty("--icon-fade-bg", iconFadeBg as string);
    setSelected(name as any);
    localStorage.setItem("accent-color", name as string);
  };

  const size = useWindowSize()

  return (
    <SettingIndexLayout title="Preferences">
      <div className="settings-preference__list">
        {PreferenceFields.slice(0, 2).map((ele, idx) => (
          <div className={`settings-preference__list__item ${size.width as any < 900 && "border-theme"}`} key={ele.name}>
            <RowItem {...ele} />
            <RavenToggleSlide id={`${ele.name}-${idx}`} color="purple-light" />
          </div>
        ))}
        <div className={`settings-preference__list__item ${size.width as any < 900 && "border-theme"}`}>
          <RowItem {...PreferenceFields[2]} icon={PreferenceIcons.Palette} />
          <div className="settings-preference__accent_row">
            {accentColors.map(({ color, name, fadeBg, iconFadeBg }) => (
              <button
                onClick={() => {
                  handleAccentColor(color, fadeBg, iconFadeBg, name);
                }}
                type="button"
                className={name === selected ? "selected-accent" : undefined}
                style={{ backgroundColor: color }}
              >
                <figure
                  className={selected === name ? "selected-accent-icon" : undefined}
                >
                  {checkIcon}
                </figure>
              </button>
            ))}
          </div>
        </div>
        <div className="settings-preference__footer">
          <SettingsTitleBlock title="Theme Preference" />
          <div className="settings-preference__theme__wrap">
            {themeOptions.map(({ icon, name }) => {
              const active = selectedTheme === name;

              return (
                <div
                  key={name}
                  onClick={() => setSelectedTheme(name)}
                  className={cn(
                    "settings-preference__theme",
                    active && "settings-preference__theme--active"
                  )}
                >
                  <div style={{ opacity: active ? 1 : 0 }} className="checkicon">
                    {checkIcon}
                  </div>
                  <figure className="settings-preference__theme__icon">{icon}</figure>
                  <div className="settings-preference__theme__text">{name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </SettingIndexLayout>
  );
};

export default SettingPreferenceIndex;
