import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import ScrollToTop from "./helper/ScrollTop";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { auth_routes_group } from "./routes/auth";
import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import PrivateRouteDashboard from "./routes/PrivateRoute";
import Confetti from "react-confetti";
import { dashboard_routes_group } from "./routes/dshboard";
import ActionContext from "./context/ActionContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import { accentColors } from "./helper";
// import FreshchatComponent from "./components/reusables/FreshChat";

function App() {
  const location = useLocation();
  const actionCtx = useContext(ActionContext);
  // const channelID = "";
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   // script.src = `//fw-cdn.com/10934263/3694369.js?channelId=${channelID}`;
  //   script.src = `//fw-cdn.com/10934263/3694369.js`;
  //   script.setAttribute("chat", "true");

  //   // Add an event listener to check when the script has loaded
  //   script.onload = () => {
  //     if (window.fcWidget) {
  //       window.fcWidget.init({
  //         token: '10934263',
  //         host: '3694369',
  //         config: {
  //           cssNames: {
  //             widget: 'custom-freshchat-widget',
  //             button: 'custom-freshchat-button',
  //             // Add more custom class names as needed
  //           },
  //         },
  //       });
  //     }
  //   };

  //   // Append the script to the document's body
  //   document.body.appendChild(script);

  //   // Cleanup by removing the script from the DOM when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  type RouteProp = {
    path: string;
    element: () => any;
    // location:
  };

  useEffect(() => {
    const colorName = localStorage.getItem("accent-color");
    if (colorName === "green" || colorName === "purple" || colorName === "orange") {
      const obj = accentColors?.find((chi) => chi?.name === colorName);
      document.documentElement.style.setProperty(
        "--primary-purple",
        obj?.color as string
      );
      document.documentElement.style.setProperty(
        "--fade-purple-bg",
        obj?.fadeBg as string
      );
      document.documentElement.style.setProperty(
        "--icon-fade-bg",
        obj?.iconFadeBg as string
      );
    } else {
      document.documentElement.style.setProperty(
        "--primary-purple",
        "rgba(117, 90, 226, 1)"
      );
      document.documentElement.style.setProperty(
        "--fade-purple-bg",
        "rgba(241, 238, 252, 1)"
      );
      document.documentElement.style.setProperty(
        "--icon-fade-bg",
        "rgba(180, 164, 252, 1)"
      );
    }
  }, []);

  return (
    <Provider store={store}>
      <div className="App">
        <Helmet>
          <title>Raven Personal Banking || The People’s Bank of Africa </title>
          {/* <title>The Raven Bank || The People’s Bank of Africa </title> */}
          <meta
            name="description"
            content="Raven Bank allows you to transfer money to other banks for free; Get Loans and up to 15% ROI on your savings plan."
          />
          <meta
            property="og:description"
            content="Raven Bank allows you to transfer money to other banks for free; Get Loans and up to 15% ROI on your savings plan."
          />
          <meta
            name="keywords"
            content="bank, banking, loan, savings, roi, interest, nigeria, africa, fintech,  Speed, fast transactions, corporate account, business account, official account, loan, overdraft, savings, payroll, inventory, invoice, receipts, account management, Fast transfers, speed, fastest bank, digital banking, online banking, savings, high interest savings, fixed savings, target savings, investment, overdraft, Bills Payment, airtime, data, electricity bills payment, cable TV subscription, virtual cards, physical cards, debit cards, virtual dollar cards, virtual naira cards, fast internet (Raven Feather Internet"
          />
          <meta name="page-topic" content="Banking" />
          <meta name="page-type" content="Landing" />
          <meta name="audience" content="Everyone" />
          <meta
            property="og:image"
            content="https://s3.eu-west-2.amazonaws.com/img.creativepool.com/files/candidate/portfolio/full/2022460.jpg"
          />
          <meta property="og:url" content="https://getravenbank.com" />
          <meta name="robots" content="index, follow" />
          <meta
            property="twitter:image:src"
            content="https://s3.eu-west-2.amazonaws.com/img.creativepool.com/files/candidate/portfolio/full/2022460.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="GetRavenBank" />
          <meta name="twitter:site" content="https://www.twitter.com/theravenbank" />
          <meta
            httpEquiv="Content-Security-Policy"
            content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
          />
        </Helmet>
        <ScrollToTop />
        <Routes location={location}>
          <Route
            // location={location}
            path="/"
            element={<Navigate to="/login" />}
          />
          {/* <Route location={location} path="*" element={<NoPageFound />} /> */}
          <Route path="*" element={<h1>404 Not Found</h1>} />
          {/* auth group start */}
          {auth_routes_group?.map((route: RouteProp, idx: number) => (
            <Route key={idx} path={route.path} element={<route.element />} />
          ))}
          {/* auth group end */}
          {/* auth group end */}
          {/* verification group start */}
          {/* {verification_routes_group?.map((route: RouteProp, idx: number) => (
          <Route key={idx} path={route.path} element={<route.element />} />
        ))} */}
          {/* verification group end */}
          {/* dashboard group start */}
          {dashboard_routes_group?.map((route: RouteProp, idx: number) => (
            <Route
              key={idx}
              path={route.path}
              element={<PrivateRouteDashboard>{<route.element />}</PrivateRouteDashboard>}
            />
          ))}
          {/* dashboard group end */}
        </Routes>
        {actionCtx?.confettiVal && (
          <div
            className=""
            style={{ zIndex: 100000000, position: "fixed", top: "0", left: "0" }}
          >
            <Confetti />
          </div>
        )}
      </div>
    </Provider>
  );
}

export default App;
