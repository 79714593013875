import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import { FaTimes } from "react-icons/fa";
// import { trimLongString } from "src/helper";
import mtnIcon from "../../../../assets/mtnIcon.svg";
import mobileIcon from "../../../../assets/mobile-icon.svg";
import gloIcon from "../../../../assets/glo-icon.svg";
import airtelIcon from "../../../../assets/airtel-icon.svg";

interface MyComponentProps {
  list: any[];
  onClose: () => void;
  visible: boolean;
  handleRemoveFromList: (obj: any) => void;
}

const AllRecipientModal: React.FC<MyComponentProps> = ({
  list,
  onClose,
  visible,
  handleRemoveFromList,
}) => {

  return (
    <RavenModal
      onClose={onClose}
      visble={visible}
      onBtnClick={function (e?: any): void {
        throw new Error("Function not implemented.");
      }}
    >
      {/* all recipient modal wrap start */}
      <div className="all-recipient-modal-wrap">
        {/* text title box start */}
        <div className="title-text-box">
          <div className="title">
            All Recipents{" "}
            <div className="num-box fade-purple-bg grey-bg-two">
              <span className="num">{list?.length}</span>
            </div>
          </div>
          <p className="text grey-white-color">
            This is the list of everyone you want to send money to
          </p>
        </div>
        {/* text title box end */}
        {/* view all box start */}
        <div className={`view-all-box-recipient`}>
          <div className="wrap-all">
            {list?.map((chi: any, idx) => {
              const { provider } = chi;
              return (
                <div key={idx} className="child-wrap border-theme grey-bg">
                  <figure className="img-box">
                    {" "}
                    <img
                      src={
                        provider === "mtn"
                          ? mtnIcon
                          : provider === "glo"
                          ? gloIcon
                          : provider === "airtel"
                          ? airtelIcon
                          : mobileIcon
                      }
                      alt=""
                      className="img"
                    />
                  </figure>
                  <div className="text-box">
                    <p className="name">{chi?.phone}</p>
                    {/* <p className="text grey-white-color">{`${
                      trimLongString(chi?.account_name, 15) || "---"
                    } • ${chi?.amount}`}</p> */}
                        <p className="text grey-white-color">{` ${chi?.amount}`}</p>
                  </div>
                  <div
                    onClick={() => {
                      handleRemoveFromList(chi);
                    }}
                    className="cancel-box"
                  >
                    <FaTimes className="icon" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* view all box end */}
      </div>
      {/* all recipient modal wrap end */}
    </RavenModal>
  );
};

export default AllRecipientModal;
