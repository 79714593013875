import SendMoneyIndex from "src/pages/dashboard/send-money/SendMoneyIndex";
import BankSendMoneyIndex from "src/pages/dashboard/send-money/bank/SendToBankIndex";
import SendViaCardIndex from "src/pages/dashboard/send-money/card/SendToCard";
import RavenSendMoneyIndex from "src/pages/dashboard/send-money/raven/RavenSendMoneyIndex";

export const dashboard_send_money_routes_group = [
  {
    path: "/dashboard-send-money",
    element: SendMoneyIndex,
  },
  {
    path: "/dashboard-send-money-raven",
    element: RavenSendMoneyIndex,
  },
  {
    path: "/dashboard-send-money-bank",
    element: BankSendMoneyIndex,
  },
  {
    path: "/dashboard-send-money-card",
    element: SendViaCardIndex,
  },
];
