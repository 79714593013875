import "src/styles/dashboard/settings/bank-statement/index.css";
import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import { useState } from "react";
import { cn } from "src/utils/helpers";
import { checkIcon } from "../preferences/icons";

interface BankStatementModalProps {
  onClose(): void;
  visible: boolean;
}

interface SelectTypeProps {
  text: string;
  selected?: boolean;
  onClick(): void;
}
const SelectType = ({ text, selected, onClick }: SelectTypeProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(selected && "selected-statement", "bs-select-statement")}
    >
      <span>{text}</span>
      {selected && <figure className="bg-primary">{checkIcon}</figure>}
    </div>
  );
};

const tabs = ["Range date select", "Custom date select"];

const fileTypes = ["PDF", "CSV", "Both"];
const locationTypes = ["Email", "Browser", "Both"];

const BankStatementModal = ({ onClose, visible }: BankStatementModalProps) => {
  const [selected, setSelected] = useState(tabs[0]);
  const [selectedType, setSelectedType] = useState(fileTypes[0]);
  const [selectedLocation, setSelectedLocation] = useState(fileTypes[0]);

  // const [statementDetails, setStatementDetails] = useState({
  //   file: fileTypes[0],
  //   location: fileTypes[0],
  // });

  // const handleChange

  return (
    <RavenModal
      onClose={onClose}
      visble={visible}
      btnColor="purple-light"
      onBtnClick={onClose}
      btnLabel="Generate"
    >
      <div className="all-recipient-modal-wrap security-change-password-modal">
        <div className="title-text-box">
          <div className="title">Generate statement</div>
          <p style={{ marginTop: "0.4rem" }} className="text grey-white-color">
            Select how to generate statement
          </p>
        </div>
        <div className="bank-statement__modal">
          <div className="rss-input rss-tab">
            <div className="bank-statement__header-tabs">
              {tabs.map((tab) => (
                <button
                  type="button"
                  className={cn(
                    "btn-reset cursor-pointer grey-bg-two",
                    selected === tab && "tab-active "
                  )}
                  onClick={() => setSelected(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>
          <div className="bank-statement__tab-content">
            {selected === tabs[0] && (
              <RavenInputField type="select" color="purple-dark" label="Select Range" />
            )}
            {selected === tabs[1] && (
              <div className="bs-tab-two">
                <RavenInputField
                  type="date"
                  color="purple-dark"
                  label="Select Start date"
                />
                <RavenInputField
                  type="date"
                  color="purple-dark"
                  label="Select End date"
                />
              </div>
            )}
            <div className="select-file-type">
              <p className="text">Select file type</p>
              <div className="select-file-type__tabs">
                {fileTypes.map((type) => (
                  <SelectType
                    onClick={() => setSelectedType(type)}
                    text={type}
                    key={type}
                    selected={selectedType === type}
                  />
                ))}
              </div>
            </div>
            <div className="select-file-type">
              <p className="text">File location</p>
              <div className="select-file-type__tabs">
                {locationTypes.map((type) => (
                  <SelectType
                    onClick={() => setSelectedLocation(type)}
                    text={type}
                    key={type}
                    selected={selectedLocation === type}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </RavenModal>
  );
};

export default BankStatementModal;
