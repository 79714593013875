import SettingProfileIndex from "src/pages/dashboard/settings/profile/index";
import SettingPreferenceIndex from "src/pages/dashboard/settings/preferences/index";
import SettingsVerificationIndex from "src/pages/dashboard/settings/verification";
import SettingsSecurityIndex from "src/pages/dashboard/settings/security";
import LimitAndFees from "src/pages/dashboard/settings/limit-and-fees";
import SettingsBeneficiaries from "src/pages/dashboard/settings/beneficiaries";
import SettingBankStatement from "src/pages/dashboard/settings/bank-statement";
import SettingsDeviceManagement from "src/pages/dashboard/settings/device-management";
import MobileSettingsIndex from "src/pages/dashboard/settings/MobileSettingsIndex";

export const dashboard_settings_routes_group = [
  {
    path: "/dashboard-settings",
    element: SettingProfileIndex,
  },
  {
    path: "/dashboard-settings-index",
    element: MobileSettingsIndex,
  },
  {
    path: "/dashboard-settings-profile",
    element: SettingProfileIndex,
  },
  {
    path: "/dashboard-settings-preferences",
    element: SettingPreferenceIndex,
  },
  {
    path: "/dashboard-settings-verification",
    element: SettingsVerificationIndex,
  },
  {
    path: "/dashboard-settings-security",
    element: SettingsSecurityIndex,
  },
  {
    path: "/dashboard-settings-limits-and-fees",
    element: LimitAndFees,
  },
  {
    path: "/dashboard-settings-beneficiaries",
    element: SettingsBeneficiaries,
  },
  {
    path: "/dashboard-settings-bank-statement",
    element: SettingBankStatement,
  },
  {
    path: "/dashboard-settings-device-management",
    element: SettingsDeviceManagement,
  },
];
