import { RavenButton, RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../styles/dashboard/fragments/transactionSuccessModal.css";

interface Props {
    visible?: boolean;
    text?: string;
    title?: string;
    onBtnClick?: () => void;
    onDelete?: () => void;
    authType?: boolean;
    value?: any;
    onChange?: (param: any) => void;
    onComplete?: (param: any) => void;
    onPay?: (param: any) => void;
    pin?: string;
    setPin?: (param: any) => void;
    btnText?: string;
  }

const SuccessModal: React.FC<Props> = ({ visible, onBtnClick, title, text, btnText, authType,  }) => {
  return (
    <RavenModal
      className={`success-modal-reuse-wrap`}
      visble={visible}
      effect={`fadeInDown`}
      onBtnClick={() => {}}
    >
      <div className="container-wrap">
        <div className="success-img-box">
          <div className="wrap"></div>
        </div>
        <p style={{color: authType && "#020202" as any}} className="title">{title || "Payment Successful."}</p>
        <p className="text grey-white-color">
          {text ||
            "You have successfully sent ₦150,000 to ."}
        </p>
      </div>
      <div className="button-wrapper">
        <RavenButton
          color={`purple-dark`}
          label={btnText || "Delete"}
        //   size={`medium`}
          onClick={onBtnClick}
        />
      </div>
    </RavenModal>
  );
};

export default SuccessModal;
