// import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext } from "react";
// import "../../styles/reusables/ReusableScheduleBox.css";
// import moment from "moment";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import ActionContext from "src/context/ActionContext";
import { trimLongString } from "src/helper";

interface MyComponentProps {
  onChange?: (param: any) => void;
  value?: string;
  id?: string;
  onReset?: () => void;
  textSchedule?: string;
}

const CategoryPopFragment: React.FC<MyComponentProps> = ({
  onChange,
  value,
  id,
  onReset,
  textSchedule,
}) => {
  const actionCtx = useContext(ActionContext);

  return (
    <div
      onClick={() => {
        actionCtx.setShowCategories(true);
      }}
      className="new-reusable-schedule-box animate-move-up-class"
      style={{marginTop: "1rem"}}
    >
      {value && <p className="text-schedule">{`Schedule time: `}</p>}

      <div className="date-arrow-wrap">
        {!value && (
          <label
            style={{ cursor: "pointer" }}
            htmlFor={`select-raven-schedule-${id}`}
          >
            {" "}
            <span className="grey-white-color">
              {textSchedule || "Categorise transaction"}
            </span>
          </label>
        )}

        {!value && (
          <label htmlFor={`select-raven-schedule-${id}`}>
            <FaAngleDown className="icon" />
          </label>
        )}
        {/* bage box start */}
        {Object?.keys(actionCtx.categoryObj)?.length > 0 && (
          <div
            style={{
              marginLeft: "auto",
              backgroundColor: actionCtx.categoryObj.bgColor,
            }}
            className="badge-cat-box"
          >
            <div className="img-cat-box">
              <figure className="img-box">{actionCtx.categoryObj.icon}</figure>
            </div>
            <p style={{ color: actionCtx.categoryObj.color }} className="name">
              {trimLongString(actionCtx.categoryObj.name, 13)}
            </p>
          </div>
        )}
        {/* bage box end */}
        {!value && (
          <label
            style={{
              cursor: "pointer",
              transform: "translateY(15%) translateX(-10%)",
              marginLeft:
                Object?.keys(actionCtx.categoryObj)?.length > 0
                  ? "1rem"
                  : "auto",
            }}
            htmlFor={`select-raven-schedule-${id}`}
          >
            {" "}
            <FaAngleRight />
          </label>
        )}
      </div>
      {value && (
        <p
          onClick={() => {
            onReset && onReset();
          }}
          className="reset"
        >
          Reset
        </p>
      )}
    </div>
  );
};

export default CategoryPopFragment;
