import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
// import StepperMainWrapper from "src/components/reusables/StepperMainWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import useWindowSize from "src/utils/UseWindowSize";
import "../../../../styles/dashboard/savings/TargetSavingIndex.css";
// import { RavenButton } from "@ravenpay/raven-bank-ui";
// import ProgressBar from "src/components/fragments/ProgressBar";
import WithdrawComponents from "./WithdrawComponents";
import SingleDetailComponent from "./SingleDetailComponent";
import TopUpComponent from "./TopUpSavingdIndex";

const SingleHifaSavingsIndex = () => {
  const size = useWindowSize();
  const [singleSavingsoShow, setSingleSavingsToShow] = useState("");

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* ?container wrapper  start */}
        <ContainerWrapper
          //   activeStep={activeStep}
          //   stepList={noTargetSavings && (stepList as any)}
          dontShowHeader
          width={(size.width as any) < 900 && `100%`}
          //   back
          //   onBack={() => {
          //     navigate("/dashboard-savings-target");
          //   }}
        >
          <div className="target-savings-index-wrap-single">
            <>
              {/* normal content to show start */}
              {!singleSavingsoShow && (
                <SingleDetailComponent
                  setSingleSavingsToShow={setSingleSavingsToShow}
                />
              )}
              {/* normal content to show end */}
              {/* withdraw content start ----------------- */}
              {singleSavingsoShow === "withdraw" && (
                <>
                  <WithdrawComponents
                    setContent={(param) => {
                      setSingleSavingsToShow(param);
                    }}
                  />
                </>
              )}
              {/* withdraw content end ------------------- */}
              {/* withdraw content start ----------------- */}
              {singleSavingsoShow === "topup" && (
                <>
                  <TopUpComponent
                    setContent={(param) => {
                      setSingleSavingsToShow(param);
                    }}
                  />
                </>
              )}
              {/* withdraw content end ------------------- */}
            </>
          </div>
        </ContainerWrapper>
        {/* container wrapper end */}
      </DashboardLayoutIndex>
    </>
  );
};

export default SingleHifaSavingsIndex;
