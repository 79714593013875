import React, { Fragment, useEffect, useState } from "react";
import "../../styles/dashboard/layout/VerificationLayout.css";
import { FaArrowRight, FaBars, FaCheck } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
// import pendingIcon from "../../assets/info-circle-icon.svg";
// import ChatIcon from "../../assets/chat-icon-img.svg";
// import infoIcon from "../../assets/info-icon-black.svg";
import { useDarkMode } from "../../utils/themes/useDarkMode";
import { darkTheme, GlobalStyles, lightTheme } from "../../utils/themes/theme";
import { ThemeProvider } from "styled-components";
import { RavenToolTip } from "@ravenpay/raven-bank-ui";
// import ravenLogo from "../../assets/raven-logo-black.svg";
// import { useSelector, useDispatch } from "react-redux";
// import useWindowSize from "src/utils/UseWindowSize";
import NoContentBox from "src/components/reusables/NoContentBox";
// import Intercom from "intercom-client";

interface MyComponentProps {
  children?: React.ReactNode;
}

const VerificationLayout: React.FC<MyComponentProps> = ({ children }) => {
  const [theme] = useDarkMode(localStorage.getItem("theme"));
  // const navigate = useNavigate()
  //   const dispatch = useDispatch();

  const themeMode = theme === "light" ? lightTheme : darkTheme;
  const location = useLocation();
  const navigate = useNavigate();

  const infoIcon = (
    <svg
      className="img"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1529_95762)">
        <path
          d="M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
          stroke="#676767"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1529_95762">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  // const handleClick = () => {
  //   const intercom = new Intercom.Client({ token: "YOUR_APP_ID" });
  //   intercom.trackEvent("Clicked Button", { buttonId: "my-button" });
  // }; 0 = init state 1 = processing 2 = failed 3 = verified

//   const size = useWindowSize();

//   const [canRefresh, setCanRefresh] = useState(true);
//   const onPresenceChange = () => {
//     // localStorage.setItem("token", "byebyebye");
//     // actionCtx?.setIsLogout(true);
//     // dispatch(logoutUser())
//     setCanRefresh(false);
//     localStorage.setItem("can-refresh", "no");
//   };

  // const channelID = "";
  useEffect(() => {
    if (
      location.pathname.includes("/dashboard-") ||
      !location.pathname.includes("/dashboard-verification")
    ) {
      const script = document.createElement("script");
      // script.src = `//fw-cdn.com/10934263/3694369.js?channelId=${channelID}`;
      script.src = `//fw-cdn.com/10934263/3694369.js`;
      script.setAttribute("chat", "true");

      // Add an event listener to check when the script has loaded

      // Append the script to the document's body
      document.body.appendChild(script);

      // Cleanup by removing the script from the DOM when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [business, setBusiness] = useState({
    bvn: 1,
    address: 1,
    nin: 1,
    business_info: 1,
    cac: 1,
  });

  return (
    <Fragment>
      {" "}
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        <div className="verification-layout-wrap">
          <div
            onClick={() => {
              // console.log(user);
              // console.log(business);
              // console.log(account_details);
            }}
            className={`left-wrap`}
          >
            <div className="link-wrap">
              {/* link box styart */}
              <div
                onClick={() => {
                  navigate("/dashboard-verification-bvn");
                  // actionCtx.setShowVerifySlide("close");
                }}
                className={`link-box ${
                  location.pathname.includes("/dashboard-verification-bvn") &&
                  "link-box-active"
                } ${business?.bvn === 3 && "verified"}`}
              >
                <div className="check-box">
                  <FaCheck className="icon" />
                </div>
                <p className="text grey-white-color">BVN Verification</p>
              </div>
              {/* link box end */}
              {/* link box styart */}
              {/* <div
                  onClick={() => {
                    business?.bvn === 3 &&
                      navigate("/dashboard-verification-id");
                    actionCtx.setShowVerifySlide("close");
                  }}
                  className={`link-box ${
                    location.pathname.includes("/dashboard-verification-id") &&
                    "link-box-active"
                  } ${business?.document_status === 3 && "verified"}`}
                  // style={{ opacity: business?.bvn === 3 ? 1 : 0.5 }}
                >
                  {business?.document_status === 1 ? (
                    <div className="pending-box">
                      <figure className="img-box">
                        <img src={pendingIcon} alt="" className="img" />
                      </figure>
                    </div>
                  ) : (
                    <div className="check-box">
                      <FaCheck className="icon" />
                    </div>
                  )}
                  <div
                    className={`text grey-white-color ${
                      business?.bvn !== 3 &&
                      business?.document_status !== 3 &&
                      "tooltip-hover-wrap"
                    }`}
                  >
                    ID Verification{" "}
                    <RavenToolTip
                      textColor="light-black"
                      color="purple-dark"
                      text="Complete BVN verification to verify business ID"
                      position={`top-right`}
                    />
                  </div>
                </div> */}
              {/* link box end */}
              {/* link box styart */}
              <div
                onClick={() => {
                  //   business?.bvn === 3 &&
                  navigate("/dashboard-verification-nin");
                  // actionCtx.setShowVerifySlide("close");
                }}
                className={`link-box ${
                  location.pathname.includes("/dashboard-verification-nin") &&
                  "link-box-active"
                } ${business?.nin === 3 && "verified"}`}
                // style={{ opacity: business?.bvn === 3 ? 1 : 0.5 }}
              >
                <div className="check-box">
                  <FaCheck className="icon" />
                </div>
                <div
                  className={`text grey-white-color ${
                    business?.bvn !== 3 &&
                    business?.nin !== 3 &&
                    "tooltip-hover-wrap"
                  }`}
                >
                  NIN Verification{" "}
                  {!location.pathname.includes(
                    "/dashboard-verification-nin"
                  ) && (
                    <RavenToolTip
                      //   textColor="light-black"
                      color="purple-dark"
                      text="Complete BVN verification to verify business NIN"
                      position={`top-right`}
                    />
                  )}
                </div>
              </div>
              {/* link box end */}

              {/* link box styart */}
              <div
                onClick={() => {
                  //   business?.nin === 3 &&
                  navigate("/dashboard-verification-nok");
                  // actionCtx.setShowVerifySlide("close");
                }}
                className={`link-box ${
                  location.pathname.includes("/dashboard-verification-nok") &&
                  "link-box-active"
                } ${business?.address === 3 && "verified"}`}
                // style={{ opacity: business?.document_status === 3 ? 1 : 0.5 }}
              >
                <div className="check-box">
                  <FaCheck className="icon" />
                </div>
                <div
                  className={`text grey-white-color ${
                    business?.nin !== 3 &&
                    business?.address !== 3 &&
                    "tooltip-hover-wrap"
                  }`}
                >
                  {" "}
                  Next of kin details{" "}
                  {!location.pathname.includes(
                    "/dashboard-verification-nok"
                  ) && (
                    <RavenToolTip
                      //   textColor="light-black"
                      color="purple-dark"
                      text="Complete ID verification to verify business address"
                      position={`top-right`}
                    />
                  )}
                </div>
              </div>
              {/* link box end */}
              {/* link box styart */}
              <div
                onClick={() => {
                  navigate("/dashboard-verification-address");
                  // actionCtx.setShowVerifySlide("close");
                }}
                className={`link-box ${
                  location.pathname.includes(
                    "/dashboard-verification-address"
                  ) && "link-box-active"
                } ${business?.business_info === 3 && "verified"}`}
              >
                <div className="check-box">
                  <FaCheck className="icon" />
                </div>
                <p className="text grey-white-color">Address verification</p>
              </div>
              {/* link box end */}
              {/* link box styart */}
              <div
                onClick={() => {
                  //   business?.address === 3 &&
                  navigate("/dashboard-verification-wealth");
                  // actionCtx.setShowVerifySlide("close");
                }}
                className={`link-box ${
                  location.pathname.includes(
                    "/dashboard-verification-wealth"
                  ) && "link-box-active"
                } ${business?.cac === 3 && "verified"}`}
                // style={{ opacity: business?.address === 3 ? 1 : 0.5 }}
              >
                <div className="check-box">
                  <FaCheck className="icon" />
                </div>
                <div
                  className={`text grey-white-color ${
                    business?.address !== 3 &&
                    business?.cac !== 3 &&
                    "tooltip-hover-wrap"
                  }`}
                >
                  Wealth declaration
                  {!location.pathname.includes(
                    "/dashboard-verification-wealth"
                  ) && (
                    <RavenToolTip
                      //   textColor="light-black"
                      color="purple-dark"
                      text="Complete Address verification to verify CAC details."
                      position={`top-right`}
                    />
                  )}
                </div>
              </div>
              {/* link box end */}
            </div>
            {/* info text box start */}
            <div className="info-text-box">
              <figure className="img-box tooltip-hover-wrap">
                {/* totl tip here */}
                <RavenToolTip
                  color={`purple-dark`}
                  title={`Save Meter Number`}
                  downText={`Got it!`}
                  position={`top-right`}
                  big
                  text="If you have a saved meter number already, once you toggle the save meter it would be replaced with the new meter number added."
                />
                {/* totl tip end here */}
                {/* <img src={infoIcon} alt="" className="img dull-" /> */}
                {infoIcon}
              </figure>
              <p className="text grey-white-color-white">
                For regulatory reason every business needs to be verified, to
                safe guard every user from risk of fraud.
              </p>
            </div>
            {/* info text box end */}
          </div>
          {/* end of left wrap */}
          <div className={`right-wrap white-black-bg `}>
            <div className="mobile-box-start">
              {/* menu toggle start */}
              <div
                onClick={() => {
                  // actionCtx.setShowVerifySlide("show");
                }}
                className="menu-box"
              >
                <FaBars className="icon" />
              </div>
              {/* menu toggle end */}
              <div
                onClick={() => {
                  // actionCtx.setShowVerifySlide("close");
                }}
                className="logo-box"
              >
                <figure className="img-box">
                  {/* <img
                    src={ravenLogo}
                    alt=""
                    className="img dull-bright-filter"
                  /> */}
                </figure>
              </div>
              <div
                onClick={() => {
                  if (navigate.length > 1) {
                    navigate(-1);
                  } else {
                    navigate("/dashboard-overview");
                  }
                }}
                className="skip-box skip-box-mobile"
              >
                <p
                  // style={{ color: theme === "light" ? "#676767" : "#ffffff" }}
                  className="text grey-white-color-white"
                >
                  {business?.bvn === 3 &&
                  business?.cac === 3 &&
                  business?.business_info === 3 &&
                  business?.address === 3
                    ? `Go to Dashboard`
                    : `Skip for later`}
                </p>
                <FaArrowRight className="icon" />
              </div>
            </div>
            {/* .mobile box end */}
            {/* skip text box start */}
            {/* {business?.bvn === 3 && ( */}
            <div
              onClick={() => {
                // navigate("/dashboard-overview");
                const urlLink = window?.location.href.split("?")[1];
                if (urlLink) {
                  navigate(`/${urlLink}`);
                } else {
                  // console.log(location.pathname);
                  navigate("/dashboard-overview");
                }
              }}
              className="skip-box "
            >
              <p
                // style={{ color: theme === "light" ? "#676767" : "#ffffff" }}
                className="text grey-white-color-white"
              >
                {business?.bvn === 3 &&
                business?.cac === 3 &&
                business?.business_info === 3 &&
                business?.address === 3
                  ? `Go to Dashboard`
                  : `Skip for later`}
              </p>
              <FaArrowRight className="icon" />
            </div>
            {/* )} */}
            {/* skip text box send */}
            <div
              onClick={() => {
                //   actionCtx.setShowVerifySlide("close");
              }}
              className="content-wrap animate-move-up-class"
            >
              {Object.keys(business)?.length < 1 ? (
                <>
                  {" "}
                  <NoContentBox
                    loading={true}
                    title={`No team Member`}
                    text="Add team member by clicking on the invite team member button above"
                  />
                </>
              ) : (
                <>{children}</>
              )}
            </div>
            {/* content wrap end */}
            {/* <div  className="chat-box">
              {" "}
              <figure className="img-box ">
                <img src={ChatIcon} alt="" className="img " />
              </figure>
            </div> */}
          </div>
          {/* end of right wrap */}
        </div>
      </ThemeProvider>
    </Fragment>
  );
};

export default VerificationLayout;
