import AddMoneyIndex from "src/pages/dashboard/add-money/AddMoneyIndex";
import AddMoneyCardIndex from "src/pages/dashboard/add-money/card/CardAddMoney";
import AddMoneyQrScanIndex from "src/pages/dashboard/add-money/scan/ScanQrAddMoneyIndex";
// import AddMoneyScanIndex from "src/pages/dashboard/add-money/scan/ScanAddMoneyIndex";
import AddMoneyUssdIndex from "src/pages/dashboard/add-money/ussd/UssdAddMoney";

export const dashboard_add_money_routes_group = [
  {
    path: "/dashboard-add-money",
    element: AddMoneyIndex,
  },
  {
    path: "/dashboard-add-money-ussd",
    element: AddMoneyUssdIndex,
  },
  {
    path: "/dashboard-add-money-card",
    element: AddMoneyCardIndex,
  },
  {
    path: "/dashboard-add-money-scan",
    element: AddMoneyQrScanIndex,
  },
];
