import React from "react";
import "../../styles/dashboard/fragments/ExportFragment.css";
import useWindowSize from "src/utils/UseWindowSize";

interface MyComponentProps {
  text?: string;
  onClick?: () => void;
}

const downloadIcon = (
  <svg
    className="img"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dull-bright-filter"
      d="M21.5 15V16.2C21.5 17.8802 21.5 18.7202 21.173 19.362C20.8854 19.9265 20.4265 20.3854 19.862 20.673C19.2202 21 18.3802 21 16.7 21H8.3C6.61984 21 5.77976 21 5.13803 20.673C4.57354 20.3854 4.1146 19.9265 3.82698 19.362C3.5 18.7202 3.5 17.8802 3.5 16.2V15M17.5 10L12.5 15M12.5 15L7.5 10M12.5 15V3"
      stroke="#1B1B1B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ExportFragment: React.FC<MyComponentProps> = ({ text, onClick }) => {
  const size = useWindowSize();
  return (
    <div onClick={onClick} className="export-fragment-index-wrap grey-bg">
      {(size.width as any) > 900 && <p className="text">{text || "export"}</p>}
      <figure className="img-box">{downloadIcon}</figure>
    </div>
  );
};

export default ExportFragment;
