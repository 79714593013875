import React, { useRef, useState } from "react";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import useWindowSize from "src/utils/UseWindowSize";
import "../../../../styles/dashboard/add-money/AddMoneyIndex.css";
// import SelectSpecialComponent from "src/components/reusables/SelectSpecialComponent";
import StepperMainWrapper from "src/components/reusables/StepperMainWrapper";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";

const AddMoneyQrScanIndex = () => {
  const stepList = ["Select option", "Receive money"];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(2);
  const size = useWindowSize();
  const navigate = useNavigate();

  const handleStepClick = (param: number) => {
    if (param === 1) {
      navigate("/dashboard-add-money");
    }
  };

  // type RegisterProp = {
  //   username: string;
  //   amount: string;
  //   narration: string;
  //   bulk_list: any[];
  //   beneficiary: boolean;
  //   schedule: string;
  //   transfer_pin: string;
  //   template_title: string;
  //   save_template: boolean;
  //   account_num: string;
  //   bank: any;
  //   name: string;
  //   schedule_time: string;
  // };

  // const [details, setDetails] = useState<RegisterProp>({
  //   username: "",
  //   amount: "",
  //   narration: "Sent from raven",
  //   bulk_list: [],
  //   beneficiary: false,
  //   account_num: "",
  //   bank: "",
  //   name: "",
  //   schedule_time: "",
  //   schedule: "",
  //   transfer_pin: "",
  //   template_title: "",
  //   save_template: false,
  // });

  // const handleChange = (e: any) => {
  //   const { name, value } = e.target;
  //   const obj = { ...details, [name]: value };
  //   setDetails(obj);
  // };

  const scanImgIcon = (
    <svg
      className="img"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99695 3.01367H6.82926C5.42053 3.01367 4.71616 3.01367 4.1781 3.28783C3.7048 3.52899 3.32 3.91379 3.07885 4.38708C2.80469 4.92515 2.80469 5.62951 2.80469 7.03825V7.20594M6.99695 18.1058H6.82926C5.42053 18.1058 4.71616 18.1058 4.1781 17.8317C3.7048 17.5905 3.32 17.2057 3.07885 16.7324C2.80469 16.1943 2.80469 15.49 2.80469 14.0812V13.9136M17.8968 7.20594V7.03825C17.8968 5.62951 17.8968 4.92515 17.6227 4.38708C17.3815 3.91379 16.9967 3.52899 16.5234 3.28783C15.9854 3.01367 15.281 3.01367 13.8723 3.01367H13.7046M17.8968 13.9136V14.0812C17.8968 15.49 17.8968 16.1943 17.6227 16.7324C17.3815 17.2057 16.9967 17.5905 16.5234 17.8317C15.9854 18.1058 15.281 18.1058 13.8723 18.1058H13.7046M2.80469 10.5597H2.81307M6.57772 10.5597H6.58611M14.1238 10.5597H14.1322M10.3508 10.5597H10.3591M17.8968 10.5597H17.9052"
        stroke="white"
        strokeWidth="1.82935"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  // const screenShotRef = useRef();
  const screenShotRef: React.RefObject<HTMLInputElement[]> = useRef([]);
  const handleScreenshot = () => {
    html2canvas(screenShotRef.current as any).then((canvas: any) => {
      const link = document?.createElement("a");
      link.download = "raven_qr_scan.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* content wrapper start */}
        <ContainerWrapper
          activeStep={activeStep}
          stepList={stepList}
          dontShowHeader={(size.width as any) < 900}
          width={(size.width as any) < 900 && `100%`}
        >
          <div className="add-money-index-wrap-ussd">
            {" "}
            {activeStep === 2 && (
              <>
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Take QR code screenshot"
                  title="Scan QR code"
                  text="Allow a raven user to scan the code below to pay you."
                  onBack={() => {
                    activeStep === 2 && handleStepClick(1);
                  }}
                  //   disabled={!details?.amount}
                  onBtnClick={() => {
                    // setCopyContent("gfdfgh");
                    handleScreenshot();
                  }}
                >
                  <form
                    autoComplete="off"
                    style={{
                      width: "100%",
                      position: "relative",
                      zIndex: 1000,
                    }}
                    action=""
                    className="wrap-input-box"
                    ref={screenShotRef as any}
                  >
                    <div className="scan-box-wrapper">
                      {/* scan image box start */}
                      <div className="scan-img-box white-blackg">
                        <div className="img-box"></div>
                      </div>
                      {/* scan image box end */}
                      {/* down-text- imag box start */}
                      <div className="down-img-text-box">
                        <div className="scan-img-icon">
                          <figure className="img-box">{scanImgIcon}</figure>
                        </div>
                        <p className="text">SCAN ME</p>
                      </div>
                      {/* down-text- imag box end */}
                    </div>
                  </form>
                </StepperMainWrapper>
              </>
            )}
          </div>
        </ContainerWrapper>
        {/* content wrapper end */}
      </DashboardLayoutIndex>
    </>
  );
};

export default AddMoneyQrScanIndex;
