export const PreferenceIcons = {
  bvn: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.50001 9.58363L9.16668 11.2503L12.9167 7.5003M16.6667 10.0003C16.6667 14.0907 12.205 17.0656 10.5817 18.0127C10.3972 18.1203 10.3049 18.1741 10.1747 18.2021C10.0737 18.2237 9.92631 18.2237 9.82528 18.2021C9.69509 18.1741 9.60284 18.1203 9.41835 18.0127C7.79498 17.0656 3.33334 14.0907 3.33334 10.0003V6.01497C3.33334 5.34871 3.33334 5.01558 3.44231 4.72922C3.53857 4.47625 3.695 4.25053 3.89806 4.07157C4.12792 3.869 4.43984 3.75203 5.06368 3.51809L9.53185 1.84253C9.70509 1.77756 9.79171 1.74508 9.88083 1.7322C9.95987 1.72078 10.0401 1.72078 10.1192 1.7322C10.2083 1.74508 10.2949 1.77756 10.4682 1.84253L14.9363 3.51809C15.5602 3.75203 15.8721 3.869 16.102 4.07157C16.305 4.25053 16.4614 4.47625 16.5577 4.72922C16.6667 5.01558 16.6667 5.34871 16.6667 6.01497V10.0003Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  nin: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_1178_25164)">
        <path
          d="M3.33334 18.181C3.83549 18.3332 4.51373 18.3332 5.66666 18.3332H14.3333C15.4862 18.3332 16.1645 18.3332 16.6666 18.181M3.33334 18.181C3.22567 18.1484 3.1261 18.1088 3.03168 18.0607C2.56128 17.821 2.17882 17.4386 1.93914 16.9681C1.66666 16.4334 1.66666 15.7333 1.66666 14.3332V5.6665C1.66666 4.26637 1.66666 3.56631 1.93914 3.03153C2.17882 2.56112 2.56128 2.17867 3.03168 1.93899C3.56646 1.6665 4.26653 1.6665 5.66666 1.6665H14.3333C15.7335 1.6665 16.4335 1.6665 16.9683 1.93899C17.4387 2.17867 17.8212 2.56112 18.0608 3.03153C18.3333 3.56631 18.3333 4.26637 18.3333 5.6665V14.3332C18.3333 15.7333 18.3333 16.4334 18.0608 16.9681C17.8212 17.4386 17.4387 17.821 16.9683 18.0607C16.8739 18.1088 16.7743 18.1484 16.6666 18.181M3.33334 18.181C3.33362 17.5066 3.33766 17.1497 3.39737 16.8495C3.66039 15.5272 4.69405 14.4936 6.01636 14.2306C6.33835 14.1665 6.72556 14.1665 7.49999 14.1665H12.5C13.2744 14.1665 13.6616 14.1665 13.9836 14.2306C15.3059 14.4936 16.3396 15.5272 16.6026 16.8495C16.6623 17.1497 16.6664 17.5066 16.6666 18.181M13.3333 7.9165C13.3333 9.75745 11.8409 11.2498 9.99999 11.2498C8.15904 11.2498 6.66666 9.75745 6.66666 7.9165C6.66666 6.07555 8.15904 4.58317 9.99999 4.58317C11.8409 4.58317 13.3333 6.07555 13.3333 7.9165Z"
          stroke="var(--primary-purple)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1178_25164">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  nextOfKin: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6667 8.33317V5.6665C16.6667 4.26637 16.6667 3.56631 16.3942 3.03153C16.1545 2.56112 15.7721 2.17867 15.3017 1.93899C14.7669 1.6665 14.0668 1.6665 12.6667 1.6665H7.33334C5.93321 1.6665 5.23315 1.6665 4.69837 1.93899C4.22796 2.17867 3.84551 2.56112 3.60583 3.03153C3.33334 3.56631 3.33334 4.26637 3.33334 5.6665V14.3332C3.33334 15.7333 3.33334 16.4334 3.60583 16.9681C3.84551 17.4386 4.22796 17.821 4.69837 18.0607C5.23315 18.3332 5.93321 18.3332 7.33334 18.3332H10.4167M15 17.4998C15 17.4998 17.5 16.3082 17.5 14.5209V12.4356L15.677 11.7842C15.239 11.6273 14.76 11.6273 14.322 11.7842L12.5 12.4356V14.5209C12.5 16.3082 15 17.4998 15 17.4998Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  address: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.76517 2.82121C7.00617 2.08754 8.45391 1.6665 9.99999 1.6665C14.6024 1.6665 18.3333 5.39746 18.3333 9.99984C18.3333 10.0274 18.3332 10.055 18.3329 10.0826M2.81761 5.77138C2.08626 7.01096 1.66666 8.4564 1.66666 9.99984C1.66666 13.8828 4.32243 17.1455 7.91666 18.0706M17.2564 14.1003C16.156 16.0433 14.2978 17.5007 12.0833 18.0706M11.7411 5.31132C11.1989 5.1099 10.6123 4.99984 9.99999 4.99984C7.23857 4.99984 4.99999 7.23841 4.99999 9.99984C4.99999 10.6155 5.11125 11.2051 5.31476 11.7497M14.6876 8.25623C14.8896 8.79912 15 9.38658 15 9.99984C15 12.7613 12.7614 14.9998 9.99999 14.9998C9.39177 14.9998 8.80892 14.8912 8.26977 14.6924M9.99999 8.33317V11.6665"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  wealthDeclaration: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.67748 12.0831C7.93765 12.5786 8.45715 12.9165 9.05556 12.9165H10.8333C11.7538 12.9165 12.5 12.1703 12.5 11.2498C12.5 10.3293 11.7538 9.58314 10.8333 9.58314H9.16668C8.2462 9.58314 7.50001 8.83695 7.50001 7.91648C7.50001 6.996 8.2462 6.24981 9.16668 6.24981H10.9445C11.5429 6.24981 12.0624 6.58772 12.3225 7.08314M10 4.99981V6.24981M10 12.9165V14.1665M16.6667 9.99981C16.6667 14.0902 12.205 17.0651 10.5817 18.0122C10.3972 18.1198 10.3049 18.1737 10.1747 18.2016C10.0737 18.2232 9.92631 18.2232 9.82528 18.2016C9.69509 18.1737 9.60284 18.1198 9.41835 18.0122C7.79498 17.0651 3.33334 14.0902 3.33334 9.99981V6.01448C3.33334 5.34822 3.33334 5.01509 3.44231 4.72873C3.53857 4.47576 3.695 4.25004 3.89806 4.07109C4.12792 3.86851 4.43984 3.75154 5.06368 3.5176L9.53185 1.84204C9.70509 1.77707 9.79171 1.74459 9.88083 1.73171C9.95987 1.72029 10.0401 1.72029 10.1192 1.73171C10.2083 1.74459 10.2949 1.77707 10.4682 1.84204L14.9363 3.5176C15.5602 3.75154 15.8721 3.86851 16.102 4.07109C16.305 4.25004 16.4614 4.47576 16.5577 4.72873C16.6667 5.01509 16.6667 5.34822 16.6667 6.01448V9.99981Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
