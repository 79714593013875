import { cn } from "src/utils/helpers";
import "../../../styles/dashboard/settings/sidebar/index.css";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";

const allLinks = [
  { name: "Profile", link: "profile" },
  { name: "Preferences", link: "preferences" },
  { name: "Verification", link: "verification" },
  { name: "Security", link: "security" },
  { name: "Limits & Fees", link: "limits-and-fees" },
  { name: "Beneficiaries", link: "beneficiaries" },
  { name: "Bank statement", link: "bank-statement" },
  { name: "Device management", link: "device-management" },
];

const verifiedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle
      cx="8"
      cy="8"
      r="5.5"
      fill="var(--primary-purple)"
      stroke="var(--primary-purple)"
      strokeWidth="5"
    />
    <path d="M10.6693 6L7.0026 9.66667L5.33594 8" fill="var(--primary-purple)" />
    <path
      d="M10.6693 6L7.0026 9.66667L5.33594 8"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface MyComponentProps {
  setShowSideBar?: (param: boolean) => void;
  showSideBar?: boolean;
}

const SettingSidebar: React.FC<MyComponentProps> = ({ setShowSideBar, showSideBar }) => {
  const { pathname } = useLocation();

  return (
    <aside
      className={`settings-sidebar grey-bg border-theme ${
        showSideBar && "settings-sidebar-translateX"
      }`}
    >
      <ul className="settings-sidebar__links">
        {allLinks.map(({ link, name }) => {
          const isProfile = link === "profile" && pathname === "/dashboard-settings";

          return (
            <NavLink
              to={`/dashboard-settings-${link}`}
              key={name}
              className={({ isActive }) =>
                cn(
                  (isActive || isProfile) &&
                    "settings-sidebar__link--active white-black-bg  border-theme",
                  "settings-sidebar__link"
                )
              }
            >
              <span
                className={`grey-white-color ${
                  pathname === `/dashboard-settings-${link}` && "grey-white-color-white"
                }`}
              >
                {name}
              </span>
              <figure className="img-box img-box-small img-box-small">
                {verifiedIcon}
              </figure>
            </NavLink>
          );
        })}
      </ul>
    </aside>
  );
};

export default SettingSidebar;
