import { useState, useEffect, useContext } from "react";
// import ActionContext from "../../context/ActionContext";

export const useDarkMode = (def) => {
  // const actionCtx = useContext(ActionContext)
  // const [color, set]
  // const [chatTheme, setChatTheme] = useState("");
  const [theme, setTheme] = useState(def);

  const setMode = (mode) => {
    setTheme(mode);
    window.localStorage.setItem("theme", mode);
    // Intercom("update", { color: mode === "dark" ? "var(--primary-purple)" : "#020202" });
  };

  const toggleTheme = () => {
    theme === "dark" ? setMode("light") : setMode("dark");
    // theme === "dark" ? setChatTheme("#020202") : setChatTheme("var(--primary-purple)");
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");

    localTheme ? setTheme(localTheme) : setMode("light");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [def]);

  // function changeIntercomTheme(color) {
  //   window.Intercom("update", {
  //     app_id: "v4k0zj58",
  //     color: color,
  //     // Add any other theme properties you want to change here
  //   });
  // }

  // useEffect(() => {
  //   const colorVal = localStorage.getItem("theme");
  //   let chatIcon = document.querySelector(
  //     ".intercom-lightweight-app-launcher-icon"
  //   );
  //   if (colorVal === "light") {
  //     // changeChatColor("#020202");

  //     if (chatIcon) {
  //       chatIcon.style.backgroundColor = "#020202";
  //       chatIcon.style.borderRadius = "50%";
  //     }
  //   } else {
  //     // changeChatColor("var(--primary-purple)");
  //     if (chatIcon) {
  //       chatIcon.style.backgroundColor = "var(--primary-purple)";
  //       chatIcon.style.borderRadius = "50%";
  //     }
  //     setChatTheme("var(--primary-purple)");
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [def]);

  // window.Intercom('onShow', function() {
  //   const trigger = () => {
  //     var divs = document.querySelectorAll('.intercom-app');
  //     [].forEach.call(divs, function(div) {
  //       let main = div.querySelector('div')
  //       let load = main.querySelectorAll('iframe')[0].contentWindow.document.readyState === 'complete'
  //       if (!load){
  //         document.querySelector('.intercom-lightweight-app-launcher-icon').click()
  //       }
  //         let open = document.querySelector('.e4nbtsn1')
  //         main.querySelectorAll('iframe').forEach( item =>{
  //           item.contentWindow.document.body.querySelector('.intercom-1esqzno').style.backgroundColor = chatTheme
  //           // item.contentWindow.document.body.querySelector('.intercom-launcher').style.backgroundColor = chatTheme
  //         }
  //       )
  //     });
  //   }
  //   trigger()
  //  });

  // const [timeoutId, setTimeoutId] = useState(null);

  // useEffect(() => {
  //   const id = setTimeout(() => {
  //     console.log("Hello, world!");
  //   }, 1000);
  //   setTimeoutId(id);
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleClick = () => {
  //   clearTimeout(timeoutId);
  // };

  // const changeChatColor = useCallback((param) => {
  //   let int;
  //   let chat;
  //   clearInterval(int);
  //   int = setInterval(() => {
  //     let chatIcon = document.querySelector(
  //       ".intercom-lightweight-app-launcher-icon"
  //     );
  //     if (chatIcon) {
  //       chatIcon.style.backgroundColor = param;
  //       chatIcon.style.borderRadius = "50%";
  //     }
  //     if (chat) return clearInterval(int);
  //   }, 50);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return [theme, toggleTheme];
};

// We might need to revert to this if the dark/light glitching continues
// if (def === 'dark') {
//   document.body.style.background = '#252525'
// }
// if (def === 'light') {
//   document.body.style.background = '#fafaff';
//   console.log(document.body.style);
// }
