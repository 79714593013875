import React, { useEffect } from "react";
import useWindowSize from "src/utils/UseWindowSize";

const FreshchatComponent: React.FC = () => {
  const size = useWindowSize();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//fw-cdn.com/10934263/3694369.js";
    script.setAttribute("chat", "true");

    script.onload = () => {
      if (window.fcWidget) {
        
      }
    };

    document.body.appendChild(script);

    // Cleanup by removing the script and style tag from the DOM when the component unmounts
    return () => {
      document.body.removeChild(script);
      const styleTag = document.head.querySelector("#freshchat-custom-styles");
      if (styleTag) {
        document.head.removeChild(styleTag);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.width && window.fcWidget]);

  return <div id="freshchat-container" />;
};

export default FreshchatComponent;


// import React, { useEffect } from 'react';

// const FreshchatComponent: React.FC = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = '//fw-cdn.com/10934263/3694369.js';
//     script.setAttribute('chat', 'true');

//     // Add an event listener to the script onload event
//     script.onload = () => {
//       if (window.fcWidget) {
//         // Set Freshchat configuration
//         window.fcWidget.init({
//           token: '//fw-cdn.com/10934263/3694369.js', // Replace with your Freshchat token
//           host: 'YOUR_FRESHCHAT_HOST',   // Replace with your Freshchat host
//         });

//         // Dynamically adjust Freshchat button visibility based on screen size
//         const handleScreenSize = () => {
//           const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
//           const freshchatButton = document.getElementById('fc_frame');

//           if (freshchatButton) {
//             freshchatButton.style.display = isMobile ? 'none' : 'block';
//           }
//         };

//         // Attach the function to the window resize event
//         window.addEventListener('resize', handleScreenSize);

//         // Initial check when the component mounts
//         handleScreenSize();
//       }
//     };

//     // Append the script tag to the document's head
//     document.head.appendChild(script);

//     // Cleanup by removing the script tag from the DOM when the component unmounts
//     return () => {
//       document.head.removeChild(script);
//       window.removeEventListener('resize', handleScreenSize);
//     };
//   }, []);

//   return <div id="freshchat-container" />;
// };

// export default FreshchatComponent;
// function handleScreenSize(this: Window, ev: UIEvent) {
//     throw new Error('Function not implemented.');
// }

