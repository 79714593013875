import React, { useEffect } from "react";
import "../../../styles/dashboard/settings/sidebar/index.css";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import TitleTextBox from "src/components/reusables/TitleTextBox";
import useWindowSize from "src/utils/UseWindowSize";
import { useNavigate } from "react-router-dom";
import BottomNavComponent from "src/layout/dashboard/BottomNavComponent";

const allLinks = [
  { name: "Profile", link: "profile" },
  { name: "Preferences", link: "preferences" },
  { name: "Verification", link: "verification" },
  { name: "Security", link: "security" },
  { name: "Limits & Fees", link: "limits-and-fees" },
  { name: "Beneficiaries", link: "beneficiaries" },
  { name: "Bank statement", link: "bank-statement" },
  { name: "Device management", link: "device-management" },
];

const MobileSettingsIndex = () => {
  const size = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    if ((size.width as any) > 900) {
      navigate("/dashboard-settings");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.width]);

  const rightIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 15L12.5 10L7.5 5"
        stroke="#8B8B8B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <DashboardLayoutIndex>
        <TitleTextBox title="Settings" text="Configure your profile to fit your taste." />
        {/* mobile settings index wrap start */}
        <div className="mobile-settings-index-wrap border-theme">
          {allLinks?.map((chi, idx) => {
            const { link, name } = chi;
            return (
              <div
                onClick={() => {
                  navigate(`/dashboard-settings-${link}`);
                }}
                key={idx}
                className="setting-links  border-theme-bottom"
              >
                <p className="name">{name}</p>
                <figure className="img-box">{rightIcon}</figure>
              </div>
            );
          })}
        </div>
        {/* mobile settings index wrap end */}
        <BottomNavComponent />
      </DashboardLayoutIndex>
    </>
  );
};

export default MobileSettingsIndex;
