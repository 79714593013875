import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useContext } from "react";
import "../../styles/reuseables/TotalReferralModal.css";
import ActionContext from "src/context/ActionContext";

interface MyComponentProps {
  visible?: boolean;
  onClose?: () => void;
}

const adressIcon = (
  <svg
    className="img"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1330_92828)">
      <path
        d="M14.6666 8.00004H11.9999M3.99992 8.00004H1.33325M7.99992 4.00004V1.33337M7.99992 14.6667V12M13.3333 8.00004C13.3333 10.9456 10.9454 13.3334 7.99992 13.3334C5.0544 13.3334 2.66659 10.9456 2.66659 8.00004C2.66659 5.05452 5.0544 2.66671 7.99992 2.66671C10.9454 2.66671 13.3333 5.05452 13.3333 8.00004ZM9.99992 8.00004C9.99992 9.10461 9.10449 10 7.99992 10C6.89535 10 5.99992 9.10461 5.99992 8.00004C5.99992 6.89547 6.89535 6.00004 7.99992 6.00004C9.10449 6.00004 9.99992 6.89547 9.99992 8.00004Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1330_92828">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const callIcon = (
  <svg
    className="img"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1330_92838)">
      <path
        d="M9.36636 4.00004C10.0175 4.12708 10.616 4.44555 11.0851 4.91466C11.5542 5.38378 11.8726 5.98222 11.9997 6.63337M9.36636 1.33337C10.7192 1.48366 11.9808 2.08949 12.9439 3.05138C13.907 4.01327 14.5144 5.27405 14.6664 6.62671M6.81768 9.24209C6.01663 8.44103 5.3841 7.53527 4.9201 6.56886C4.88019 6.48573 4.86024 6.44417 4.84491 6.39158C4.79042 6.20467 4.82956 5.97517 4.9429 5.81688C4.97479 5.77234 5.0129 5.73424 5.0891 5.65803C5.32217 5.42496 5.43871 5.30842 5.5149 5.19124C5.80223 4.74931 5.80223 4.17959 5.5149 3.73766C5.43871 3.62048 5.32218 3.50394 5.0891 3.27087L4.95919 3.14096C4.6049 2.78666 4.42775 2.60951 4.23749 2.51329C3.85912 2.32191 3.41228 2.32191 3.0339 2.51329C2.84365 2.60952 2.6665 2.78666 2.31221 3.14096L2.20712 3.24605C1.85403 3.59913 1.67749 3.77567 1.54266 4.01569C1.39305 4.28203 1.28547 4.69569 1.28638 5.00117C1.2872 5.27647 1.3406 5.46462 1.44741 5.84092C2.02139 7.86318 3.10437 9.77142 4.69636 11.3634C6.28835 12.9554 8.19659 14.0384 10.2189 14.6124C10.5951 14.7192 10.7833 14.7726 11.0586 14.7734C11.3641 14.7743 11.7777 14.6667 12.0441 14.5171C12.2841 14.3823 12.4606 14.2057 12.8137 13.8526L12.9188 13.7476C13.2731 13.3933 13.4503 13.2161 13.5465 13.0259C13.7379 12.6475 13.7379 12.2006 13.5465 11.8223C13.4503 11.632 13.2731 11.4549 12.9188 11.1006L12.7889 10.9707C12.5558 10.7376 12.4393 10.6211 12.3221 10.5449C11.8802 10.2575 11.3105 10.2575 10.8685 10.5449C10.7513 10.6211 10.6348 10.7376 10.4017 10.9707C10.3255 11.0469 10.2874 11.085 10.2429 11.1169C10.0846 11.2302 9.85509 11.2693 9.66819 11.2149C9.6156 11.1995 9.57403 11.1796 9.49091 11.1397C8.52449 10.6757 7.61873 10.0431 6.81768 9.24209Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1330_92838">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const mailIcon = (
  <svg
    className="img"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3332 12L9.90468 7.99996M6.09516 7.99996L1.66661 12M1.33325 4.66663L6.77653 8.47692C7.21731 8.78547 7.4377 8.93974 7.67743 8.9995C7.88918 9.05228 8.11066 9.05228 8.32241 8.9995C8.56213 8.93974 8.78252 8.78547 9.2233 8.47692L14.6666 4.66663M4.53325 13.3333H11.4666C12.5867 13.3333 13.1467 13.3333 13.5746 13.1153C13.9509 12.9236 14.2569 12.6176 14.4486 12.2413C14.6666 11.8134 14.6666 11.2534 14.6666 10.1333V5.86663C14.6666 4.74652 14.6666 4.18647 14.4486 3.75864C14.2569 3.38232 13.9509 3.07636 13.5746 2.88461C13.1467 2.66663 12.5867 2.66663 11.4666 2.66663H4.53325C3.41315 2.66663 2.85309 2.66663 2.42527 2.88461C2.04895 3.07636 1.74299 3.38232 1.55124 3.75864C1.33325 4.18647 1.33325 4.74652 1.33325 5.86663V10.1333C1.33325 11.2534 1.33325 11.8134 1.55124 12.2413C1.74299 12.6176 2.04895 12.9236 2.42527 13.1153C2.85309 13.3333 3.41315 13.3333 4.53325 13.3333Z"
      stroke="var(--primary-purple)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const chatIcon = (
  <svg
    className="img"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4001 8.00002C14.4001 11.0928 11.5347 13.6 8.0001 13.6C6.80679 13.6 5.68976 13.3143 4.73351 12.8167L1.6001 13.6L2.67074 11.1019C1.99434 10.2139 1.6001 9.14723 1.6001 8.00002C1.6001 4.90723 4.46548 2.40002 8.0001 2.40002C11.5347 2.40002 14.4001 4.90723 14.4001 8.00002ZM5.6001 7.20002H4.0001V8.80002H5.6001V7.20002ZM12.0001 7.20002H10.4001V8.80002H12.0001V7.20002ZM7.2001 7.20002H8.8001V8.80002H7.2001V7.20002Z"
      stroke="var(--primary-purple)"
    />
  </svg>
);

const phoneNumber = "+23481 938 3738";

const handleCallButtonClick = () => {
  window.location.href = `tel:${phoneNumber}`;
};

const facebookIcon = (
  <svg
    className="img"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 2.33337C11.3333 2.24496 11.2982 2.16018 11.2357 2.09767C11.1732 2.03515 11.0884 2.00004 11 2.00004H9.33333C8.49408 1.95823 7.6724 2.2503 7.04781 2.81242C6.42323 3.37455 6.04652 4.16103 6 5.00004V6.80003H4.33333C4.24493 6.80003 4.16014 6.83515 4.09763 6.89767C4.03512 6.96018 4 7.04496 4 7.13337V8.8667C4 8.95511 4.03512 9.03989 4.09763 9.1024C4.16014 9.16492 4.24493 9.20004 4.33333 9.20004H6V13.6667C6 13.7551 6.03512 13.8399 6.09763 13.9024C6.16014 13.9649 6.24493 14 6.33333 14H8.33333C8.42174 14 8.50652 13.9649 8.56904 13.9024C8.63155 13.8399 8.66667 13.7551 8.66667 13.6667V9.20004H10.4133C10.4875 9.2011 10.5598 9.17742 10.619 9.13275C10.6782 9.08808 10.7207 9.02496 10.74 8.95337L11.22 7.22004C11.2333 7.17078 11.235 7.11914 11.2252 7.0691C11.2154 7.01905 11.1942 6.97192 11.1633 6.93135C11.1324 6.89078 11.0926 6.85784 11.0469 6.83507C11.0013 6.81231 10.951 6.80032 10.9 6.80003H8.66667V5.00004C8.68325 4.83502 8.76074 4.68211 8.88402 4.57116C9.00729 4.46022 9.16749 4.3992 9.33333 4.40004H11C11.0884 4.40004 11.1732 4.36492 11.2357 4.3024C11.2982 4.23989 11.3333 4.15511 11.3333 4.0667V2.33337Z"
      fill="var(--primary-purple)"
    />
  </svg>
);

const instagramIcon = (
  <svg
    className="img"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00007 5.91724C6.8532 5.91724 5.91726 6.85317 5.91726 8.00005C5.91726 9.14692 6.8532 10.0829 8.00007 10.0829C9.14695 10.0829 10.0829 9.14692 10.0829 8.00005C10.0829 6.85317 9.14695 5.91724 8.00007 5.91724ZM14.2469 8.00005C14.2469 7.13755 14.2548 6.28286 14.2063 5.42192C14.1579 4.42192 13.9298 3.53442 13.1985 2.80317C12.4657 2.07036 11.5798 1.8438 10.5798 1.79536C9.71726 1.74692 8.86257 1.75474 8.00164 1.75474C7.13914 1.75474 6.28445 1.74692 5.42351 1.79536C4.42351 1.8438 3.53601 2.07192 2.80476 2.80317C2.07195 3.53599 1.84539 4.42192 1.79695 5.42192C1.74851 6.28442 1.75632 7.13911 1.75632 8.00005C1.75632 8.86099 1.74851 9.71724 1.79695 10.5782C1.84539 11.5782 2.07351 12.4657 2.80476 13.1969C3.53757 13.9297 4.42351 14.1563 5.42351 14.2047C6.28601 14.2532 7.1407 14.2454 8.00164 14.2454C8.86414 14.2454 9.71882 14.2532 10.5798 14.2047C11.5798 14.1563 12.4673 13.9282 13.1985 13.1969C13.9313 12.4641 14.1579 11.5782 14.2063 10.5782C14.2563 9.71724 14.2469 8.86255 14.2469 8.00005ZM8.00007 11.2047C6.22664 11.2047 4.79539 9.77349 4.79539 8.00005C4.79539 6.22661 6.22664 4.79536 8.00007 4.79536C9.77351 4.79536 11.2048 6.22661 11.2048 8.00005C11.2048 9.77349 9.77351 11.2047 8.00007 11.2047ZM11.336 5.41255C10.9219 5.41255 10.5876 5.07817 10.5876 4.66411C10.5876 4.25005 10.9219 3.91567 11.336 3.91567C11.7501 3.91567 12.0844 4.25005 12.0844 4.66411C12.0846 4.76243 12.0653 4.85981 12.0277 4.95067C11.9902 5.04153 11.935 5.12409 11.8655 5.19361C11.796 5.26314 11.7134 5.31826 11.6226 5.35583C11.5317 5.3934 11.4343 5.41267 11.336 5.41255Z"
      fill="var(--primary-purple)"
    />
  </svg>
);

const twitterIcon = (
  <svg
    className="img"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6666 3.86406C14.1673 4.08538 13.6376 4.23055 13.0953 4.29473C13.6667 3.95303 14.0944 3.41515 14.2986 2.78139C13.7617 3.10037 13.174 3.32496 12.5613 3.44539C12.1858 3.04568 11.6989 2.76799 11.1637 2.64833C10.6285 2.52868 10.0697 2.57258 9.55974 2.77434C9.0498 2.97611 8.61223 3.32643 8.30379 3.77988C7.99535 4.23333 7.83025 4.76898 7.82992 5.31739C7.82955 5.52715 7.85303 5.73627 7.89992 5.94073C6.81242 5.88613 5.74856 5.60347 4.77738 5.11111C3.8062 4.61874 2.9494 3.92766 2.26259 3.08273C1.91276 3.68496 1.80559 4.39788 1.96288 5.07635C2.12017 5.75483 2.53011 6.34786 3.10925 6.73473C2.67454 6.7214 2.24934 6.60413 1.86925 6.39273V6.42739C1.86936 7.05904 2.08796 7.6712 2.48797 8.16004C2.88799 8.64888 3.44479 8.98429 4.06392 9.10939C3.82904 9.17326 3.58666 9.20532 3.34325 9.20473C3.17035 9.20481 2.99783 9.18874 2.82792 9.15673C3.00262 9.70016 3.34276 10.1754 3.8008 10.5161C4.25883 10.8567 4.81186 11.0458 5.38259 11.0567C4.23553 11.9545 2.77953 12.3613 1.33325 12.1881C2.58396 12.9921 4.03975 13.4188 5.52659 13.4174C6.55043 13.4243 7.56545 13.2277 8.51269 12.8391C9.45992 12.4504 10.3205 11.8775 11.0445 11.1535C11.7684 10.4295 12.3413 9.56882 12.7299 8.62154C13.1184 7.67427 13.3149 6.65924 13.3079 5.63539C13.3079 5.51673 13.305 5.39895 13.2993 5.28206C13.8356 4.89433 14.2986 4.41416 14.6666 3.86406Z"
      fill="var(--primary-purple)"
    />
  </svg>
);

const ContactUsModal: React.FC<MyComponentProps> = ({ visible, onClose }) => {
  const actionCtx = useContext(ActionContext);
  return (
    <RavenModal onClose={onClose} visble={visible} onBtnClick={() => {}}>
      <div className="total-referral-modal-wrap-index">
        <div className="title-text-box">
          <p className="title">Contact us</p>
          <p className="text grey-white-color">
            Reach out to us throught the following below
          </p>
        </div>

        {/* total cash detail box start */}
        <a
          style={{ textDecoration: "none", cursor: "pointer" }}
          target="_blank"
          rel="noreferrer"
          href="https://maps.app.goo.gl/ydw81xpVrfJ3RNQS7"
        >
          {" "}
          <div className=" contact-us-inner-wrap border-theme">
            {/* icon text image wrap start */}
            <div className="icon-text-img-wrap">
              <div className="icon-wrap grey-bg-two">
                <figure className="img-box">{adressIcon}</figure>
              </div>
              <p className="text">Address</p>
            </div>
            {/* icon text image wrap end */}
            <p className="adress-text grey-white-color-white">
              Plot 20, Layi Yusuf Cresent, Lekki Phase 1
            </p>
          </div>
        </a>
        {/* total cash detail box end */}
        {/* call email and chat start */}
        <div style={{ gap: "1.5rem" }} className=" contact-us-inner-wrap border-theme">
          {/* icon text image wrap start */}
          <div
            onClick={handleCallButtonClick}
            className="icon-text-img-wrap border-theme-bottom"
            style={{ paddingBottom: "1.5rem" }}
          >
            <div className="icon-wrap grey-bg-two">
              <figure className="img-box">{callIcon}</figure>
            </div>
            <p className="text">{phoneNumber}</p>
          </div>
          {/* icon text image wrap end */}
          {/* icon text image wrap start */}
          <a
            style={{ textDecoration: "none", cursor: "pointer" }}
            // target="_blank"
            // rel="noreferrer"
            href="mailto:support@getravenbank.com"
          >
            {" "}
            <div
              style={{ paddingBottom: "1.5rem" }}
              className="icon-text-img-wrap border-theme-bottom"
            >
              <div className="icon-wrap grey-bg-two">
                <figure className="img-box">{mailIcon}</figure>
              </div>
              <p className="text">support@getravenbank.com</p>
            </div>
          </a>

          {/* icon text image wrap end */}
          {/* icon text image wrap start */}
          <div
            onClick={() => {
              // window.Intercom("show");
              if (window.fcWidget) {
                actionCtx.setContactUsModal(false);
                window.fcWidget.open();
              }
            }}
            className="icon-text-img-wrap"
          >
            <div className="icon-wrap grey-bg-two">
              <figure className="img-box">{chatIcon}</figure>
            </div>
            <p className="text">Live chat</p>
          </div>
          {/* icon text image wrap end */}
        </div>
        {/* call email and chat end */}
        {/* call email and chat start */}
        <div style={{ gap: "1.5rem" }} className=" contact-us-inner-wrap border-theme">
          {/* icon text image wrap start */}
          <a
            style={{ textDecoration: "none", cursor: "pointer" }}
            target="_blank"
            rel="noreferrer"
            href="https://m.facebook.com/theravenbank"
          >
            {" "}
            <div
              style={{ paddingBottom: "1.5rem" }}
              className="icon-text-img-wrap border-theme-bottom"
            >
              <div className="icon-wrap grey-bg-two">
                <figure className="img-box">{facebookIcon}</figure>
              </div>
              <p className="text">Facebook</p>
            </div>
          </a>

          {/* icon text image wrap end */}
          {/* icon text image wrap start */}
          <a
            style={{ textDecoration: "none", cursor: "pointer" }}
            target="_blank"
            rel="noreferrer"
            href="https://instagram.com/theravenbank?igshid=MTNiYzNiMzkwZA=="
          >
            {" "}
            <div
              style={{ paddingBottom: "1.5rem" }}
              className="icon-text-img-wrap border-theme-bottom"
            >
              <div className="icon-wrap grey-bg-two">
                <figure className="img-box">{instagramIcon}</figure>
              </div>
              <p className="text">Instagram</p>
            </div>
          </a>

          {/* icon text image wrap end */}
          {/* icon text image wrap start */}
          <a
            style={{ textDecoration: "none", cursor: "pointer" }}
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/theravenbank"
          >
            {" "}
            <div className="icon-text-img-wrap ">
              <div className="icon-wrap grey-bg-two">
                <figure className="img-box">{twitterIcon}</figure>
              </div>
              <p className="text">Twitter</p>
            </div>
          </a>

          {/* icon text image wrap end */}
        </div>
        {/* call email and chat end */}
      </div>
    </RavenModal>
  );
};

export default ContactUsModal;
