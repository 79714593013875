import clsx from "clsx";
// import { CountryStateCityList } from "./CountryStateCityList";

export function cn(...inputs: clsx.ClassArray) {
  return clsx(inputs);
}

export function generateArray(length: number): number[] {
  return Array.from({ length }, (_, index) => index + 1);
}

export function getAccentColor() {
  let colorVal;
  const accentColor = localStorage.getItem("accent-color");
  if (
    accentColor === "green" ||
    accentColor === "orange" ||
    accentColor === "purple"
  ) {
    const color = accentColor ? `${accentColor}-dark` : "purple-dark";
    colorVal = color;
  } else {
    colorVal = "purple-dark";
  }
  return colorVal;
}


