import { useState } from "react";
import SettingIndexLayout from "../SettingsIndexLayout";
import { SettingsRowItem } from "../blocks";
import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import ChangePasswordModal from "./ChangePasswordModal";
import "../../../../styles/dashboard/settings/security/index.css";
import ChangePinModal from "./ChangePin";

const Icons = {
  password: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0001 9.99967H10.0042M14.1667 9.99967H14.1709M5.83341 9.99967H5.83758M4.33341 5.83301H15.6667C16.6002 5.83301 17.0669 5.83301 17.4234 6.01466C17.737 6.17445 17.992 6.42942 18.1518 6.74302C18.3334 7.09954 18.3334 7.56625 18.3334 8.49967V11.4997C18.3334 12.4331 18.3334 12.8998 18.1518 13.2563C17.992 13.5699 17.737 13.8249 17.4234 13.9847C17.0669 14.1663 16.6002 14.1663 15.6667 14.1663H4.33342C3.39999 14.1663 2.93328 14.1663 2.57676 13.9847C2.26316 13.8249 2.00819 13.5699 1.8484 13.2563C1.66675 12.8998 1.66675 12.4331 1.66675 11.4997V8.49967C1.66675 7.56625 1.66675 7.09954 1.8484 6.74302C2.00819 6.42942 2.26316 6.17445 2.57676 6.01466C2.93328 5.83301 3.39999 5.83301 4.33341 5.83301ZM10.2084 9.99967C10.2084 10.1147 10.1151 10.208 10.0001 10.208C9.88502 10.208 9.79175 10.1147 9.79175 9.99967C9.79175 9.88462 9.88502 9.79134 10.0001 9.79134C10.1151 9.79134 10.2084 9.88462 10.2084 9.99967ZM14.3751 9.99967C14.3751 10.1147 14.2818 10.208 14.1667 10.208C14.0517 10.208 13.9584 10.1147 13.9584 9.99967C13.9584 9.88462 14.0517 9.79134 14.1667 9.79134C14.2818 9.79134 14.3751 9.88462 14.3751 9.99967ZM6.04175 9.99967C6.04175 10.1147 5.94847 10.208 5.83341 10.208C5.71836 10.208 5.62508 10.1147 5.62508 9.99967C5.62508 9.88462 5.71836 9.79134 5.83341 9.79134C5.94847 9.79134 6.04175 9.88462 6.04175 9.99967Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  pin: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.3334 9.1665V6.83317C18.3334 5.89975 18.3334 5.43304 18.1518 5.07652C17.992 4.76292 17.737 4.50795 17.4234 4.34816C17.0669 4.1665 16.6002 4.1665 15.6667 4.1665H4.33341C3.39999 4.1665 2.93328 4.1665 2.57676 4.34816C2.26316 4.50795 2.00819 4.76292 1.8484 5.07652C1.66675 5.43304 1.66675 5.89975 1.66675 6.83317V9.83317C1.66675 10.7666 1.66675 11.2333 1.8484 11.5898C2.00819 11.9034 2.26316 12.1584 2.57676 12.3182C2.93328 12.4998 3.39999 12.4998 4.33341 12.4998H9.16675M10.0001 8.33317H10.0042M14.1667 8.33317H14.1709M5.83341 8.33317H5.83758M16.0417 14.1665V12.7082C16.0417 11.9028 15.3888 11.2498 14.5834 11.2498C13.778 11.2498 13.1251 11.9028 13.1251 12.7082V14.1665M10.2084 8.33317C10.2084 8.44823 10.1151 8.5415 10.0001 8.5415C9.88502 8.5415 9.79175 8.44823 9.79175 8.33317C9.79175 8.21811 9.88502 8.12484 10.0001 8.12484C10.1151 8.12484 10.2084 8.21811 10.2084 8.33317ZM14.3751 8.33317C14.3751 8.44823 14.2818 8.5415 14.1667 8.5415C14.0517 8.5415 13.9584 8.44823 13.9584 8.33317C13.9584 8.21811 14.0517 8.12484 14.1667 8.12484C14.2818 8.12484 14.3751 8.21811 14.3751 8.33317ZM6.04175 8.33317C6.04175 8.44823 5.94847 8.5415 5.83341 8.5415C5.71836 8.5415 5.62508 8.44823 5.62508 8.33317C5.62508 8.21811 5.71836 8.12484 5.83341 8.12484C5.94847 8.12484 6.04175 8.21811 6.04175 8.33317ZM13.0001 17.4998H16.1667C16.6335 17.4998 16.8668 17.4998 17.0451 17.409C17.2019 17.3291 17.3294 17.2016 17.4093 17.0448C17.5001 16.8666 17.5001 16.6332 17.5001 16.1665V15.4998C17.5001 15.0331 17.5001 14.7998 17.4093 14.6215C17.3294 14.4647 17.2019 14.3372 17.0451 14.2573C16.8668 14.1665 16.6335 14.1665 16.1667 14.1665H13.0001C12.5334 14.1665 12.3 14.1665 12.1218 14.2573C11.965 14.3372 11.8375 14.4647 11.7576 14.6215C11.6667 14.7998 11.6667 15.0331 11.6667 15.4998V16.1665C11.6667 16.6332 11.6667 16.8666 11.7576 17.0448C11.8375 17.2016 11.965 17.3291 12.1218 17.409C12.3 17.4998 12.5334 17.4998 13.0001 17.4998Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  toggle: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

type Actions = "change-password" | "change-pin" | "toggle-balance";

interface Fields {
  title: string;
  desc: string;
  icon: JSX.Element;
  action: Actions;
}

const fields: Fields[] = [
  {
    title: "Change password",
    desc: "Change your personal banking password",
    icon: Icons.password,
    action: "change-password",
  },
  {
    title: "Change your transaction pin",
    desc: "Change your pin for all transactions",
    icon: Icons.pin,
    action: "change-pin",
  },
  {
    title: "Show balance",
    desc: "Show or hide account balance",
    icon: Icons.toggle,
    action: "toggle-balance",
  },
];

const SettingsSecurityIndex = () => {
  const [action, setAction] = useState<Actions>();

  const handleRowClick = (ctx?: Actions) => {
    setAction(ctx);
  };

  return (
    <SettingIndexLayout title="Security">
      <ul className="settings-grouped-list">
        {fields.slice(0, 2).map((ele) => (
          <li
            onClick={() => handleRowClick(ele.action)}
            className="settings-grouped-list-item cursor-pointer border-theme"
            key={ele.title}
          >
            <SettingsRowItem {...ele} />
          </li>
        ))}
        <li className="settings-grouped-list-item border-theme">
          <SettingsRowItem {...fields[2]} />
          <RavenToggleSlide color="purple-light" />
        </li>
      </ul>

      {action === "change-password" && (
        <ChangePasswordModal visible onClose={() => handleRowClick()} />
      )}

      {action === "change-pin" && (
        <ChangePinModal visible onClose={() => handleRowClick()} />
      )}
    </SettingIndexLayout>
  );
};

export default SettingsSecurityIndex;
