import SpendingInsight from "src/pages/dashboard/transaction/SpendingInsight";
import TransactionCategoryPage from "src/pages/dashboard/transaction/TransactionCategoryPage";
import TransactionIndex from "src/pages/dashboard/transaction/TransactionIndex";

export const dashboard_transaction_routes_group = [
  {
    path: "/dashboard-transaction",
    element: TransactionIndex,
  },
  {
    path: "/dashboard-transaction-spending-insight",
    element: SpendingInsight,
  },
  {
    path: "/dashboard-transaction-category",
    element: TransactionCategoryPage,
  },
];
