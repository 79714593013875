import React, { useContext, useState } from "react";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import useWindowSize from "src/utils/UseWindowSize";
import "../../../../styles/dashboard/bill-utilities/AirtimeIndex.css";
import { useNavigate } from "react-router-dom";
import StepperMainWrapper from "src/components/reusables/StepperMainWrapper";
import { AmountInfoIconSpan } from "../../send-money/raven/AmountSpanInfo";
import { RavenInputField, } from "@ravenpay/raven-bank-ui";
import mtnIcon from "../../../../assets/mtnIcon.svg";
import mobileIcon from "../../../../assets/mobile-icon.svg";
import gloIcon from "../../../../assets/glo-icon.svg";
import airtelIcon from "../../../../assets/airtel-icon.svg";
// import { DetectNetwork } from "src/utils/DetectNetwork";
import {
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  getAllAmount,
} from "src/helper";
import { InfoIconSpan } from "../../send-money/raven/UserSpanInfo";
import { FaTimes } from "react-icons/fa";
import AllRecipientModal from "./AllRecipientModal";
import NewPreviewDetailBox from "./PreviewDetailBox";
import SuccessModal from "src/components/fragments/TransactionSuccessModal";
import TransactionPinModal from "src/components/fragments/TransactionPinModal";
import ActionContext from "src/context/ActionContext";
// import { InfoIconSpan } from "src/pages/auth/InfoIconSpan";

const ElectricityIndex = () => {
  const stepList = ["Select option", "Pay a bill", "Confirm transaction"];
  const [activeStep, setActiveStep] = useState(2);
  const [viewAll, setViewAll] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const size = useWindowSize();
  const navigate = useNavigate();
  const actionCtx = useContext(ActionContext);
  const [selectedProvider, setSelectedProvider] = useState("Prepaid");

  type RegisterProp = {
    amount: string;
    narration: string;
    bulk_list: any[];
    beneficiary: boolean;
    schedule: string;
    schedule_date: string;
    user_pin: string;
    provider: string;
    type: string;
    phone: string;
    recipients: any[];
  };

  const [details, setDetails] = useState<RegisterProp>({
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    provider: "",
    type: "",
    amount: "",
    phone: "",
    user_pin: "",
    schedule: "",
    schedule_date: "",
    recipients: [],
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };
  const providerList = [
    { name: "Prepaid", },
    { name: "Postpaid", },
  ];

  type showModalProp = {
    pin: boolean;
    success: boolean;
    view: boolean;
  };
  const [showModal, setShowModal] = useState<showModalProp>({
    pin: false,
    success: false,
    view: false,
  });

  const handleStepClick = (param: number) => {
    if (param === 1) {
      navigate("/dashboard-bill-utilities");
    }
  };

//   const handleBulkTransfer = () => {
//     const checkList = details?.bulk_list?.some(
//       (chi) => chi?.phone === details?.phone
//     );
//     if (!checkList) {
//       const obj = {
//         phone: details?.phone,
//         amount: details?.amount,
//         // beneficiary: false,
//         provider: selectedProvider,
//       };
//       const newList = [obj, ...details?.bulk_list];
//       //   console.log(newList);
//       setSelectedProvider("");
//       setDetails((prev) => {
//         return {
//           ...prev,
//           bulk_list: newList,
//           phone: "",
//           amount: "",
//         };
//       });
//     } else {
//       console.log("jhghj");

//       toast.error("Recepient already exist in bulk list");
//     }
//   };

  const handleRemoveFromList = (obj: any) => {
    if (details?.bulk_list?.length === 1) {
      setViewAll(false);
    }
    const newList = details?.bulk_list?.filter(
      (chi) => chi?.phone !== obj?.phone
    );
    setDetails((prev) => {
      return {
        ...prev,
        bulk_list: newList,
      };
    });
  };

//   const handleAddBeneficiary = (obj: any, val: string) => {
//     if (details?.bulk_list?.length > 0) {
//       const newList = details?.bulk_list?.map((chi) =>
//         chi?.phone === obj?.phone ? { ...chi, beneficiary: val } : chi
//       );
//       setDetails((prev) => {
//         return {
//           ...prev,
//           bulk_list: newList,
//         };
//       });
//     } else {
//       const newObj = { ...details, beneficiary: val };
//       setDetails(newObj as any);
//     }
//   };

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* content wrapper start */}
        <ContainerWrapper
          activeStep={activeStep}
          stepList={stepList}
          dontShowHeader={(size.width as any) < 900}
          width={(size.width as any) < 900 && `100%`}
          back={activeStep === 3}
          onBack={() => setActiveStep(activeStep - 1)}
        >
          {/* send money index wrap start */}
          <div className="bill-utilities-airtime-index-wrap">
            {/* step two start */}
            {activeStep === 2 && (
              <>
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Proceed"
                  title="Electricity"
                  text="Pay your electricity bills in the speed of light."
                  onBack={() => {
                    activeStep === 2 && handleStepClick(1);
                  }}
                  disabled={
                    activeStep === 2
                      ? details?.bulk_list.length > 0
                        ? details?.bulk_list.length < 1
                        : !details?.amount ||
                          !details?.phone ||
                          !selectedProvider
                      : false
                  }
                  onBtnClick={() => setActiveStep(3)}
                >
                  {/* select type box start */}
                  <div className="select-type-box ">
                    <p className="title dull-bright-filter-two">
                      Select Network
                    </p>
                    {/* select wrap start */}
                    <div className="select-wrap select-wrap-provider  grey-bg">
                      {providerList.map((chi, idx) => {
                        const {  name } = chi;
                        return (
                          <div
                            className={`child-wrap select-option-class-electricity ${
                              selectedProvider === name &&
                              "select-option-class-electricity-active"
                            }`}
                            key={idx}
                            onClick={() => {
                              setSelectedProvider(name);
                              setDetails((prev) => {
                                return { ...prev, provider: name };
                              });
                            }}
                          >
                          <p className="name grey-white-color">{name}</p>
                          </div>
                        );
                      })}
                    </div>
                    {/* select wrap end */}
                  </div>
                  {/* select type box end */}
                  <RavenInputField
                    labelSpanText={InfoIconSpan(true)}
                    placeholder="Select provider"
                    type="select"
                    label="Service Provider"
                    color="purple-light"
                    value={details?.phone}
                    onChange={handleChange}
                    name={`phone`}
                  />
                  {/* use my number box start */}
                  {/* <div
                    onClick={() => {
                      setDetails((prev) => {
                        return { ...prev, phone: `08162631944` };
                      });
                    }}
                    className="use-my-number-box fade-purple-bg grey-bg-two"
                  >
                    <p className="number">Use my number</p>
                  </div> */}
                  {/* use my number box end */}
                  <RavenInputField
                    // labelSpanText={AmountInfoIconSpan(false, 21457) as string}
                    placeholder="Enter meter number"
                    type="number"
                    label="Meter number"
                    color="purple-dark"
                    value={details?.amount}
                    onChange={handleChange}
                    name={`amount`}
                  />
                  <RavenInputField
                    labelSpanText={AmountInfoIconSpan(false, 21457) as string}
                    placeholder="How much do you want to send?"
                    type="number"
                    label="Amount"
                    color="purple-dark"
                    thousandFormat
                    numberPrefix={`₦`}
                    // style={{ marginTop: "-1rem" }}
                    value={details?.amount}
                    onChange={handleChange}
                    name={`amount`}
                  />
                  {/* add another text start */}
                  {/* <div
                    onClick={() => {
                      selectedProvider &&
                        details?.amount &&
                        details?.phone &&
                        handleBulkTransfer();
                    }}
                    className={`add-another-box ${
                      selectedProvider &&
                      details?.amount &&
                      details?.phone &&
                      "add-another-box-active"
                    }`}
                  >
                    <figure className="img-box">{addIcon}</figure>
                    <p className="text">Recharge for another recipient</p>
                  </div> */}
                  {/* add another text end */}
                  {/* recepient box start */}
                  <div
                    className={`recepient-box ${
                      details?.bulk_list?.length > 0 && "recepient-box-show"
                    }`}
                  >
                    <div className="text-line">
                      <span></span>
                      <p className="text">Recipients</p>
                      <span></span>
                    </div>
                    <div className="view-total-box">
                      <p className="total">{`Total added (${details?.bulk_list?.length})`}</p>
                      <div className="view-wrap">
                        {/* view all box start */}

                        {/* view all box end */}
                        {viewAll ? (
                          <p
                            onClick={() => {
                              setViewAll(false);
                              details?.bulk_list?.length === 1 &&
                                setViewAll(false);
                            }}
                            className="text"
                          >
                            Close
                          </p>
                        ) : (
                          <p
                            onClick={() => {
                              setViewAll(true);
                            }}
                            className="text"
                          >
                            View all
                          </p>
                        )}
                      </div>
                    </div>
                    {/* end of view total box */}
                    {/* preview list start */}
                    {!viewAll && (
                      <div className="preview-box">
                        {details?.bulk_list?.slice(0, 2).map((chi, idx) => {
                          const { phone, amount, provider } = chi;
                          return (
                            <div className="box box-card grey-bg" key={idx}>
                              <div
                                onClick={() => {
                                  handleRemoveFromList(chi);
                                }}
                                className="cancel-box"
                              >
                                <FaTimes className="icon" />
                              </div>
                              <figure className="img-box">
                                <img
                                  src={
                                    provider === "mtn"
                                      ? mtnIcon
                                      : provider === "glo"
                                      ? gloIcon
                                      : provider === "airtel"
                                      ? airtelIcon
                                      : mobileIcon
                                  }
                                  alt=""
                                  className="img"
                                />
                              </figure>
                              <p className="name">{`+234 ${phone}`}</p>
                              <p className="text grey-white-color-white">{` ₦ ${amount}`}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* preview list end */}

                    {/* <div className="space-bottom"></div> */}
                  </div>
                  {/* recepient box end */}
                </StepperMainWrapper>
              </>
            )}
            {/* step two end */}

            {/* step three start */}
            {activeStep === 3 && (
              <>
                <NewPreviewDetailBox
                  bulkAmount={
                    details?.bulk_list?.length > 0
                      ? formatNumWithCommaNairaSymbol(
                          getAllAmount(details?.bulk_list) as any
                        )
                      : formatNumWithCommaNairaSymbol(
                          formatNumWithoutCommaNaira(details?.amount)
                        )
                  }
                  valueSchedule={fromDate}
                  onChangeSchedule={(param) => {
                    // console.log(param);
                    setFromDate(param);
                    setDetails((prev) => {
                      return { ...prev, schedule: param };
                    });
                  }}
                  onSubmit={() => {
                    setShowModal((prev) => {
                      return { ...prev, pin: true };
                    });
                  }}
                  list={
                    details.bulk_list.length < 2
                      ? [
                          {
                            phone: details?.phone,
                            provider: details?.provider,
                            amount: details?.amount,
                            narration: details?.narration,
                            beneficiary: false,
                          },
                        ]
                      : details?.bulk_list
                  }
                  onReset={() => setFromDate("")}
                />
              </>
            )}
            {/* step three end */}
          </div>
          {/* send money index wrap end */}
        </ContainerWrapper>
        {/* content wrapper end */}
      </DashboardLayoutIndex>
      {/* all recipient modal start */}
      <AllRecipientModal
        list={details?.bulk_list}
        onClose={() => {
          setViewAll(false);
        }}
        handleRemoveFromList={handleRemoveFromList}
        visible={viewAll}
      />
      {/* all recipient modal end */}
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin) => {
          //   handleSubmit(pin);
          actionCtx?.setConfettiVal();
          actionCtx?.playSound();
          setShowModal((prev) => {
            return { ...prev, success: true, pin: false };
          });
        }}
        value={details?.user_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        // loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        // onClose={() => {
        //   setShowModal((prev) => {
        //     return { ...prev, success: false };
        //   });
        // }}
        title="Transaction Successful."
        text={`You have successfully sent ₦150,000 to Ayeni Kehinde Timilehin`}
        btnText={`Go to dashboard`}
        onBtnClick={() => {
          navigate("/dashboard-overview");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default ElectricityIndex;
