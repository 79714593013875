import React from "react";
import "../../styles/dashboard/reusables/StepperMainWrapper.css";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import BackToDashboard from "../fragments/BackToDashboard";

interface MyComponentProps {
  children?: React.ReactNode;
  title?: string;
  text?: string;
  btnText?: string;
  onBtnClick?: () => void;
  btnColor?: string;
  loading?: boolean;
  onBack?: () => void;
  disabled?: boolean;
  className?: string;
  back?: boolean;
}

const StepperMainWrapper: React.FC<MyComponentProps> = ({
  children,
  title,
  text,
  btnColor,
  btnText,
  onBtnClick,
  loading,
  onBack,
  disabled,
  className,
  back,
}) => {
  return (
    <div className={`Stepper-main-wrapper-index border-theme ${className && className}`}>
      {/* back button start */}
     {back && <BackToDashboard onBack={onBack} typeTwo small text="Back" />}
      {/* back button end */}
      {/* title text box start */}
      <div className="title-text-box">
        <p className="title">{title}</p>
        <p className="text grey-white-color">{text}</p>
      </div>
      {/* title text box end */}
      {/* content wrap start */}
      <div className="content-wrap-box">
        {children}
      </div>
      {/* content wrap end */}
      {/* btn start */}
      {btnText && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onBtnClick && onBtnClick();
          }}
          action=""
          className="form"
        >
          <RavenInputField
            className="btn"
            color={btnColor as any}
            value={btnText}
            type="submit"
            loading={loading}
            disabled={disabled}
            // purpleGradient
          />
        </form>
      )}
      {/* btn end */}
    </div>
  );
};

export default StepperMainWrapper;
