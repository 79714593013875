// import { ThreeDots } from 'react-loader-spinner';
// import { RavenToolTip } from "@ravenpay/raven-bank-ui";

import { formatNumWithCommaNairaSymbol } from "src/helper";

export const AmountInfoIconSpan = (
  loading: boolean | false,
  amount?: string | number
) => {
  return loading ? (
    ((
      <div
        style={{ display: "flex", alignItems: "center", gap: ".8rem" }}
        className="info-box "
      ></div>
    ) as unknown as string)
  ) : (
    <div
      style={{
        padding: ".6rem 1rem",
        borderRadius: "3rem",
        display: "flex",
        justifyContent: "center",
        // backgroundColor: "rgba(241, 238, 252, 1)",
      }}
      className="fade-purple-bg grey-bg-two"
    >
      <span style={{ color: "var(--primary-purple)", fontSize: "1.25rem" }}>
        {formatNumWithCommaNairaSymbol(amount as any)}
      </span>
    </div>
  );
};
