import React, { useEffect, useState } from "react";
import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import { getAccentColor } from "src/utils/helpers";

interface MyComponentProps {
  onClose: () => void;
  showModal: boolean;
  onSucess: () => void;
  userEmail?: string;
}

const ConfirmPasswordModal: React.FC<MyComponentProps> = ({
  onClose,
  showModal,
  onSucess,
  userEmail
}) => {
  const [completeToken, setCompleteToken] = useState(false);
  const [toggleKey, setKeyToggle] = useState(false);
  const [details, setDetails] = useState({
    token: "",
  });

  const handleSubmit = async () => {
    onSucess();
  };

  useEffect(() => {
    setKeyToggle(!toggleKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleResendEmailOtp = async () => {};

  return (
    <RavenModal
      onClose={onClose}
      visble={showModal}
      btnLabel="Verify Account"
      btnColor={getAccentColor() as any}
      effect={`fadeInUp`}
      disabled={!completeToken}
      //   loading={loading}
      onBtnClick={handleSubmit}
      className={`auth-pin-modal-wrap`}
    >
      <form action="" className="form form-modal-auth">
        <div className="text-box">
          <p className="big-title">Email Verification</p>
          <p className="small-title">
            {`Please kindly provide the 6-Digit OTP sent to the email provided to
            verify account. `} <span>{userEmail || ""}</span>
          </p>
        </div>
        <RavenInputField
          type={`pin`}
          // label={`Enter token sent to your email`}
          color={getAccentColor() as any}
          onChange={(e: any) => {
            setCompleteToken(false);
            setDetails((prev) => {
              return { ...prev, token: e };
            });
          }}
          onComplete={() => {
            setCompleteToken(true);
          }}
          value={details?.token}
          showCountDown
          // countDownTime={1000}
          key={toggleKey as any}
          // onCountDownComplete={() => setShowResend(true)}
          countdownFinishClick={handleResendEmailOtp}
          countdownFinishText={` Resend OTP`}
          countdownFinishClassName={`resend-text`}
          // onCountdownFinish
          countdownLabelClassName={`black-white-color`}
        />
        {/* <p onClick={handleResendEmailOtp} className="resend-text">
          Resend OTP
        </p> */}
      </form>
    </RavenModal>
  );
};

export default ConfirmPasswordModal;
