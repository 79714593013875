import { useState } from "react";
import SettingIndexLayout from "../SettingsIndexLayout";
import { SettingsRowItem, SettingsTabHeader } from "../blocks";
import { accessIcon, copyIcon, deleteIcon, gtLogo, ravenlogo } from "./icons";
import DeleteBeneficiaryModal from "./DeleteBeneficiaryModal";

const options = ["Raven", "Other banks"];

const data = [
  {
    name: "Tayo Ola",
    username: "tee",
    phone: "8748297488392",
    icon: gtLogo,
  },
  {
    name: "Pelumi Breeze",
    username: "pelumiii",
    phone: "8748297488392",
    icon: accessIcon,
  },
];

interface RavenBeneficiariesProps {
  showAsExternal: boolean;
  onClick(): void;
}
const RavenBeneficiaries = ({ showAsExternal, onClick }: RavenBeneficiariesProps) => (
  <div className="settings-grouped-list settings-grouped-list-two">
    {data.map(({ name, username, icon, phone }) => (
      <div className="settings-grouped-list-item border-theme beneficiaries-list" key={name}>
        <SettingsRowItem
          desc={showAsExternal ? phone : `@${username}`}
          title={name}
          icon={showAsExternal ? icon : ravenlogo}
        />
        <div className="beneficiaries-right">
          <div className="purple-accent-box fade-purple-bg grey-bg-two">
            <figure>{copyIcon}</figure>
            <span>Copy</span>
          </div>
          <button
            onClick={onClick}
            className="btn-reset btn-delete cursor-pointer"
            type="button"
          >
            {deleteIcon}
          </button>
        </div>
      </div>
    ))}
  </div>
);

const SettingsBeneficiaries = () => {
  const [selected, setSelected] = useState(options[0]);
  const [showModal, setShowModal] = useState(false);

  return (
    <SettingIndexLayout title="Beneficiaries">
      <div>
        <SettingsTabHeader options={options} selected={selected} onChange={setSelected} />

        <div className="after-tab-component">
          <RavenBeneficiaries
            onClick={() => setShowModal(true)}
            showAsExternal={selected === options[1]}
          />
        </div>
      </div>

      {showModal && (
        <DeleteBeneficiaryModal visible onClose={() => setShowModal(false)} />
      )}
    </SettingIndexLayout>
  );
};

export default SettingsBeneficiaries;
