import axios from 'axios';
import env from '../env'
 
const API = env.base_url;

/** base url to make request to the BE end point */

const instance = axios.create({
	baseURL: API,
});

instance.interceptors.request.use(
	async (config) => {
		// const _reToken = getCookie('g-rec-response'); 
		// if (_reToken) {
		// 	config.headers['g-rec-response'] = _reToken;
		// }
		try {
			// const encryptedData = await encrypt2(config.data ?? '');

			config.data = { data: config };
		} catch (error) {
			// logger.log(error);
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	async (response) => {
		// response.data.data = decrypt2(response.data.data);
		delete response.config.transformRequest;
		delete response.data?.header;
		return response;
	},
	async (error) => {
		let val;

		// logger.log(decrypt2(error.response.data.data), 'the error');
		if (error.response.data.status === 'fail') {
			val = error.response;
			delete val.config.transformRequest;
		}

		// val.data.data = decrypt2(val.data.data);
		return val;
	}
);

export default instance;
