import { useEffect, useRef } from "react";

export const useOnClickOutsideTwo = (handler: (e: MouseEvent) => void) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return ref;
};
    