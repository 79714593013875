import React, { useContext, useState } from "react";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import useWindowSize from "src/utils/UseWindowSize";
import "../../../../styles/dashboard/bill-utilities/AirtimeIndex.css";
import { useNavigate } from "react-router-dom";
import StepperMainWrapper from "src/components/reusables/StepperMainWrapper";
// import { AmountInfoIconSpan } from "../../send-money/raven/AmountSpanInfo";
import { RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import mtnIcon from "../../../../assets/mtnIcon.svg";
import mobileIcon from "../../../../assets/mobile-icon.svg";
import gloIcon from "../../../../assets/glo-icon.svg";
import airtelIcon from "../../../../assets/airtel-icon.svg";
import { DetectNetwork } from "src/utils/DetectNetwork";
import {
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  getAllAmount,
} from "src/helper";
import { InfoIconSpan } from "../../send-money/raven/UserSpanInfo";
import { FaTimes } from "react-icons/fa";
import AllRecipientModal from "./AllRecipientModal";
import NewPreviewDetailBox from "./PreviewDetailBox";
import SuccessModal from "src/components/fragments/TransactionSuccessModal";
import TransactionPinModal from "src/components/fragments/TransactionPinModal";
import ActionContext from "src/context/ActionContext";
// import { InfoIconSpan } from "src/pages/auth/InfoIconSpan";

const CableIndex = () => {
  const stepList = ["Select option", "Pay a bill", "Confirm transaction"];
  const [activeStep, setActiveStep] = useState(2);
  const [viewAll, setViewAll] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const size = useWindowSize();
  const navigate = useNavigate();
  const actionCtx = useContext(ActionContext);
  const [selectedProvider, setSelectedProvider] = useState("");
  const addIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.51 16.6281C14.258 16.6281 17.2964 13.5897 17.2964 9.84169C17.2964 6.09367 14.258 3.0553 10.51 3.0553C6.762 3.0553 3.72363 6.09367 3.72363 9.84169C3.72363 13.5897 6.762 16.6281 10.51 16.6281Z"
        fill="var(--primary-purple)"
      />
      <path
        d="M10.5106 16.6776C6.74119 16.6776 3.6748 13.6112 3.6748 9.84173C3.6748 6.07228 6.74119 3.00562 10.5106 3.00562C14.2801 3.00562 17.3467 6.07228 17.3467 9.84173C17.3467 13.6112 14.2801 16.6776 10.5106 16.6776ZM10.5106 3.10478C6.79592 3.10478 3.77397 6.127 3.77397 9.84173C3.77397 13.5564 6.79592 16.5784 10.5106 16.5784C14.2254 16.5784 17.2473 13.5556 17.2473 9.84173C17.2473 6.12784 14.2254 3.10478 10.5106 3.10478Z"
        fill="var(--primary-purple)"
      />
      <path
        d="M10.1545 14.0692C10.1077 14.0224 10.0705 13.9669 10.0452 13.9058C10.0199 13.8447 10.0069 13.7792 10.007 13.7131V5.97027C10.0054 5.90316 10.0173 5.83641 10.0418 5.77395C10.0664 5.71148 10.1033 5.65457 10.1502 5.60654C10.1971 5.55852 10.2531 5.52036 10.315 5.49431C10.3769 5.46826 10.4433 5.45483 10.5104 5.45483C10.5776 5.45483 10.644 5.46826 10.7059 5.49431C10.7678 5.52036 10.8238 5.55852 10.8707 5.60654C10.9176 5.65457 10.9545 5.71148 10.979 5.77395C11.0036 5.83641 11.0155 5.90316 11.0139 5.97027V13.7131C11.0139 13.8126 10.9843 13.9099 10.929 13.9927C10.8737 14.0754 10.7951 14.1399 10.7031 14.178C10.6111 14.2161 10.5099 14.2261 10.4123 14.2068C10.3146 14.1874 10.2249 14.1395 10.1545 14.0692Z"
        fill="white"
      />
      <path
        d="M6.28297 10.1975C6.21264 10.127 6.16475 10.0373 6.14538 9.93967C6.126 9.84203 6.13599 9.74083 6.1741 9.64886C6.21221 9.5569 6.27671 9.47829 6.35947 9.42296C6.44224 9.36764 6.53954 9.33808 6.63908 9.33803H14.3819C14.449 9.33645 14.5157 9.34831 14.5782 9.3729C14.6407 9.3975 14.6976 9.43433 14.7456 9.48124C14.7936 9.52815 14.8318 9.58418 14.8578 9.64605C14.8839 9.70792 14.8973 9.77437 14.8973 9.8415C14.8973 9.90863 14.8839 9.97508 14.8578 10.037C14.8318 10.0988 14.7936 10.1549 14.7456 10.2018C14.6976 10.2487 14.6407 10.2855 14.5782 10.3101C14.5157 10.3347 14.449 10.3466 14.3819 10.345H6.63908C6.57294 10.345 6.50743 10.332 6.44632 10.3067C6.38521 10.2814 6.3297 10.2443 6.28297 10.1975Z"
        fill="white"
      />
    </svg>
  );
  type RegisterProp = {
    amount: string;
    narration: string;
    bulk_list: any[];
    beneficiary: boolean;
    schedule: string;
    schedule_date: string;
    user_pin: string;
    provider: string;
    type: string;
    phone: string;
    recipients: any[];
  };

  const [details, setDetails] = useState<RegisterProp>({
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    provider: "",
    type: "",
    amount: "",
    phone: "",
    user_pin: "",
    schedule: "",
    schedule_date: "",
    recipients: [],
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };
  //   const providerList = [
  //     { name: "mtn", icon: mtnIcon },
  //     { name: "mobile", icon: mobileIcon },
  //     { name: "airtel", icon: airtelIcon },
  //     { name: "glo", icon: gloIcon },
  //   ];
  DetectNetwork(formatNumWithoutCommaNaira(`0${details?.phone}`), setSelectedProvider);
  type showModalProp = {
    pin: boolean;
    success: boolean;
    view: boolean;
  };
  const [showModal, setShowModal] = useState<showModalProp>({
    pin: false,
    success: false,
    view: false,
  });

  const handleStepClick = (param: number) => {
    if (param === 1) {
      navigate("/dashboard-bill-utilities");
    }
  };

  const handleBulkTransfer = () => {
    const checkList = details?.bulk_list?.some((chi) => chi?.phone === details?.phone);
    if (!checkList) {
      const obj = {
        phone: details?.phone,
        amount: details?.amount,
        // beneficiary: false,
        provider: selectedProvider,
      };
      const newList = [obj, ...details?.bulk_list];
      //   console.log(newList);
      setSelectedProvider("");
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          phone: "",
          amount: "",
        };
      });
    } else {
      console.log("jhghj");

      toast.error("Recepient already exist in bulk list");
    }
  };

  const handleRemoveFromList = (obj: any) => {
    if (details?.bulk_list?.length === 1) {
      setViewAll(false);
    }
    const newList = details?.bulk_list?.filter((chi) => chi?.phone !== obj?.phone);
    setDetails((prev) => {
      return {
        ...prev,
        bulk_list: newList,
      };
    });
  };

  //   const handleAddBeneficiary = (obj: any, val: string) => {
  //     if (details?.bulk_list?.length > 0) {
  //       const newList = details?.bulk_list?.map((chi) =>
  //         chi?.phone === obj?.phone ? { ...chi, beneficiary: val } : chi
  //       );
  //       setDetails((prev) => {
  //         return {
  //           ...prev,
  //           bulk_list: newList,
  //         };
  //       });
  //     } else {
  //       const newObj = { ...details, beneficiary: val };
  //       setDetails(newObj as any);
  //     }
  //   };

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* content wrapper start */}
        <ContainerWrapper
          activeStep={activeStep}
          stepList={stepList}
          dontShowHeader={(size.width as any) < 900}
          width={(size.width as any) < 900 && `100%`}
          back={activeStep === 3}
          onBack={() => setActiveStep(activeStep - 1)}
        >
          {/* send money index wrap start */}
          <div className="bill-utilities-airtime-index-wrap">
            {/* step two start */}
            {activeStep === 2 && (
              <>
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Proceed"
                  title="Cable TV"
                  text="Buy airtime for yourself and your loved ones."
                  onBack={() => {
                    activeStep === 2 && handleStepClick(1);
                  }}
                  disabled={
                    activeStep === 2
                      ? details?.bulk_list.length > 0
                        ? details?.bulk_list.length < 1
                        : !details?.amount || !details?.phone || !selectedProvider
                      : false
                  }
                  onBtnClick={() => setActiveStep(3)}
                >
                  <RavenInputField
                    labelSpanText={InfoIconSpan(true)}
                    placeholder="Select provider"
                    type="select"
                    label="Service Provider"
                    color="purple-light"
                    value={details?.phone}
                    onChange={handleChange}
                    name={`phone`}
                  />
                  {/* use my number box start */}
                  {/* <div
                    onClick={() => {
                      setDetails((prev) => {
                        return { ...prev, phone: `08162631944` };
                      });
                    }}
                    className="use-my-number-box fade-purple-bg grey-bg-two"
                  >
                    <p className="number">Use my number</p>
                  </div> */}
                  {/* use my number box end */}
                  <RavenInputField
                    // labelSpanText={AmountInfoIconSpan(false, 21457) as string}
                    placeholder="Enter DSTV smartcard number"
                    type="number"
                    label="Smartcard Number"
                    color="purple-dark"
                    value={details?.amount}
                    onChange={handleChange}
                    name={`amount`}
                  />
                  <RavenInputField
                    // labelSpanText={AmountInfoIconSpan(false, 21457) as string}
                    placeholder="Choose your preferred package"
                    type="select"
                    label="Select Package"
                    color="purple-dark"
                    value={details?.amount}
                    onChange={handleChange}
                    name={`amount`}
                  />
                  {/* add another text start */}
                  <div
                    onClick={() => {
                      selectedProvider &&
                        details?.amount &&
                        details?.phone &&
                        handleBulkTransfer();
                    }}
                    className={`add-another-box ${
                      selectedProvider &&
                      details?.amount &&
                      details?.phone &&
                      "add-another-box-active"
                    }`}
                  >
                    <figure className="img-box">{addIcon}</figure>
                    <p className="text">Recharge for another recipient</p>
                  </div>
                  {/* add another text end */}
                  {/* recepient box start */}
                  <div
                    className={`recepient-box ${
                      details?.bulk_list?.length > 0 && "recepient-box-show"
                    }`}
                  >
                    <div className="text-line">
                      <span></span>
                      <p className="text">Recipients</p>
                      <span></span>
                    </div>
                    <div className="view-total-box">
                      <p className="total">{`Total added (${details?.bulk_list?.length})`}</p>
                      <div className="view-wrap">
                        {/* view all box start */}

                        {/* view all box end */}
                        {viewAll ? (
                          <p
                            onClick={() => {
                              setViewAll(false);
                              details?.bulk_list?.length === 1 && setViewAll(false);
                            }}
                            className="text"
                          >
                            Close
                          </p>
                        ) : (
                          <p
                            onClick={() => {
                              setViewAll(true);
                            }}
                            className="text"
                          >
                            View all
                          </p>
                        )}
                      </div>
                    </div>
                    {/* end of view total box */}
                    {/* preview list start */}
                    {!viewAll && (
                      <div className="preview-box">
                        {details?.bulk_list?.slice(0, 2).map((chi, idx) => {
                          const { phone, amount, provider } = chi;
                          return (
                            <div className="box box-card grey-bg" key={idx}>
                              <div
                                onClick={() => {
                                  handleRemoveFromList(chi);
                                }}
                                className="cancel-box"
                              >
                                <FaTimes className="icon" />
                              </div>
                              <figure className="img-box">
                                <img
                                  src={
                                    provider === "mtn"
                                      ? mtnIcon
                                      : provider === "glo"
                                      ? gloIcon
                                      : provider === "airtel"
                                      ? airtelIcon
                                      : mobileIcon
                                  }
                                  alt=""
                                  className="img"
                                />
                              </figure>
                              <p className="name">{`+234 ${phone}`}</p>
                              <p className="text grey-white-color-white">{` ₦ ${amount}`}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* preview list end */}

                    {/* <div className="space-bottom"></div> */}
                  </div>
                  {/* recepient box end */}
                </StepperMainWrapper>
              </>
            )}
            {/* step two end */}

            {/* step three start */}
            {activeStep === 3 && (
              <>
                <NewPreviewDetailBox
                  bulkAmount={
                    details?.bulk_list?.length > 0
                      ? formatNumWithCommaNairaSymbol(
                          getAllAmount(details?.bulk_list) as any
                        )
                      : formatNumWithCommaNairaSymbol(
                          formatNumWithoutCommaNaira(details?.amount)
                        )
                  }
                  valueSchedule={fromDate}
                  onChangeSchedule={(param) => {
                    // console.log(param);
                    setFromDate(param);
                    setDetails((prev) => {
                      return { ...prev, schedule: param };
                    });
                  }}
                  onSubmit={() => {
                    setShowModal((prev) => {
                      return { ...prev, pin: true };
                    });
                  }}
                  list={
                    details.bulk_list.length < 2
                      ? [
                          {
                            phone: details?.phone,
                            provider: details?.provider,
                            amount: details?.amount,
                            narration: details?.narration,
                            beneficiary: false,
                          },
                        ]
                      : details?.bulk_list
                  }
                  onReset={() => setFromDate("")}
                />
              </>
            )}
            {/* step three end */}
          </div>
          {/* send money index wrap end */}
        </ContainerWrapper>
        {/* content wrapper end */}
      </DashboardLayoutIndex>
      {/* all recipient modal start */}
      <AllRecipientModal
        list={details?.bulk_list}
        onClose={() => {
          setViewAll(false);
        }}
        handleRemoveFromList={handleRemoveFromList}
        visible={viewAll}
      />
      {/* all recipient modal end */}
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin) => {
          //   handleSubmit(pin);
          actionCtx?.setConfettiVal();
          actionCtx?.playSound();
          setShowModal((prev) => {
            return { ...prev, success: true, pin: false };
          });
        }}
        value={details?.user_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        // loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        // onClose={() => {
        //   setShowModal((prev) => {
        //     return { ...prev, success: false };
        //   });
        // }}
        title="Transaction Successful."
        text={`You have successfully sent ₦150,000 to Ayeni Kehinde Timilehin`}
        btnText={`Go to dashboard`}
        onBtnClick={() => {
          navigate("/dashboard-overview");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default CableIndex;
