import "../../styles/reuseables/CircleProgress.css";
import { cn, generateArray } from "src/utils/helpers";

interface CircleProgressProps {
  steps: number;
  currentStep: number;
  className?: string;
  /** use rem or px */
  maxWidth?: string;
}

const CircleProgress = (props: CircleProgressProps) => {
  const { currentStep, steps, maxWidth = "10rem", className } = props;

  return (
    <div
      style={{ "--rs-circle-maxWidth": maxWidth } as React.CSSProperties}
      className="rs-circle-progress"
    >
      {generateArray(steps).map((n) => (
        <div
          key={n}
          className={cn(
            "rs-circle-progress__bar",
            n === currentStep && "rs-circle-progress__bar--active",
            currentStep > n && "rs-circle-progress__bar--passed",
            className
          )}
        />
      ))}
    </div>
  );
};

export default CircleProgress;
