import "../../../../styles/dashboard/settings/beneficiary/DeleteModal.css";
import { RavenButton, RavenModal } from "@ravenpay/raven-bank-ui";
import { dotsIllustration, trashRedAsset } from "./icons";

interface DeleteBeneficiaryModalProps {
  onClose: () => void;
  visible: boolean;
}

const DeleteBeneficiaryModal = (props: DeleteBeneficiaryModalProps) => {
  const { onClose, visible } = props;

  return (
    <RavenModal
      onClose={onClose}
      visble={visible}
      onBtnClick={() => {
        throw new Error("Not implemented");
      }}
      className="db-modal-wrap"
    >
      <div className="delete-beneficiary-modal">
        <div className="illustration">
          <span className="bg-illustration">{dotsIllustration}</span>
          <figure className="grey-bg delete-illustration">{trashRedAsset}</figure>
        </div>
        <p className="text-content">
          Are you sure you want to delete this account from your list of beneficiaries?
        </p>
      </div>
      <div className="delete-beneficiary-modal-footer grey-bg">
        <div className="btn-row">
          <button className="btn-reset btn-text" type="button">
            Cancel
          </button>
          <RavenButton color="error-dark">Proceed</RavenButton>
        </div>
      </div>
    </RavenModal>
  );
};

export default DeleteBeneficiaryModal;
