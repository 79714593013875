// import { RavenCheckBox, RavenInputField } from "@ravenpay/raven-bank-ui";
import React, {  useState } from "react";
// import VerificationLayout from "../../../layout/dashboard/VarificationLayout";
import "../../../styles/dashboard/verification/verification.css";
// import { useSelector, useDispatch } from "react-redux";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import VerificationSDK from "@ravenpay/verify-sdk";
// import { io } from "socket.io-client";
// import env from "../../../env";
// import CryptoJS from "crypto-js";
// import ActionContext from "../../../context/ActionContext";
// import { useLocation } from "react-router-dom";
// import { UseSocketFunction } from "../../../utils/helper/UseSocket";
import VerificationLayout from "src/layout/dashboard/VerificationLayout";
// import useDebounce from "src/utils/UseDebounce";
import ReuseVerified from "./ReusedVerifyBox";

const BvnVerification = () => {
//   const [showModal, setShowModal] = useState(false);
  //   const { business } = useSelector((state) => state.user);
  //   const { loading } = useSelector((state) => state.verifications);
//   const dispatch = useDispatch();
  // const { loadingBvn } = useSelector((state) => state.cards);
  // const { loading } = useSelector((state) => state.verifications);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [details, setDetails] = useState({
    bvn: "",
    nin: "",
    bvn_name: "",
  });
//   const actionCtx = useContext(ActionContext);
//   const location = useLocation();
  //initialize socket.io-client

  // const SERVER = process.env.REACT_APP_SOCKET_BASE_URL;
  // const SERVER = env.base_url;
  // const authenticated = localStorage.getItem("token");

  // const decryptTokenFunc = (tok) => {
  //   const encryptedToken = tok;
  //   const secretKey =
  //     "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

  //   // Encrypt the token
  //   const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  //   const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
  //   // console.log(tok);
  //   // console.log(decryptedToken);
  //   return decryptedToken;
  // };

  // const VerificationSdk = window.RvpSdk;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusCheck, setStatusCheck] = useState(1);
  //   useEffect(() => {
  //     if (location.pathname.includes("dashboard-verification-bvn")) {
  //       const socket = UseSocketFunction()
  //       socket.on("bvn_verified", () => {
  //         dispatch(getUser());
  //         // console.log("yes-----------------------");
  //       });
  //       return () => {
  //         socket.off("bvn_verified");
  //       };
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  //   const handleValidateBvn = async (param) => {
  //     // console.log(param);
  //     const data = await dispatch(validateBvn(param));
  //     if (data?.payload?.data?.status === "success") {
  //       const details = data?.payload?.data?.data;
  //       setDetails((prev) => {
  //         return {
  //           ...prev,
  //           bvn_name: `${details?.firstname} ${details?.lastname} `,
  //         };
  //       });
  //     } else {
  //       setDetails((prev) => {
  //         return { ...prev, bvn_name: "" };
  //       });
  //     }
  //   };

//   const debouncedSearchTerm = useDebounce(details?.bvn, 700);
  //   useEffect(() => {
  //     let isMount = true;

  //     if (
  //       isMount &&
  //       removeSpace(debouncedSearchTerm)?.length > 10 &&
  //       !details?.bvn_name
  //     ) {
  //       const obj = {
  //         bvn: details?.bvn,
  //       };
  //       handleValidateBvn(obj);
  //       //   console.log(obj);
  //     }

  //     if (isMount && debouncedSearchTerm?.length < 1) {
  //     }

  //     return () => {
  //       isMount = false;
  //     };
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [debouncedSearchTerm]);

  //   // const [dontShowBtn, setDonShowBtn] = useState(false);
  //   const [statusCheck, setStatusCheck] = useState(0);
  //   useEffect(() => {
  //     if (Object.keys(business).length > 0) {
  //       setStatusCheck(Number(business?.bvn));
  //     }
  //   }, [business]);

  //   const handleRetryFunction = async () => {
  //     const obj = {
  //       type: "bvn",
  //     };
  //     const data = await dispatch(changeVerificationStatus(obj));
  //     if (data?.payload?.data?.status === "success") {
  //       dispatch(getUser());
  //     }
  //   };
  //   // eslint-disable-next-line no-unused-vars
  //   const [initiateObj, setInitiateObj] = useState("");
  //   const config = {
  //     mobileCallBackURL:
  //       "https://businessbanking.getraventest.com/dashboard-verification-bvn?intent=mobile",
  //     reference: initiateObj,
  //   };

  //   useEffect(() => {
  //     const urlSearchParams = new URLSearchParams(window.location.search);
  //     const params = Object.fromEntries(urlSearchParams.entries());

  //     if (params.intent === "mobile") {
  //       VerificationSDK.init("bvn", config);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);
  const [loadingSdk, setLoadingSdk] = useState(false);
  //   const handleIntiateBvnVerification = async () => {
  //     const data = await dispatch(intiateBvnVerification());
  //     if (data?.payload?.data?.status === "success") {
  //       setLoadingSdk(true);
  //       // console.log(data);
  //       const url = data?.payload?.data?.data?.url;
  //       const extractRef = url?.split("reference=")[1];
  //       const refValue = extractRef?.replaceAll("%", "");
  //       // console.log(extractRef);
  //       // console.log(refValue);
  //       // setInitiateObj(refValue);
  //       if (refValue) {
  //         VerificationSDK.init("bvn", {
  //           mobileCallBackURL:
  //             "https://businessbanking.getraventest.com/dashboard-verification-bvn?intent=mobile",
  //           reference: refValue,
  //           style: {
  //             color: "purple-dark",
  //           },
  //         });
  //       }

  //       // window.open(url, "_blank").focus();
  //       // "https://signup.getmati.com/?merchantToken=61e6d21822dcd6001cffdcaa&flowId=646073d1040f5e001c9b3874&metadata=%7B%22reference%22%3A%220015409156188772%22%7D"
  //     }
  //   };

  VerificationSDK.onLoad = () => {
    setLoadingSdk(false);
  };

  VerificationSDK.onSupport = () => {
    // window.Intercom("show");
    window.fcWidget.open();
  };

  //   VerificationSDK.onVerify = (data) => {
  //     if (Object?.keys(data)?.length > 0) {
  //       dispatch(getUser());
  //     }
  //   };

  return (
    <>
      <VerificationLayout>
        <div className="verification-wrap-box">
          <div className="text-box-verify">
            <p className="big-title">Verify your BVN </p>
            <p className="small-title">
            We need this to verify your account
            </p>
          </div>
          {/* text box end */}
          {/* form start */}
          {statusCheck === 3 || statusCheck === 1 || statusCheck === 2 ? (
            <ReuseVerified
              //   dontShowBtn={actionCtx?.verificationBtn?.bvn}
              //   onRetryPending={handleRetryFunction}
              text={
                statusCheck === 1
                  ? `Your verification is pending, and you would be notified when, you have been verified`
                  : statusCheck === 2
                  ? `Bvn not verified, we couldn’t verify your bvn at this moment restart verification`
                  : `Your bvn is verified successfully, proceed to other verifications to complete your setup.`
              }
              pending={statusCheck === 1}
              failed={statusCheck === 2}
              //   onFailClick={() => setStatusCheck(0)}
            />
          ) : (
            <>
              <div className="verification-summary border-theme">
                {/* top info box start */}
                <div className="top-info-box border-theme-bottom">
                  <p className="text grey-white-color-white">
                    Verification Instructions
                  </p>
                </div>
                {/* top info box end */}
                {/* middle info box start */}
                <div className="middle-info-box border-theme-bottom">
                  <p className="text grey-white-color-white">
                    To verify your account first click on the{" "}
                    <span
                    // onClick={handleIntiateBvnVerification}
                    >
                      {" "}
                      {/* <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                        href={`https://signup.getmati.com/?merchantToken=61e6d21822dcd6001cffdcaa&flowId=646073d1040f5e001c9b3874&metadata={“email”:“${business?.email}”}`}
                      > */}
                      Begin verification
                      {/* </a>{" "} */}
                    </span>
                    button below, this would open a modal that would bring your
                    verification options, with the verification follow the
                    process that follow.{" "}
                  </p>
                </div>
                {/* middle info box end */}
                {/* bottom info box start */}
                {/* <div className="bottom-info-box">
                  <figure className="img-box">
                    <img src={youTubeIcon} alt="" className="img" />
                  </figure>
                  <p className="text ">Or watch how to verify</p>
                  <FaArrowRight className="icon" />
                </div> */}
                {/* bottom info box end */}
              </div>
              {/* btn start */}
              {/* <a
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                href={`https://signup.getmati.com/?merchantToken=61e6d21822dcd6001cffdcaa&flowId=646073d1040f5e001c9b3874&metadata={“email”:“${business?.email}”}`}
              > */}
              <RavenButton
                color="purple-dark"
                label="Begin Verification"
                style={{ width: `100%` }}
                onClick={() => {
                  //   actionCtx.setVericationBtn("bvn", true);
                  //   handleIntiateBvnVerification();
                  //   setTimeout(() => {
                  //     actionCtx.setVericationBtn("bvn", false);
                  //   }, 300000);
                }}
                loading={loadingSdk}
              />
              {/* </a> */}
              {/* btn end */}
            </>
          )}
          {/* form end */}
        </div>
      </VerificationLayout>
      {/* sign up modal start */}
      {/* <SuccessModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        title="Bvn Verification Successful."
        text={``}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal(false);
        }}
      /> */}
      {/* sign up modal end */}
    </>
  );
};

export default BvnVerification;
