import React from "react";
import defaultImg from "../../assets/raven-no-content-img.png"
import loadingGif from "../../assets/raven-loader.gif"
import "../../styles/dashboard/reusables/NoContentPage.css"

interface MyComponentProps {
  img?: any;
  title?: string;
  text?: string;
  loading?: boolean;
}

const defaultText =
  "You have not carried out any transactions Yet \n, once you do it would show up here.";

const NoContentPage: React.FC<MyComponentProps> = ({
  img,
  title,
  text,
  loading,
}) => {
  return (
    <div className="no-content-page-index">
      {loading ? (
        <>
          <div className="loading-wrap">
            <figure className="img-box">
              <img src={loadingGif} alt="" className="img" />
            </figure>
          </div>
        </>
      ) : (
        <div className="wrap-center">
          <div className="img-center-box fade-purple-bg grey-bg-two">
            <figure className="img-box">
              <img src={img || defaultImg} alt="" className="img" />
            </figure>
          </div>
          <div className="title-text-box">
            <p className="title">{title || "Nothing to see here for now..."}</p>
            {text ? (
              <>
                {" "}
                {text.split("\n").map((chi, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {" "}
                      <p className="text grey-white-color">{chi}</p>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <>
                {" "}
                {defaultText.split("\n").map((chi, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {" "}
                      <p className="text grey-white-color">{chi}</p>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NoContentPage;
