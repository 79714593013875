import AddressVerification from "src/pages/dashboard/verification/AddressVerification";
import BvnVerification from "src/pages/dashboard/verification/BvnVerification";
import NextOfKinVerification from "src/pages/dashboard/verification/NextOfKinVerification";
import NinVerification from "src/pages/dashboard/verification/NinVerification";
import WealthDeclarationVerification from "src/pages/dashboard/verification/WealthDeclaration";

export const verification_routes_group = [
  { path: "/dashboard-verification-bvn", element: BvnVerification },
  { path: "/dashboard-verification-nin", element: NinVerification },
  { path: "/dashboard-verification-nok", element: NextOfKinVerification },
  { path: "/dashboard-verification-address", element: AddressVerification },
  { path: "/dashboard-verification-wealth", element: WealthDeclarationVerification },
];
