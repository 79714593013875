import React, { useState } from "react";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import useWindowSize from "src/utils/UseWindowSize";
import "../../../../styles/dashboard/add-money/AddMoneyIndex.css";
// import SelectSpecialComponent from "src/components/reusables/SelectSpecialComponent";
import StepperMainWrapper from "src/components/reusables/StepperMainWrapper";
import { useNavigate } from "react-router-dom";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import { AmountInfoIconSpan } from "../../send-money/raven/AmountSpanInfo";
// import { FaCheck } from "react-icons/fa";
// import { trimLongString } from "src/helper";

const AddMoneyCardIndex = () => {
  const stepList = ["Select option", "Receive money"];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(2);
  const size = useWindowSize();
  const navigate = useNavigate();



  const handleStepClick = (param: number) => {
    if (param === 1) {
      navigate("/dashboard-add-money");
    }
  };

  type RegisterProp = {
    username: string;
    amount: string;
    narration: string;
    bulk_list: any[];
    beneficiary: boolean;
    schedule: string;
    transfer_pin: string;
    template_title: string;
    save_template: boolean;
    account_num: string;
    bank: any;
    name: string;
    schedule_time: string;
  };

  const [details, setDetails] = useState<RegisterProp>({
    username: "",
    amount: "",
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    account_num: "",
    bank: "",
    name: "",
    schedule_time: "",
    schedule: "",
    transfer_pin: "",
    template_title: "",
    save_template: false,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };


  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* content wrapper start */}
        <ContainerWrapper
          activeStep={activeStep}
          stepList={stepList}
          dontShowHeader={(size.width as any) < 900}
          width={(size.width as any) < 900 && `100%`}
        >
          <div className="add-money-index-wrap-ussd">
            {" "}
            {activeStep === 2 && (
              <>
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Proceed"
                  title="Top up with card"
                  text="Add money directly from your card."
                  onBack={() => {
                    activeStep === 2 && handleStepClick(1);
                  }}
                  disabled={!details?.amount}
                  onBtnClick={() => {
                    // setCopyContent("gfdfgh");
                  }}
                >
                  <form
                    autoComplete="off"
                    style={{
                      width: "100%",
                      position: "relative",
                      zIndex: 1000,
                    }}
                    action=""
                    className="wrap-input-box"
                  >
                    <RavenInputField
                      labelSpanText={
                        AmountInfoIconSpan(false, 214757) as string
                      }
                      placeholder="How much do you want to send?"
                      type="number"
                      label="Amount"
                      color="purple-dark"
                      thousandFormat
                      numberPrefix={`₦`}
                      value={details?.amount}
                      onChange={handleChange}
                      name={`amount`}
                    />
              
                  </form>
                </StepperMainWrapper>
              </>
            )}
          </div>
        </ContainerWrapper>
        {/* content wrapper end */}
      </DashboardLayoutIndex>
    </>
  );
};

export default AddMoneyCardIndex;
