export const checkIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      d="M10.5 3.99992L4.5 9.99992L1.75 7.24992L2.455 6.54492L4.5 8.58492L9.795 3.29492L10.5 3.99992Z"
      fill="white"
    />
  </svg>
);

export const ThemeIllustration = {
  light: (
    <svg
      width="206"
      height="150"
      viewBox="0 0 206 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="205.6" height="149.6" rx="8.8" fill="white" />
      <path
        d="M0 8.8C0 3.93989 3.93989 0 8.8 0H60V149.6H8.8C3.93989 149.6 0 145.66 0 140.8V8.8Z"
        fill="#F7F8F7"
      />
      <circle cx="9.59922" cy="12.0001" r="2.4" fill="#FF0F00" />
      <circle cx="12" cy="28.7998" r="4" fill="#EEEEEE" />
      <circle cx="84" cy="28.7998" r="8" fill="#EEEEEE" />
      <circle cx="80.0008" cy="66.4002" r="3.2" fill="#EEEEEE" />
      <circle cx="80.0008" cy="75.9998" r="3.2" fill="#EEEEEE" />
      <circle cx="80.0008" cy="84.8001" r="3.2" fill="#EEEEEE" />
      <circle cx="80.0008" cy="98.4002" r="3.2" fill="#EEEEEE" />
      <circle cx="80.0008" cy="107.2" r="3.2" fill="#EEEEEE" />
      <circle cx="80.0008" cy="120.8" r="3.2" fill="#EEEEEE" />
      <circle cx="80.0008" cy="129.6" r="3.2" fill="#EEEEEE" />
      <circle cx="23.6012" cy="12.0001" r="2.4" fill="#1ACE37" />
      <circle cx="16.8004" cy="12.0001" r="2.4" fill="#EA872D" />
      <rect x="20.8008" y="26.3999" width="31.2" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="7.19922" y="41.6001" width="40.8" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="76" y="41.6001" width="45.6" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="87.1992" y="64" width="72" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="87.1992" y="73.6001" width="101.6" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="87.1992" y="82.3999" width="92" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="87.1992" y="96" width="92" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="87.1992" y="104.8" width="92" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="87.1992" y="118.4" width="101.6" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="87.1992" y="127.2" width="92" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="7.19922" y="52" width="27.2" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="7.19922" y="64" width="27.2" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="7.19922" y="95.2002" width="22.4" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="7.19922" y="72.7998" width="44.8" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect x="7.19922" y="81.6001" width="40.8" height="4.8" rx="2.4" fill="#EEEEEE" />
      <rect
        x="1"
        y="1"
        width="203.6"
        height="147.6"
        rx="7.8"
        stroke="#eee"
        stroke-width="1"
        className="bordered-icon"
      />
    </svg>
  ),
  dark: (
    <svg
      width="207"
      height="150"
      viewBox="0 0 207 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.799805" width="205.6" height="149.6" rx="8.8" fill="#020202" />
      <path
        d="M0.799805 8.8C0.799805 3.93989 4.7397 0 9.5998 0H60.7998V149.6H9.5998C4.7397 149.6 0.799805 145.66 0.799805 140.8V8.8Z"
        fill="#1B1B1B"
      />
      <circle cx="10.399" cy="11.9996" r="2.4" fill="#FF0F00" />
      <circle opacity="0.1" cx="12.7998" cy="28.7998" r="4" fill="#D9D9D9" />
      <circle opacity="0.1" cx="84.7998" cy="28.7998" r="8" fill="#D9D9D9" />
      <circle opacity="0.1" cx="80.8006" cy="66.3997" r="3.2" fill="#D9D9D9" />
      <circle opacity="0.1" cx="80.8006" cy="75.9998" r="3.2" fill="#D9D9D9" />
      <circle opacity="0.1" cx="80.8006" cy="84.7996" r="3.2" fill="#D9D9D9" />
      <circle opacity="0.1" cx="80.8006" cy="98.3997" r="3.2" fill="#D9D9D9" />
      <circle opacity="0.1" cx="80.8006" cy="107.2" r="3.2" fill="#D9D9D9" />
      <circle opacity="0.1" cx="80.8006" cy="120.8" r="3.2" fill="#D9D9D9" />
      <circle opacity="0.1" cx="80.8006" cy="129.6" r="3.2" fill="#D9D9D9" />
      <circle cx="17.6002" cy="11.9996" r="2.4" fill="#EA872D" />
      <circle cx="24.7994" cy="11.9996" r="2.4" fill="#1ACE37" />
      <rect
        opacity="0.1"
        x="21.6006"
        y="26.3999"
        width="31.2"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="7.99902"
        y="41.5996"
        width="40.8"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="76.7998"
        y="41.5996"
        width="45.6"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="87.999"
        y="64"
        width="72"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="87.999"
        y="73.5996"
        width="101.6"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="87.999"
        y="82.3999"
        width="92"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="87.999"
        y="96"
        width="92"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="87.999"
        y="104.8"
        width="92"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="87.999"
        y="118.4"
        width="101.6"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="87.999"
        y="127.2"
        width="92"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="7.99902"
        y="52"
        width="27.2"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="7.99902"
        y="64"
        width="27.2"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="7.99902"
        y="95.1997"
        width="22.4"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="7.99902"
        y="72.7998"
        width="44.8"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        opacity="0.1"
        x="7.99902"
        y="81.5996"
        width="40.8"
        height="4.8"
        rx="2.4"
        fill="#D9D9D9"
      />
      <rect
        x="1.6002"
        y="0.4"
        width="204.8"
        height="148.8"
        rx="8.4"
        stroke="#020202"
        stroke-width="0.8"
      />
    </svg>
  ),
  system: (
    <svg
      width="206"
      height="149"
      viewBox="0 0 206 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1169_24720)">
        <rect width="205.6" height="149" rx="8.8" fill="#020202" />
        <path
          d="M0 8.8C0 3.93989 3.93989 0 8.8 0H60V149H8.8C3.93989 149 0 145.06 0 140.2V8.8Z"
          fill="#1B1B1B"
        />
        <ellipse cx="9.59922" cy="11.9514" rx="2.4" ry="2.39037" fill="#FF0F00" />
        <ellipse opacity="0.1" cx="12" cy="28.6842" rx="4" ry="3.98396" fill="#D9D9D9" />
        <ellipse opacity="0.1" cx="84" cy="28.6842" rx="8" ry="7.96791" fill="#D9D9D9" />
        <ellipse
          opacity="0.1"
          cx="80.0008"
          cy="66.1335"
          rx="3.2"
          ry="3.18717"
          fill="#D9D9D9"
        />
        <ellipse
          opacity="0.1"
          cx="80.0008"
          cy="75.695"
          rx="3.2"
          ry="3.18717"
          fill="#D9D9D9"
        />
        <ellipse
          opacity="0.1"
          cx="80.0008"
          cy="84.4596"
          rx="3.2"
          ry="3.18717"
          fill="#D9D9D9"
        />
        <ellipse
          opacity="0.1"
          cx="80.0008"
          cy="98.005"
          rx="3.2"
          ry="3.18717"
          fill="#D9D9D9"
        />
        <ellipse
          opacity="0.1"
          cx="80.0008"
          cy="106.77"
          rx="3.2"
          ry="3.18717"
          fill="#D9D9D9"
        />
        <ellipse
          opacity="0.1"
          cx="80.0008"
          cy="120.315"
          rx="3.2"
          ry="3.18717"
          fill="#D9D9D9"
        />
        <ellipse
          opacity="0.1"
          cx="80.0008"
          cy="129.08"
          rx="3.2"
          ry="3.18717"
          fill="#D9D9D9"
        />
        <ellipse cx="16.8004" cy="11.9514" rx="2.4" ry="2.39037" fill="#EA872D" />
        <ellipse cx="23.9996" cy="11.9514" rx="2.4" ry="2.39037" fill="#1ACE37" />
        <rect
          opacity="0.1"
          x="20.8008"
          y="26.2939"
          width="31.2"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="7.19922"
          y="41.4326"
          width="40.8"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="76"
          y="41.4326"
          width="45.6"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="87.1992"
          y="63.7432"
          width="72"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="87.1992"
          y="73.3042"
          width="101.6"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="87.1992"
          y="82.0693"
          width="92"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="87.1992"
          y="95.6147"
          width="92"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="87.1992"
          y="104.379"
          width="92"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="87.1992"
          y="117.925"
          width="101.6"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="87.1992"
          y="126.689"
          width="92"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="7.19922"
          y="51.7915"
          width="27.2"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="7.19922"
          y="63.7432"
          width="27.2"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="7.19922"
          y="94.8179"
          width="22.4"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="7.19922"
          y="72.5078"
          width="44.8"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
        <rect
          opacity="0.1"
          x="7.19922"
          y="81.2725"
          width="40.8"
          height="4.78075"
          rx="2.39037"
          fill="#D9D9D9"
        />
      </g>
      <g clip-path="url(#clip1_1169_24720)">
        <rect width="205.6" height="149" rx="8.8" fill="white" />
        <rect
          x="76"
          y="41.4331"
          width="45.6"
          height="4.78075"
          rx="2.39037"
          fill="#EEEEEE"
        />
        <rect
          x="87.1992"
          y="63.7432"
          width="72"
          height="4.78075"
          rx="2.39037"
          fill="#EEEEEE"
        />
        <rect
          x="87.1992"
          y="73.3047"
          width="101.6"
          height="4.78075"
          rx="2.39037"
          fill="#EEEEEE"
        />
        <rect
          x="87.1992"
          y="82.0693"
          width="92"
          height="4.78075"
          rx="2.39037"
          fill="#EEEEEE"
        />
        <rect
          x="87.1992"
          y="95.6147"
          width="92"
          height="4.78075"
          rx="2.39037"
          fill="#EEEEEE"
        />
        <rect
          x="87.1992"
          y="104.379"
          width="92"
          height="4.78075"
          rx="2.39037"
          fill="#EEEEEE"
        />
        <rect
          x="87.1992"
          y="117.925"
          width="101.6"
          height="4.78075"
          rx="2.39037"
          fill="#EEEEEE"
        />
        <rect
          x="87.1992"
          y="126.69"
          width="92"
          height="4.78075"
          rx="2.39037"
          fill="#EEEEEE"
        />
      </g>
      <path
        d="M103.5 0.5H198C202.142 0.5 205.5 3.85786 205.5 8V141C205.5 145.142 202.142 148.5 198 148.5H103.5V0.5Z"
        stroke="#E3E3E3"
      />
      <defs>
        <clipPath id="clip0_1169_24720">
          <rect width="103" height="149" fill="white" />
        </clipPath>
        <clipPath id="clip1_1169_24720">
          <path
            d="M103 0H198C202.418 0 206 3.58172 206 8V141C206 145.418 202.418 149 198 149H103V0Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  ),
};

export const PreferenceIcons = {
  Alarm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M13.0383 15.5126C13.3957 16.8462 12.6042 18.2171 11.2705 18.5744C9.93687 18.9318 8.56603 18.1403 8.20867 16.8067M1.71407 10.178C1.38949 9.01285 1.72703 7.75315 2.59069 6.90639M10.122 5.28428C10.3755 4.8282 10.459 4.27695 10.3133 3.73312C10.0155 2.62173 8.8731 1.96219 7.76171 2.25998C6.65032 2.55778 5.99078 3.70015 6.28857 4.81153C6.43429 5.35536 6.78223 5.79101 7.22982 6.05923M16.9326 6.10024C16.6311 4.9289 15.7089 4.00674 14.5376 3.70526M14.5756 8.37131C14.2781 7.2612 13.484 6.3329 12.3679 5.79062C11.2519 5.24835 9.90529 5.13651 8.62439 5.47973C7.34349 5.82294 6.23322 6.59309 5.53782 7.62075C4.84242 8.64841 4.61886 9.84939 4.91631 10.9595C5.40845 12.7962 5.31289 14.2615 5.0021 15.375C4.64787 16.644 4.47076 17.2785 4.51861 17.406C4.57335 17.5517 4.61295 17.5917 4.75815 17.648C4.88506 17.6972 5.4182 17.5543 6.48448 17.2686L16.372 14.6192C17.4383 14.3335 17.9715 14.1907 18.0568 14.0846C18.1544 13.9633 18.1687 13.9089 18.1432 13.7553C18.1209 13.621 17.6503 13.16 16.709 12.2381C15.8831 11.4292 15.0677 10.208 14.5756 8.37131Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  VolumeMax: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M16.4565 4.66644C17.6377 6.30831 18.3333 8.32281 18.3333 10.4998C18.3333 12.6767 17.6377 14.6912 16.4565 16.3331M13.1211 7.16644C13.7801 8.1113 14.1666 9.2604 14.1666 10.4998C14.1666 11.7392 13.7801 12.8882 13.1211 13.8331M8.02856 4.97118L5.39048 7.60925C5.24636 7.75338 5.17429 7.82544 5.0902 7.87698C5.01564 7.92267 4.93435 7.95634 4.84932 7.97675C4.75341 7.99978 4.6515 7.99978 4.44767 7.99978H2.99996C2.53325 7.99978 2.29989 7.99978 2.12163 8.0906C1.96483 8.1705 1.83735 8.29798 1.75745 8.45478C1.66663 8.63304 1.66663 8.8664 1.66663 9.33311V11.6664C1.66663 12.1332 1.66663 12.3665 1.75745 12.5448C1.83735 12.7016 1.96483 12.8291 2.12163 12.9089C2.29989 12.9998 2.53325 12.9998 2.99996 12.9998H4.44767C4.6515 12.9998 4.75341 12.9998 4.84932 13.0228C4.93435 13.0432 5.01564 13.0769 5.0902 13.1226C5.17429 13.1741 5.24636 13.2462 5.39048 13.3903L8.02856 16.0284C8.38554 16.3854 8.56403 16.5638 8.71727 16.5759C8.85023 16.5864 8.98017 16.5325 9.0668 16.4311C9.16663 16.3142 9.16663 16.0618 9.16663 15.557V5.44259C9.16663 4.93774 9.16663 4.68532 9.0668 4.56843C8.98017 4.46701 8.85023 4.41319 8.71727 4.42365C8.56403 4.43571 8.38554 4.6142 8.02856 4.97118Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Palette: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M1.66663 10.4998C1.66663 15.1022 5.39759 18.8332 9.99996 18.8332C11.3807 18.8332 12.5 17.7139 12.5 16.3332V15.9165C12.5 15.5295 12.5 15.336 12.5213 15.1735C12.669 14.0517 13.5518 13.1689 14.6736 13.0212C14.8361 12.9998 15.0296 12.9998 15.4166 12.9998H15.8333C17.214 12.9998 18.3333 11.8805 18.3333 10.4998C18.3333 5.89746 14.6023 2.1665 9.99996 2.1665C5.39759 2.1665 1.66663 5.89746 1.66663 10.4998Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83329 11.3332C6.29353 11.3332 6.66663 10.9601 6.66663 10.4998C6.66663 10.0396 6.29353 9.6665 5.83329 9.6665C5.37306 9.6665 4.99996 10.0396 4.99996 10.4998C4.99996 10.9601 5.37306 11.3332 5.83329 11.3332Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 7.99984C13.7935 7.99984 14.1666 7.62674 14.1666 7.1665C14.1666 6.70627 13.7935 6.33317 13.3333 6.33317C12.8731 6.33317 12.5 6.70627 12.5 7.1665C12.5 7.62674 12.8731 7.99984 13.3333 7.99984Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33329 7.1665C8.79353 7.1665 9.16663 6.79341 9.16663 6.33317C9.16663 5.87293 8.79353 5.49984 8.33329 5.49984C7.87306 5.49984 7.49996 5.87293 7.49996 6.33317C7.49996 6.79341 7.87306 7.1665 8.33329 7.1665Z"
        stroke="var(--primary-purple)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
