import React, { useState } from "react";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import useWindowSize from "src/utils/UseWindowSize";
import "../../../styles/dashboard/add-money/AddMoneyIndex.css";
import SelectSpecialComponent from "src/components/reusables/SelectSpecialComponent";

const AddMoneyIndex = () => {
  const stepList = ["Select option", "Receive money"];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(1);
  const size = useWindowSize();

  const usdLogo = (
    <svg
      className="img"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4357 8L10.0355 10H7.5653L7.9654 8H10.4357ZM3.25 0C1.45507 0 0 1.45507 0 3.25V14.75C0 16.5449 1.45507 18 3.25 18H14.75C16.5449 18 18 16.5449 18 14.75V3.25C18 1.45507 16.5449 0 14.75 0H3.25ZM7.8979 4.51472C8.304 4.59598 8.5674 4.99112 8.4861 5.39728L8.2655 6.5H10.7358L11.0153 5.10301C11.0966 4.69684 11.4917 4.43346 11.8979 4.51472C12.304 4.59598 12.5674 4.99112 12.4861 5.39728L12.2655 6.5H13.25C13.6642 6.5 14 6.83579 14 7.25C14 7.6642 13.6642 8 13.25 8H11.9654L11.5653 10H12.75C13.1642 10 13.5 10.3358 13.5 10.75C13.5 11.1642 13.1642 11.5 12.75 11.5H11.2652L10.9856 12.8975C10.9043 13.3037 10.5092 13.567 10.103 13.4858C9.6968 13.4045 9.4335 13.0094 9.5147 12.6032L9.7354 11.5H7.2652L6.98557 12.8975C6.90431 13.3037 6.50917 13.567 6.10301 13.4858C5.69684 13.4045 5.43346 13.0094 5.51472 12.6032L5.73544 11.5H4.75C4.33579 11.5 4 11.1642 4 10.75C4 10.3358 4.33579 10 4.75 10H6.03555L6.43569 8H5.25C4.83579 8 4.5 7.6642 4.5 7.25C4.5 6.83579 4.83579 6.5 5.25 6.5H6.73579L7.0153 5.10301C7.0966 4.69684 7.4917 4.43346 7.8979 4.51472Z"
        fill="var(--primary-purple)"
      />
    </svg>
  );
 
  const bankIcon = (
    <svg
      className="img"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4806 12.122C23.4802 11.8653 23.4193 11.6124 23.3029 11.3837C23.1865 11.155 23.0178 10.9569 22.8105 10.8056L15.2614 5.29463C14.9862 5.094 14.6552 4.98442 14.3146 4.98121C13.9741 4.97801 13.641 5.08133 13.3621 5.27674L5.47893 10.8056C5.26427 10.9561 5.08899 11.156 4.9679 11.3885C4.84681 11.621 4.78346 11.8793 4.7832 12.1414C4.78315 12.2275 4.80006 12.3127 4.83297 12.3923C4.86588 12.4718 4.91414 12.5441 4.97499 12.605C5.03585 12.6659 5.1081 12.7142 5.18763 12.7472C5.26715 12.7801 5.3524 12.7971 5.43848 12.7971H22.8059C22.8945 12.7971 22.9823 12.7796 23.0642 12.7457C23.146 12.7117 23.2204 12.662 23.2831 12.5993C23.3458 12.5366 23.3955 12.4622 23.4293 12.3803C23.4632 12.2984 23.4806 12.2106 23.4806 12.122Z"
        fill="var(--primary-purple)"
      />
      <path
        d="M4.7832 21.6404V22.4959C4.7832 22.9291 4.95529 23.3446 5.2616 23.6509C5.5679 23.9572 5.98335 24.1293 6.41654 24.1293H21.8488C22.282 24.1293 22.6974 23.9572 23.0038 23.6509C23.3101 23.3446 23.4821 22.9291 23.4821 22.4959V21.6404H4.7832Z"
        fill="var(--primary-purple)"
      />
      <path
        d="M14.4991 13.4337H13.7664C13.2237 13.4337 12.7837 13.8736 12.7837 14.4164V19.9888C12.7837 20.5315 13.2237 20.9715 13.7664 20.9715H14.4991C15.0418 20.9715 15.4818 20.5315 15.4818 19.9888V14.4164C15.4818 13.8736 15.0418 13.4337 14.4991 13.4337Z"
        fill="var(--icon-fade-bg)"
      />
      <path
        d="M19.8594 13.4337H19.1268C18.584 13.4337 18.144 13.8736 18.144 14.4164V19.9888C18.144 20.5315 18.584 20.9715 19.1268 20.9715H19.8594C20.4022 20.9715 20.8422 20.5315 20.8422 19.9888V14.4164C20.8422 13.8736 20.4022 13.4337 19.8594 13.4337Z"
        fill="var(--icon-fade-bg)"
      />
      <path
        d="M9.13873 13.4337H8.40606C7.86332 13.4337 7.42334 13.8736 7.42334 14.4164V19.9888C7.42334 20.5315 7.86332 20.9715 8.40606 20.9715H9.13873C9.68147 20.9715 10.1215 20.5315 10.1215 19.9888V14.4164C10.1215 13.8736 9.68147 13.4337 9.13873 13.4337Z"
        fill="var(--icon-fade-bg)"
      />
    </svg>
  );

  const qrCodeLogo = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.44444 2.66667H2.66667V4.44444H4.44444V2.66667ZM0 2.22222C0 0.994924 0.994924 0 2.22222 0H4.88889C6.11619 0 7.11111 0.994924 7.11111 2.22222V4.88889C7.11111 6.11618 6.11619 7.11111 4.88889 7.11111H2.22222C0.994924 7.11111 0 6.11618 0 4.88889V2.22222ZM2.22222 1.77778C1.97676 1.77778 1.77778 1.97676 1.77778 2.22222V4.88889C1.77778 5.13434 1.97676 5.33333 2.22222 5.33333H4.88889C5.13435 5.33333 5.33333 5.13434 5.33333 4.88889V2.22222C5.33333 1.97676 5.13435 1.77778 4.88889 1.77778H2.22222ZM2.66667 11.5556H4.44444V13.3333H2.66667V11.5556ZM0 11.1111C0 9.88382 0.994924 8.88888 2.22222 8.88888H4.88889C6.11619 8.88888 7.11111 9.88382 7.11111 11.1111V13.7778C7.11111 15.0051 6.11619 16 4.88889 16H2.22222C0.994924 16 0 15.0051 0 13.7778V11.1111ZM2.22222 10.6667C1.97676 10.6667 1.77778 10.8657 1.77778 11.1111V13.7778C1.77778 14.0232 1.97676 14.2222 2.22222 14.2222H4.88889C5.13435 14.2222 5.33333 14.0232 5.33333 13.7778V11.1111C5.33333 10.8657 5.13435 10.6667 4.88889 10.6667H2.22222ZM13.3333 2.66667H11.5556V4.44444H13.3333V2.66667ZM11.1111 0C9.88382 0 8.88889 0.994924 8.88889 2.22222V4.88889C8.88889 6.11618 9.88382 7.11111 11.1111 7.11111H13.7778C15.0051 7.11111 16 6.11618 16 4.88889V2.22222C16 0.994924 15.0051 0 13.7778 0H11.1111ZM10.6667 2.22222C10.6667 1.97676 10.8657 1.77778 11.1111 1.77778H13.7778C14.0232 1.77778 14.2222 1.97676 14.2222 2.22222V4.88889C14.2222 5.13434 14.0232 5.33333 13.7778 5.33333H11.1111C10.8657 5.33333 10.6667 5.13434 10.6667 4.88889V2.22222ZM8.88889 8.88888H11.3333V11.3333H8.88889V8.88888ZM13.5556 11.3333H11.3333V13.5556H8.88889V16H11.3333V13.5556H13.5556V16H16V13.5556H13.5556V11.3333ZM13.5556 11.3333V8.88888H16V11.3333H13.5556Z"
        fill="var(--primary-purple)"
      />
      <path
        d="M8.88867 8.88892H11.3331V11.3334H8.88867V8.88892ZM13.5553 11.3334H11.3331V13.5556H8.88867V16H11.3331V13.5556H13.5553V16H15.9998V13.5556H13.5553V11.3334ZM13.5553 11.3334V8.88892H15.9998V11.3334H13.5553Z"
        fill="var(--icon-fade-bg)"
      />
    </svg>
  );

  const cardIcon = (
    <svg
      className="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 18.125C1.5 18.8212 1.77656 19.4889 2.26884 19.9812C2.76113 20.4734 3.42881 20.75 4.125 20.75H19.875C20.5712 20.75 21.2389 20.4734 21.7312 19.9812C22.2234 19.4889 22.5 18.8212 22.5 18.125V10.9062H1.5V18.125ZM4.59375 14.5625C4.59375 14.1895 4.74191 13.8319 5.00563 13.5681C5.26935 13.3044 5.62704 13.1562 6 13.1562H8.25C8.62296 13.1562 8.98065 13.3044 9.24437 13.5681C9.50809 13.8319 9.65625 14.1895 9.65625 14.5625V15.5C9.65625 15.873 9.50809 16.2306 9.24437 16.4944C8.98065 16.7581 8.62296 16.9062 8.25 16.9062H6C5.62704 16.9062 5.26935 16.7581 5.00563 16.4944C4.74191 16.2306 4.59375 15.873 4.59375 15.5V14.5625ZM19.875 4.25H4.125C3.42881 4.25 2.76113 4.52656 2.26884 5.01884C1.77656 5.51113 1.5 6.17881 1.5 6.875V8.09375H22.5V6.875C22.5 6.17881 22.2234 5.51113 21.7312 5.01884C21.2389 4.52656 20.5712 4.25 19.875 4.25Z"
        fill="var(--primary-purple)"
      />
    </svg>
  );

  const selectList = [
    {
      name: "Bank transfer",
      text: "Add money to your account using USSD.",
      //   link: "/dashboard-send-money-raven",
      logo: bankIcon,
      details: {
        name: "Timi Ayeni",
        bank: "Raven Bank",
        account_num: "9403848849",
        copy_value: "9403848849"
      }
    },
    {
      name: "USSD",
      text: "Add money to your account using USSD.",
      link: "/dashboard-add-money-ussd",
      logo: usdLogo,
      smallIcon: true,
    },
    {
      name: "Top-up with card",
      text: "Add money directly from your card.",
      link: "/dashboard-add-money-card",
      logo: cardIcon,
    },
    {
      name: "QR Code",
      text: "Show your QR Code to any Raven user.",
      link: "/dashboard-add-money-scan",
      logo: qrCodeLogo,
      smallIcon: true,
    },
  ];

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* content wrapper start */}
        <ContainerWrapper
          activeStep={activeStep}
          stepList={stepList}
          dontShowHeader={(size.width as any) < 900}
          width={(size.width as any) < 900 && `100%`}
        >
          {/* send money index wrap start */}
          <div className="add-money-index-wrap">
            <SelectSpecialComponent selectList={selectList} />
          </div>
          {/* send money index wrap end */}
        </ContainerWrapper>
        {/* content wrapper end */}
      </DashboardLayoutIndex>
    </>
  );
};

export default AddMoneyIndex;
