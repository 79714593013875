const env = {
	base_url: 'https://integrations.getravenbank.com/v1/',
	bankbox_api: 'https://postest.getravenpos.com',
	// process.env.NODE_ENV === 'production'
	// 	? process.env.REACT_APP_BASE_URL
	// 	: process.env.REACT_APP_DEVELOPMENT_BASE_URL,
};

export default env;

// 'https://integrations.getravenbank.com/v1/'
 