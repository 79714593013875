import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackToDashboard from "src/components/fragments/BackToDashboard";
import ContainerWrapper from "src/components/reusables/ContainerWrapper";
import StepperMainWrapper from "src/components/reusables/StepperMainWrapper";
import DashboardLayoutIndex from "src/layout/dashboard/LayoutIndex";
import "../../../styles/dashboard/cards/CardIndexPage.css";
import blackCard from "../../../assets/test-card-black.png";
import purpleCard from "../../../assets/test-card-purple.png";
import goldCard from "../../../assets/test-card-gold.png";
import greenCard from "../../../assets/test-card-green.png";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import { AmountInfoIconSpan } from "../send-money/raven/AmountSpanInfo";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import { formatNumWithCommaNairaSymbol } from "src/helper";
import TransactionPinModal from "src/components/fragments/TransactionPinModal";
import SuccessModal from "src/components/fragments/TransactionSuccessModal";
import ActionContext from "src/context/ActionContext";
// import useWindowSize from "src/utils/UseWindowSize";

const CreateCardIndex = () => {
  const navigate = useNavigate();
  const actionCtx = useContext(ActionContext);
  // const size = useWindowSize();
  const stepList = ["Create a card", "Setup your card", "Confirm card request"];
  const [activeStep, setActiveStep] = useState(1);
  type showModalProp = {
    pin: boolean;
    success: boolean;
  };
  const [showModal, setShowModal] = useState<showModalProp>({
    pin: false,
    success: false,
  });
  type RegisterProp = {
    number: number;
    position: number;
  };
  const [cardPosition, setCardPosition] = useState<RegisterProp>({
    number: 0,
    position: 0,
  });
  const rightArrow = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M5.93945 13.2802L10.2861 8.93355C10.7995 8.42021 10.7995 7.58021 10.2861 7.06688L5.93945 2.72021"
        stroke="#344054"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const leftArrow = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M9.99925 13.2802L5.65258 8.93355C5.13924 8.42021 5.13924 7.58021 5.65258 7.06688L9.99925 2.72021"
        stroke="#D0D5DD"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const designList = [
    { name: "black", img: blackCard },
    { name: "gold", img: goldCard },
    { name: "green", img: greenCard },
    { name: "purple", img: purpleCard },
  ];

  const handleScroll = (param: string) => {
    // console.log("start");
    if (param === "next") {
      if (cardPosition.number === designList.length) {
        // console.log("first");
        setCardPosition((prev) => {
          return { ...prev, position: 0, number: 0 };
        });
        // console.log(cardPosition);
      } else {
        setCardPosition((prev) => {
          return {
            ...prev,
            position: cardPosition.position - 100,
            number: cardPosition.number + 1,
          };
        });
      }
    }

    if (param === "prev") {
      if (cardPosition.number === designList.length) {
        // console.log("first");
        setCardPosition((prev) => {
          return { ...prev, position: 0, number: 0 };
        });
        // console.log(cardPosition);
      } else {
        setCardPosition((prev) => {
          return {
            ...prev,
            position: cardPosition.position + 100,
            number: cardPosition.number - 1,
          };
        });
      }
    }
  };
  const [cardSelectedType, setCardSelectedType] = useState("USD Card (₦)");
  const cardType = ["USD Card (₦)", "Naira Card (₦)"];

  type DetailProps = {
    title: string;
    amount: string;
    transfer_pin: string;
    design: string;
  };
  const [details, setDetails] = useState<DetailProps>({
    title: "",
    amount: "",
    transfer_pin: "",
    design: "black",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  return (
    <>
      <DashboardLayoutIndex>
        {/* back to dashboard start */}
        <BackToDashboard />
        {/* back to dashboard end */}
        {/* content wrapper start */}
        <ContainerWrapper
          stepList={stepList}
          activeStep={activeStep}
          width={`100%`}
          back={true}
          onBack={() => {
            if (activeStep > 1) {
              setActiveStep(activeStep - 1);
            }
            if(activeStep === 1){
              navigate("/dashboard-cards");
            }
          }}
        >
          {/* card index wrap start */}
          <div className="cards-page-create-index-wrap">
            {/* step one start ---------- */}
            {activeStep === 1 && (
              <>
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Create card"
                  // onBack={() => {
                  //   navigate("/dashboard-cards");
                  // }}
                  
                  //   disabled={!details?.amount}
                  onBtnClick={() => {
                    setActiveStep(2);
                  }}
                >
                  {/* title next prev box start */}
                  <div className="title-next-prev-box">
                    <p className="title">Select card theme</p>
                    {/* next prrev box start */}
                    <div className="next-prev-box">
                      <div
                        onClick={() => {
                          cardPosition.number > 0 && handleScroll("prev");
                        }}
                        className="icon-wrap border-theme"
                      >
                        <figure className="img-box">{leftArrow}</figure>
                      </div>
                      <div
                        onClick={() => {
                          cardPosition.number < designList.length && handleScroll("next");
                        }}
                        className="icon-wrap border-theme"
                      >
                        <figure className="img-box">{rightArrow}</figure>
                      </div>
                    </div>
                    {/* next prrev box end */}
                  </div>
                  {/* title next prev box end */}
                  {/* switch select wrap box start */}
                  <div className="switch-select-wrap-box">
                    {/* caurousel wrap start */}
                    {/* <div className="caurousel-wrap-inner">

                    </div> */}
                    {/* caurousel wrap end */}
                    {/* switch caurousel wrap start */}
                    <div
                      style={{
                        gridTemplateColumns: `repeat(${designList?.length}, calc(100% - 2.5rem))`,
                      }}
                      className="switch-caurousel-wrap"
                    >
                      {designList?.map((chi, idx) => {
                        return (
                          <div
                            key={idx}
                            style={{
                              transform: `translateX(${cardPosition.position}%)`,
                            }}
                            onClick={() => {
                              setDetails((prev) => {
                                return { ...prev, design: chi?.name };
                              });
                            }}
                            className={`card-design-box ${
                              cardPosition?.number === idx && "card-design-box-select"
                            }`}
                          >
                            <figure className="img-box">
                              <img className="img" src={chi?.img} alt="" />
                            </figure>
                            {details?.design === chi?.name && (
                              <div className="check-wrap">
                                <figure className="img-box">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="img"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M6.26701 3.45496C6.91008 3.40364 7.52057 3.15077 8.01158 2.73234C9.15738 1.75589 10.8426 1.75589 11.9884 2.73234C12.4794 3.15077 13.0899 3.40364 13.733 3.45496C15.2336 3.57471 16.4253 4.76636 16.545 6.26701C16.5964 6.91008 16.8492 7.52057 17.2677 8.01158C18.2441 9.15738 18.2441 10.8426 17.2677 11.9884C16.8492 12.4794 16.5964 13.0899 16.545 13.733C16.4253 15.2336 15.2336 16.4253 13.733 16.545C13.0899 16.5964 12.4794 16.8492 11.9884 17.2677C10.8426 18.2441 9.15738 18.2441 8.01158 17.2677C7.52057 16.8492 6.91008 16.5964 6.26701 16.545C4.76636 16.4253 3.57471 15.2336 3.45496 13.733C3.40364 13.0899 3.15077 12.4794 2.73234 11.9884C1.75589 10.8426 1.75589 9.15738 2.73234 8.01158C3.15077 7.52057 3.40364 6.91008 3.45496 6.26701C3.57471 4.76636 4.76636 3.57471 6.26701 3.45496ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                      fill="#E3E3E3"
                                    />
                                  </svg>
                                </figure>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {/* switch caurousel wrap end */}
                    {/* switch index dots start */}
                    <div className="switch-index-dots">
                      {designList?.map((chi, idx) => {
                        return (
                          <div
                            key={idx}
                            className={`dots fade-purple-bg ${
                              cardPosition?.number === idx && "dots-active"
                            }`}
                          ></div>
                        );
                      })}
                    </div>
                    {/* switch index dots end */}
                    {/* card select type start */}
                    <div className="card-select-type-box">
                      <p className="title">Select a card type</p>
                      <div className="switch-box">
                        {cardType?.map((chi, idx) => {
                          return (
                            <div key={idx} className={`select-item`}>
                              <input
                                type="radio"
                                name={`input-card-radio`}
                                id={`${idx}-${chi}`}
                                className="input-radio"
                                checked={cardSelectedType === chi}
                                onChange={() => {
                                  setCardSelectedType(chi);
                                }}
                              />
                              <label htmlFor={`${idx}-${chi}`} className="label-radio">
                                <div className="circle">
                                  {" "}
                                  <span className="card-bg"></span>
                                </div>{" "}
                                {chi}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* card select type end */}
                  </div>
                  {/* switch select wrap box end */}
                </StepperMainWrapper>
              </>
            )}
            {/* step one end ---------- */}
            {/* step two start ---------- */}
            {activeStep === 2 && (
              <>
                <StepperMainWrapper
                  btnColor="purple-dark"
                  btnText="Proceed"
                  title="Setup your card"
                  onBack={() => {
                    setActiveStep(1);
                  }}
                  //   disabled={!details?.amount}
                  onBtnClick={() => {
                    setActiveStep(3);
                  }}
                >
                  {/* form group start */}
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    <RavenInputField
                      color="purple-dark"
                      type="text"
                      label="Card Title"
                      placeholder="Give your card a name"
                      onChange={() => {}}
                    />
                  </form>
                  {/* form group end */}
                  <RavenInputField
                    labelSpanText={AmountInfoIconSpan(false, 214757) as string}
                    placeholder="₦0.00"
                    type="number"
                    label="Enter amount"
                    color="purple-dark"
                    thousandFormat
                    numberPrefix={`₦`}
                    // style={{ marginTop: "-1rem" }}
                    value={details?.amount}
                    onChange={handleChange}
                    name={`amount`}
                  />
                </StepperMainWrapper>
              </>
            )}
            {/* step two end ---------- */}
            {/* step three start ---------- */}
            {activeStep === 3 && (
              <>
                <NewPreviewDetailBox
                  bulkAmount={details?.amount || formatNumWithCommaNairaSymbol("0")}
                  onSubmit={() => {
                    setShowModal((prev) => {
                      return { ...prev, pin: true };
                    });
                  }}
                />
              </>
            )}
            {/* step three end ---------- */}
          </div>
          {/* card index wrap end */}
        </ContainerWrapper>
        {/* content wrapper end */}
      </DashboardLayoutIndex>

      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin) => {
          //   handleSubmit(pin);
          //   actionCtx?.setConfettiVal();
          actionCtx?.playSound();
          setShowModal((prev) => {
            return { ...prev, success: true, pin: false };
          });
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        // loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        // onClose={() => {
        //   setShowModal((prev) => {
        //     return { ...prev, success: false };
        //   });
        // }}
        title="Card creation successful"
        text={`Your virtual card has been created and is ready to be used`}
        btnText={`Done`}
        onBtnClick={() => {
          navigate("/dashboard-cards");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default CreateCardIndex;
