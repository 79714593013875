import { useNavigate } from "react-router-dom";
import "../../../../styles/dashboard/settings/verification/index.css";
import SettingIndexLayout from "../SettingsIndexLayout";
import { SettingsRowItem } from "../blocks";
import { PreferenceIcons } from "./icons";

const fields = [
  {
    title: "BVN",
    desc: "Confirm your bank verification number",
    icon: PreferenceIcons.bvn,
    status: "verified" as const,
    link: "/dashboard-verification-bvn",
  },
  {
    title: "NIN",
    desc: "Access to receiving SMS notifications for your transactions",
    icon: PreferenceIcons.nin,
    link: "/dashboard-verification-nin",
  },
  {
    title: "Next of kin details",
    desc: "Access to receiving SMS notifications for your transactions",
    icon: PreferenceIcons.nextOfKin,
    link: "/dashboard-verification-nok",
  },
  {
    title: "Address verification",
    desc: "Access to receiving SMS notifications for your transactions",
    icon: PreferenceIcons.address,
    link: "/dashboard-verification-address",
  },
  {
    title: "Wealth declaration",
    desc: "Access to receiving SMS notifications for your transactions",
    icon: PreferenceIcons.wealthDeclaration,
    link: "/dashboard-verification-wealth",
  },
];

const checkIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M13.3333 4.5L5.99996 11.8333L2.66663 8.5"
      stroke="#1ACE37"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SettingsVerificationIndex = () => {
  const navigate = useNavigate();
  return (
    <SettingIndexLayout title="Verification">
      <ul className="settings-verification settings-grouped-list">
        {fields.map(({ status, ...ele }) => {
          const { link } = ele;
          return (
            <li className="settings-grouped-list-item border-theme" key={ele.title}>
              <SettingsRowItem {...ele} />
              {status === "verified" ? (
                <div
                  // onClick={() => {
                  //   navigate(link);
                  // }}
                  className="success-label grey-bg-two"
                >
                  {checkIcon}
                  <span>Verified</span>
                </div>
              ) : (
                <button
                  onClick={() => {
                    navigate(link);
                  }}
                  className="btn-reset btn-verify grey-bg-two"
                  type="button"
                >
                  Verify now
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </SettingIndexLayout>
  );
};

export default SettingsVerificationIndex;
