// import { current } from "@reduxjs/toolkit";
import React from "react";
import "../../styles/dashboard/fragments/PreviewNextAndPrev.css";

interface MyComponentProps {
  onNext?: (param: any) => void;
  onPrev?: (param: any) => void;
  total?: number | string;
  current?: number | string;
}

const arrowIcon = (
  <svg
    className="img"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
    className="dull-bright-filter"
      d="M8 15L13 10L8 5"
      stroke="#014345"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PreviewNextAndPrev: React.FC<MyComponentProps> = ({
  onNext,
  onPrev,
  current,
  total,
}) => {
  const handleNext = (currentVal: any, totalVal: any) => {
    if (currentVal + 1 < totalVal) {
      // console.log(currentVal);

      onNext && onNext(currentVal + 1);
    }
  };

  const handlePrev = (currentVal: any) => {
    if (currentVal !== 0) {
      // console.log(currentVal);

      onPrev && onPrev(currentVal - 1);
    }
  };
  return (
    <div className="preview-next-prev-index-wrap">
      <div
       style={{
        cursor: Number(current) !== 0 ? "pointer" : "not-allowed",
        opacity: Number(current) !== 0 ? 1 : .7
      }}
        onClick={() => {
          Number(current) !== 0 &&
            handlePrev(Number(current));
        }}
        className="prev-box grey-bg"
      >
        <figure style={{ transform: "rotate(-180deg)" }} className="img-box">
          {arrowIcon}
        </figure>
      </div>
      <div className="middle-box grey-bg">
        <span>{Number(current) + 1 || "1"}</span> / <span>{total || "10"}</span>
      </div>
      <div
        style={{
          cursor: Number(current) + 1 !== total ? "pointer" : "not-allowed",
          opacity: Number(current) + 1 !== total ? 1 : .7
        }}
        onClick={() => {
          Number(current) + 1 !== total &&
            handleNext(Number(current), Number(total));
        }}
        className="next-box grey-bg"
      >
        {" "}
        <figure className="img-box">{arrowIcon}</figure>
      </div>
    </div>
  );
};

export default PreviewNextAndPrev;
